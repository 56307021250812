import { createTheme } from '@mui/material/styles'

import { appBreakpoints, colors } from '../../tailwindResolver'
import { componentsConfig } from './components/componentsConfig'

// Use declaration merging to allow extra options in the theme
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    '2xl': true // adds the `2xl` breakpoint
  }
  interface Palette {
    gray: Palette['primary']
    white: Palette['primary']
  }
  interface PaletteOptions {
    gray: PaletteOptions['primary']
    white: PaletteOptions['primary'] // todo: should this always be primary? I can only find documentation that uses primary
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    white: true
  }
}

const muiTheme = createTheme({
  breakpoints: {
    values: appBreakpoints,
  },
  components: { ...componentsConfig },
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary,
    },
    warning: {
      main: colors.orangeYellow,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.redThunderbird,
    },
    gray: {
      50: colors.gray[50],
      100: colors.gray[100],
      200: colors.gray[200],
      300: colors.gray[300],
      400: colors.gray[400],
      500: colors.gray[500],
      600: colors.gray[600],
      700: colors.gray[700],
      800: colors.gray[800],
      900: colors.gray[900],
    },
    white: {
      main: colors.white,
      contrastText: colors.black,
    },
  },
  typography: {
    fontFamily: ['"IBM Plex Sans"', 'Verdana', 'Helvetica', 'sans-serif'].join(
      ',',
    ),
  },
})

export default muiTheme
