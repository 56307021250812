import { ComponentProps, FC } from 'react'

import ToggleButton from '../buttons/ToggleButton'
import ToggleButtonGroup from '../buttons/ToggleButtonGroup'

enum GstMode {
  excl = 'excl',
  incl = 'incl',
}

type Mode = 'excl' | 'incl'

interface Props extends ComponentProps<typeof ToggleButtonGroup> {
  value: Mode
}
export const GstToggleButton: FC<Props> = ({ ...props }) => {
  return (
    <ToggleButtonGroup aria-label='gst options' {...props}>
      <ToggleButton key='excl' value='excl' aria-label='gst exclusive'>
        GST Excl
      </ToggleButton>
      <ToggleButton key='incl' value='incl' aria-label='gst inclusive'>
        GST Incl
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export { GstMode }
