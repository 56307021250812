import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

import { colors } from '~/tailwindResolver'

const commonColors = {
  text: {
    active: colors.gray[900],
    disabled: colors.gray[400],
    placeholder: colors.gray[600],
    readOnly: colors.gray[800],
  },
  border: {
    active: colors.gray[400],
    activeHover: colors.gray[800],
    disabled: colors.gray[300],
    readOnly: colors.gray[400],
    error: colors.redThunderbird,
  },
  background: {
    readOnly: colors.gray[50],
  },
}

export const dialogActionsPaddingY = 24
export const dialogActionsPaddingX = 32

export const componentsConfig: Components<Omit<Theme, 'components'>> = {
  MuiAutocomplete: {
    styleOverrides: {
      option: {
        '&.Mui-focusVisible': {
          backgroundColor: colors.gray[200],
        },
        '&:hover': {
          backgroundColor: colors.gray[50],
        },
        '&.MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: '#E5F2F2', // greenMint
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },
  MuiCheckbox: {
    variants: [
      {
        props: { color: 'default' },
        style: {
          color: colors.gray[500],
          '&.Mui-checked,&.MuiCheckbox-indeterminate': {
            color: colors.gray[800],
          },
          '&.Mui-disabled': {
            color: colors.gray[200],
          },
        },
      },
      {
        props: { color: 'white' },
        style: {
          color: colors.white,
          opacity: 0.8,
          '&.Mui-checked': {
            color: colors.white,
            opacity: 1,
          },
        },
      },
    ],
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: '#E5F2F2', // greenMint
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        paddingBlock: dialogActionsPaddingY,
        paddingInline: dialogActionsPaddingX,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: colors.gray[400],
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        width: 414,
      },
      paper: {
        width: 414,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: colors.gray[800],
        fontSize: '0.75rem',
        lineHeight: '1rem',
      },
      contained: {
        marginLeft: 4,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: colors.gray[600],
      },
    },
  },
  // Style for the Select group label
  MuiListSubheader: {
    styleOverrides: {
      root: {
        color: colors.gray[800],
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: '12px',
        lineHeight: '42px',
        '&:first-of-type': {
          marginTop: '-8px',
        },
        '&:not(:first-of-type)': {
          borderTopColor: colors.gray[300],
          borderTopWidth: 'thin',
          borderTopStyle: 'solid',
          marginTop: '6px',
        },
      },
    },
  },
  // MuiMenuItem also sets the styles for Select options
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          backgroundColor: colors.gray[200],
        },
        '&:hover': {
          backgroundColor: colors.gray[50],
        },
        '&.Mui-selected': {
          backgroundColor: '#E5F2F2', // greenMint
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        '&.MuiTableCell-body': {
          verticalAlign: 'top',
        },
        // '&:not(.MuiTableCell-paddingNone, .MuiTableCell-paddingCheckbox)': {
        //   padding: '14px 16px',
        // },
        borderColor: colors.gray[400],

        '&.MuiTableCell-paddingCheckbox': {
          '&.MuiTableCell-body': {
            borderRightWidth: 1, // This assumes we will only have one checkbox per row
          },
          width: '48px',
          padding: '0 0.4375rem',

          '.MuiCheckbox-root': {
            padding: '0.3125rem',
          },
        },
      },
      head: {
        textTransform: 'uppercase',
        fontWeight: 600,
        color: colors.gray[800],
        lineHeight: '1.2rem',
        fontSize: '0.75rem',

        '&:has(> .MuiCheckbox-root)': {
          borderRightWidth: 0,
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-root': {
          '&.Mui-readOnly': {
            backgroundColor: commonColors.background.readOnly,
          },
          '& .Mui-disabled': {
            WebkitTextFillColor: commonColors.text.disabled, // Sets the disabled trailing text color
            fontWeight: 600,
          },
        },
        '& .MuiInputBase-input': {
          color: commonColors.text.active,
          '&::-webkit-input-placeholder': {
            opacity: 1,
            color: commonColors.text.placeholder,
          },
          '&.Mui-readOnly': {
            color: commonColors.text.readOnly,
            fontWeight: 600,
            backgroundColor: commonColors.background.readOnly,
            borderRadius: 4,
          },
          '&:disabled': {
            WebkitTextFillColor: commonColors.text.disabled, // Sets the disabled text color
            fontWeight: 600,
          },
        },
        '& .MuiOutlinedInput-root': {
          '& > fieldset': {
            borderColor: commonColors.border.active,
          },
        },
        '& .MuiOutlinedInput-root:hover': {
          '& > fieldset': {
            borderColor: commonColors.border.activeHover,
          },
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
          '& > fieldset': {
            borderColor: commonColors.border.disabled,
          },
        },
        '& .MuiOutlinedInput-root.Mui-readOnly': {
          '& > fieldset': {
            borderColor: commonColors.border.readOnly,
          },
        },
        '& .MuiOutlinedInput-root.Mui-error': {
          '& > fieldset': {
            borderColor: commonColors.border.error,
          },
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderColor: colors.gray[500],
        color: colors.gray[800],
      },
    },
  },
}
