// GST
// these GST types might not belong here, but they didn't belong on the farms state either
// they need some refactoring because they are hard to follow
// we dont have a way of saying "value === gstTypes.ZERO_RATE" - shoud we use an enum?
// probably should use an enum, for now, I've added ALL_GST_TYPE_VALUES
export interface NZL_GST_TYPES_TYPE {
  BUSINESS: string
  EXEMPT: string
  PERSONAL: string
  ZERO_RATE: string
}
export const NZL_GST_TYPES: NZL_GST_TYPES_TYPE = {
  BUSINESS: 'Business',
  EXEMPT: 'Exempt',
  PERSONAL: 'Personal',
  ZERO_RATE: 'Zero rated',
}

export type NZL_GST_TYPE = keyof NZL_GST_TYPES_TYPE

export interface AUS_GST_TYPES_TYPE {
  BUSINESS: string
  PERSONAL: string
  ZERO_RATE: string
  GST_FREE: string
  INPUT_TAXED_SUPPLIES: string
  NOT_APPLICABLE: string
}

export type AUS_GST_TYPE = keyof AUS_GST_TYPES_TYPE

export const AUS_GST_TYPES: AUS_GST_TYPES_TYPE = {
  BUSINESS: 'Business',
  PERSONAL: 'Personal',
  ZERO_RATE: 'Zero rated',
  GST_FREE: 'GST free',
  INPUT_TAXED_SUPPLIES: 'Input taxed supplies',
  NOT_APPLICABLE: 'Not applicable',
}

export const GST_APPLICABLE_TYPE = 'BUSINESS'

export type ALL_GST_TYPES = NZL_GST_TYPE | AUS_GST_TYPE | 'INHERIT'
export const ALL_GST_TYPE_VALUES: ALL_GST_TYPES[] = [
  'BUSINESS',
  'EXEMPT',
  'PERSONAL',
  'ZERO_RATE',
  'GST_FREE',
  'INPUT_TAXED_SUPPLIES',
  'NOT_APPLICABLE',
  'INHERIT',
]

export const isAValidGstTypeString = (
  value: ALL_GST_TYPES | string | undefined | null,
): boolean => ALL_GST_TYPE_VALUES.includes(value as ALL_GST_TYPES)

//
export type GST_TYPES = NZL_GST_TYPES_TYPE | AUS_GST_TYPES_TYPE
