import { SvgIcon, SvgIconProps } from '@mui/material'

const RepeatIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d='M19.0320313,10.9719479 C19.4462448,10.9719479 19.7820313,11.3077344 19.7820313,11.7219479 C19.7820313,14.3462422 17.8274395,16.4566614 15.2683277,16.5688212 L15.0536979,16.5735104 L5.2508474,16.5729479 L6.8793112,18.7465299 C7.10500596,19.0479559 7.06971248,19.4638272 6.81231783,19.7234736 L6.72847529,19.7964102 C6.42704928,20.0221049 6.011178,19.9868114 5.75153156,19.7294168 L5.67859505,19.6455743 L3.15349088,16.2731784 C2.96573807,16.0224257 2.9556772,15.6855439 3.11757776,15.426492 L3.18633996,15.333316 L5.71144413,12.410816 C5.98225156,12.0973895 6.45586679,12.0628398 6.76929336,12.3336473 C7.05422659,12.5798358 7.10868303,12.9936342 6.91276775,13.3023176 L6.84646212,13.3914965 L5.3918474,15.0729479 L15.0536979,15.0735104 C16.8743119,15.0735104 18.2820313,13.6046447 18.2820313,11.7219479 C18.2820313,11.3077344 18.6178177,10.9719479 19.0320313,10.9719479 Z'
        id='Combined-Shape'
      ></path>
      <path
        d='M18.0320313,1.97194792 C18.4462448,1.97194792 18.7820313,2.30773435 18.7820313,2.72194792 C18.7820313,5.34624219 16.8274395,7.45666141 14.2683277,7.56882119 L14.0536979,7.57351042 L4.2508474,7.57294792 L5.8793112,9.74652992 C6.10500596,10.0479559 6.06971248,10.4638272 5.81231783,10.7234736 L5.72847529,10.7964102 C5.42704928,11.0221049 5.011178,10.9868114 4.75153156,10.7294168 L4.67859505,10.6455743 L2.15349088,7.27317842 C1.96573807,7.02242567 1.9556772,6.6855439 2.11757776,6.42649198 L2.18633996,6.33331602 L4.71144413,3.41081602 C4.98225156,3.09738946 5.45586679,3.06283982 5.76929336,3.33364726 C6.05422659,3.57983583 6.10868303,3.99363416 5.91276775,4.30231761 L5.84646212,4.39149648 L4.3918474,6.07294792 L14.0536979,6.07351042 C15.8743119,6.07351042 17.2820313,4.6046447 17.2820313,2.72194792 C17.2820313,2.30773435 17.6178177,1.97194792 18.0320313,1.97194792 Z'
        id='Combined-Shape'
        transform='translate(10.392939, 6.459022) scale(-1, -1) translate(-10.392939, -6.459022) '
      ></path>
    </SvgIcon>
  )
}

export default RepeatIcon
