import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper, { PopperProps } from '@mui/material/Popper'
import { FC, useCallback, useId, useState } from 'react'

import { colors } from '~/tailwindResolver'

import { InfoIcon, WarningIcon } from './Icons'
import { cn } from './utils/cn'

type Variant = 'info' | 'warning' | 'error' | 'info-light'
interface Props {
  /** Popper placement
   * @default 'top-start'
   */
  placement?: PopperProps['placement']
  iconClassName?: string
  /** Whether the icon is vertically offset (to the top), e.g. label²
   *
   * @default false
   */
  alignSuper?: boolean
  /** Whether the popper is offset to the left by 16px
   *
   * @default false
   */
  disablePopperOffset?: boolean
  onIconClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  children: React.ReactNode
  variant?: Variant
  buttonContent?: JSX.Element
}

const getIcon = (variant: Variant) => {
  switch (variant) {
    case 'warning':
    case 'error':
      return WarningIcon
    case 'info':
    default:
      return InfoIcon
  }
}

const getColour = (variant: Variant) => {
  switch (variant) {
    case 'warning':
      return colors.orangeYellow
    case 'error':
      return colors.tertiary
    case 'info':
      return colors.primary
    case 'info-light':
      return colors.white
  }
}

/**
 * Invision Documentation: [Rich Tooltip](https://projects.invisionapp.com/d/main/default/#/console/22221912/473148248/preview)
 *
 * Replaces `InfoPopup`
 */
export const RichTooltip: FC<Props> = ({
  placement = 'top-start',
  alignSuper = false,
  disablePopperOffset = false,
  variant = 'info',
  buttonContent,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const id = useId()
  const isOpen = Boolean(anchorEl)
  const handleClose = useCallback(() => setAnchorEl(null), [])

  const Icon = getIcon(variant)
  const colour = getColour(variant)

  return (
    <>
      <button
        className='print:hidden'
        onClick={(e) => {
          props.onIconClick?.(e)
          setAnchorEl(e.currentTarget)
        }}
        onKeyDown={(event) => {
          if (event.key === 'Tab') {
            handleClose()
          }
        }}
        type='button'
        aria-haspopup='true'
        aria-expanded={isOpen}
        aria-describedby={isOpen ? id : undefined}
      >
        {buttonContent ? (
          buttonContent
        ) : (
          <Icon
            className={cn(
              '!size-4',
              alignSuper ? 'align-super' : '-mt-[3px]',
              props.iconClassName,
            )}
            htmlColor={colour}
          />
        )}
      </button>
      <Popper
        className='print:hidden'
        open={isOpen}
        anchorEl={anchorEl}
        placement={placement}
        sx={(theme) => ({
          zIndex: theme.zIndex.modal + 1,
        })}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div
            id={id}
            role='tooltip'
            className={cn(
              'm-1 max-w-[300px] rounded bg-white p-6 text-sm text-gray-900 shadow-[0px_0px_10px] shadow-gray-900/30',
              !disablePopperOffset && '-ml-6',
            )}
          >
            {props.children}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
