import './404.scss'

import { FC } from 'react'
import { Link } from 'react-router-dom'

import Button from '~/common/ui/buttons/Button'

interface FourZeroFourProps {
  returnPath?: string
  returnPathName?: string
}

export const FourZeroFour: FC<FourZeroFourProps> = ({
  returnPath = '/',
  returnPathName = 'Dashboard',
}) => (
  <div className='four-zero-four'>
    <h1>404</h1>
    <h2>Page not found</h2>
    <p>We couldn’t find the page you are looking for.</p>
    <br />
    <Link to={returnPath}>
      <Button className='button' primary>
        Back to {returnPathName}
      </Button>
    </Link>
  </div>
)

export default FourZeroFour
