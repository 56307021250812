export const CodingRuleIcon: React.FC = () => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='1.82 2.91 12.36 10.18'
      height={10}
    >
      <title>transaction-code-rule-small</title>
      <path
        className='fill-current'
        d='M4.30740534,2.90909091 L4.30740534,4.19801129 L3.06176645,4.19811665 L3.06176645,11.8019887 L4.30740534,11.8019887 L4.30740534,13.0909091 L2.4399041,13.0909091 C2.12294927,13.0909091 1.86139029,12.8450571 1.82302592,12.5272886 L1.81818182,12.4464489 L1.81818182,3.5535511 C1.81818182,3.22500445 2.05535966,2.95387962 2.36191658,2.91411217 L2.4399041,2.90909091 L4.30740534,2.90909091 Z M13.5600958,2.90909091 L13.6380833,2.91387222 C13.9105784,2.94753184 14.1282554,3.15525681 14.1732639,3.42072434 L14.1818181,3.52275415 L14.1818181,12.4772458 C14.1818181,12.7900922 13.9446403,13.0482607 13.6380833,13.0861278 L13.5600958,13.0909091 L11.6925946,13.0909091 L11.6925946,11.8635826 L12.9383113,11.8631257 L12.9383113,4.13687426 L11.6925946,4.1364174 L11.6925946,2.90909091 L13.5600958,2.90909091 Z M11.4825007,8.89770947 L11.4825007,10.1356205 L4.01249577,10.1356205 L4.01249577,8.89770947 L11.4825007,8.89770947 Z M8.68124885,6.99430928 L8.68124885,8.23222031 L4.01249577,8.23222031 L4.01249577,6.99430928 L8.68124885,6.99430928 Z M10.5954376,5.09090909 L10.5954376,6.32882012 L4.01249577,6.32882012 L4.01249577,5.09090909 L10.5954376,5.09090909 Z'
      ></path>
    </svg>
  )
}
