import { List, Record } from 'immutable'

import { TimeStampString, UUID } from '~/state/model/ModelTypes'

import { GstType } from '../codes/codeTypes'

interface CodeSplitLineFromServer {
  amount: string
  code: string
  description: string
  extcode_id: string
  gst_type: GstType
  name: string
  tag_ids: string[]
}

export const defaultCodeSplitLine = {
  amount: '',
  code: '',
  description: '',
  extcode_id: '',
  gst_type: 'BUSINESS' as GstType,
  name: '',
  tag_ids: [] as string[],
}

export type CodeSplitLineList = List<CodeSplitLine>

export class CodeSplitLine extends Record(defaultCodeSplitLine) {
  static fromServerJS(data: CodeSplitLineFromServer): CodeSplitLine {
    const fields = {
      ...defaultCodeSplitLine,
      ...data,
    }
    return new CodeSplitLine(fields)
  }
  static fromServerArray(src): CodeSplitLineList {
    return List((src || []).map((a) => CodeSplitLine.fromServerJS(a)))
  }
}

interface SplitRuleFieldsFromServer {
  version: number
  codeSplitLines: CodeSplitLineFromServer[]
}

interface SplitRuleFields {
  version: number
  codeSplitLines: List<CodeSplitLine>
}

const splitRuleDefaults: SplitRuleFields = {
  version: 1,
  codeSplitLines: List(),
}

export class SplitRule extends Record(splitRuleDefaults) {
  static fromServerJS(data: SplitRuleFieldsFromServer): SplitRule {
    const fields = {
      ...splitRuleDefaults,
      ...data,
      codeSplitLines: CodeSplitLine.fromServerArray(data?.codeSplitLines || []),
    }
    return new SplitRule(fields)
  }
}

export interface CodeSplitRuleFromServer {
  deleted_at: TimeStampString
  extcode_id: UUID
  farm_id: UUID
  id: UUID
  rule: SplitRuleFieldsFromServer
}

interface CodeSplitRuleFields {
  deleted_at: TimeStampString
  extcode_id: UUID
  farm_id: UUID
  id: UUID
  rule: SplitRule
}

export const codeSplitRuleDefaults: CodeSplitRuleFields = {
  deleted_at: '',
  extcode_id: '',
  farm_id: '',
  id: '',
  rule: SplitRule.fromServerJS({ version: 1, codeSplitLines: [] }),
}

export type CodeSplitRuleList = List<CodeSplitRule>

export class CodeSplitRule extends Record(codeSplitRuleDefaults) {
  static fromServerJS(data: CodeSplitRuleFromServer): CodeSplitRule {
    const fields: CodeSplitRuleFields = {
      ...codeSplitRuleDefaults,
      ...data,
      rule: SplitRule.fromServerJS(data.rule),
    }
    return new CodeSplitRule(fields)
  }

  static nullRecord(data = {}): CodeSplitRule {
    return new CodeSplitRule({ ...codeSplitRuleDefaults, ...data })
  }

  static fromServerArray(src?: CodeSplitRuleFromServer[]): CodeSplitRuleList {
    return List((src || []).map((a) => CodeSplitRule.fromServerJS(a)))
  }
}

export const codeSplitRuleExistForExtCode = (
  extcodeId?: string,
  codeSplitRules?: CodeSplitRuleList,
): boolean =>
  !!(
    extcodeId &&
    codeSplitRules?.find(
      (codeSplitRule) => codeSplitRule.extcode_id === extcodeId,
    )
  )
