import { Decimal } from 'decimal.js'
import { List, Record } from 'immutable'

import { toDecimal, zero } from '~/common/utils/CurrencyUtils'
import { UUID } from '~/state/model/ModelTypes'

export enum CODING_RULE_ACTION_TYPES {
  CODE_DOLLAR_AMOUNT = 'code-dollar-amount',
  CODE_PERCENTAGE_AMOUNT = 'code-percentage-amount',
  CODE_FULL_AMOUNT = 'code-full-amount',
  UNDEFINED = '',
}

export const actionOptions = [
  {
    value: CODING_RULE_ACTION_TYPES.CODE_FULL_AMOUNT,
    label: 'Code full amount to',
  },
  {
    value: CODING_RULE_ACTION_TYPES.CODE_DOLLAR_AMOUNT,
    label: 'Code $ amount to',
  },
  {
    value: CODING_RULE_ACTION_TYPES.CODE_PERCENTAGE_AMOUNT,
    label: 'Code % amount to',
  },
]

import {
  getCodeDetailsFromFormattedCodeString,
  SectionsList,
} from '~/state/model/codes/SectionsList'

import { FarmCodingRule } from './FarmCodingRule'

export interface CodeRuleActionFromServer {
  amount: string
  code_to_code?: string
  code_to_name?: string
  description: string
  extcode_id?: UUID
  tag_ids?: UUID[]
}

interface CodeRuleActionCommonFields {
  actionType: CODING_RULE_ACTION_TYPES
  amount: string
  code_to_code: string
  code_to_name: string
  description: string
  extcode_id: UUID
  tag_ids: UUID[]
}

export interface CodeRuleActionJS extends CodeRuleActionCommonFields {}
export interface CodeRuleActionFields extends CodeRuleActionCommonFields {}

const codeRuleActionDefaults: CodeRuleActionFields = {
  actionType: CODING_RULE_ACTION_TYPES.UNDEFINED,
  amount: '',
  code_to_code: '',
  code_to_name: '',
  description: '',
  extcode_id: '',
  tag_ids: [],
}

export type CodeRuleActionList = List<CodeRuleActionRecord>

export class CodeRuleActionRecord extends Record(codeRuleActionDefaults) {
  static fromServerJS(data: CodeRuleActionFromServer): CodeRuleActionRecord {
    const fields: CodeRuleActionFields = {
      ...codeRuleActionDefaults,
      ...data,
    }
    if (!fields.actionType) {
      // work out actionType from amount, %, % or full
      if (fields.amount.includes('%')) {
        fields.actionType = CODING_RULE_ACTION_TYPES.CODE_PERCENTAGE_AMOUNT
      } else if (
        fields.amount &&
        !isNaN(parseFloat(fields.amount.replace('$', '')))
      ) {
        fields.actionType = CODING_RULE_ACTION_TYPES.CODE_DOLLAR_AMOUNT
      } else {
        fields.actionType = CODING_RULE_ACTION_TYPES.CODE_FULL_AMOUNT
      }
    }
    // strip the old % / $ as these are nolonger needed
    fields.amount = fields.amount.replace('$', '').replace('%', '')
    if (fields.tag_ids === null || fields.tag_ids === undefined) {
      fields.tag_ids = []
    }
    return new this(fields)
  }
  static fromServerArray(src: CodeRuleActionFromServer[]): CodeRuleActionList {
    return List(src.map((a) => this.fromServerJS(a)))
  }
  static toJSArray(codingRuleActions: CodeRuleActionList): CodeRuleActionJS[] {
    return codingRuleActions.toJS() as CodeRuleActionJS[]
  }

  isPercent(): boolean {
    return this.actionType === CODING_RULE_ACTION_TYPES.CODE_PERCENTAGE_AMOUNT
  }

  isDollarSplit(): boolean {
    return this.actionType === CODING_RULE_ACTION_TYPES.CODE_DOLLAR_AMOUNT
  }

  isFullAmount(): boolean {
    return this.actionType === CODING_RULE_ACTION_TYPES.CODE_FULL_AMOUNT
  }
}

// CodeRuleActionJS helpers

export const nullCodeRuleAction = (data = {}): CodeRuleActionJS => ({
  ...codeRuleActionDefaults,
  ...data,
})

const MAX_ACTIONS = 6

const isEmptyAmount = (a: CodeRuleActionJS): boolean =>
  a.amount === undefined || a.amount === null || a.amount === ''

const countEmptyAmounts = (actions: CodeRuleActionJS[]): number =>
  actions.filter((a) => isEmptyAmount(a)).length

const allHaveAmount = (actions: CodeRuleActionJS[]): boolean =>
  actions.every((a) => !isEmptyAmount(a))

const hasEmptyAmounts = (actions: CodeRuleActionJS[]): boolean =>
  !!actions.find((a) => isEmptyAmount(a))

export const allocatedTotal = (actions: CodeRuleActionJS[]): Decimal =>
  actions.reduce((sum, a) => sum.add(toDecimal(a.amount)), zero)

export const hasAmountOrCode = (action: CodeRuleActionJS): boolean =>
  !isEmptyAmount(action) || action.code_to_code > ''

const allocatedPercent = (actions: CodeRuleActionJS[]): Decimal =>
  allocatedTotal(actions)

const requiresAnotherAction = (actions: CodeRuleActionJS[]) => {
  if (actions.length >= MAX_ACTIONS) {
    return false
  }
  const actionType = actions[0].actionType
  switch (actionType) {
    case CODING_RULE_ACTION_TYPES.CODE_FULL_AMOUNT:
      return false
    case CODING_RULE_ACTION_TYPES.CODE_DOLLAR_AMOUNT:
      return allHaveAmount(actions)
    case CODING_RULE_ACTION_TYPES.CODE_PERCENTAGE_AMOUNT:
      return allocatedPercent(actions).lessThan(100) && allHaveAmount(actions)
  }
}

export const isDollarSplit = (actions: CodeRuleActionJS[]): boolean =>
  actionTypeForActions(actions) === CODING_RULE_ACTION_TYPES.CODE_DOLLAR_AMOUNT

export const isFullAmount = (actions: CodeRuleActionJS[]): boolean =>
  actionTypeForActions(actions) === CODING_RULE_ACTION_TYPES.CODE_FULL_AMOUNT

export const isPercentSplit = (actions: CodeRuleActionJS[]): boolean =>
  actionTypeForActions(actions) ===
  CODING_RULE_ACTION_TYPES.CODE_PERCENTAGE_AMOUNT

export const isOverAllocated = (actions: CodeRuleActionJS[]): boolean =>
  isPercentSplit(actions) && allocatedPercent(actions).greaterThan(100)

export const isFullyAllocated = (actions: CodeRuleActionJS[]): boolean =>
  isPercentSplit(actions) && allocatedPercent(actions).equals(100)

const requiresSingleAction = (actions: CodeRuleActionJS[]): boolean =>
  isFullAmount(actions)

export const actionsForTypeNeedUpdating = (
  actions: CodeRuleActionJS[],
): boolean => {
  return (
    (isFullyAllocated(actions) && hasEmptyAmounts(actions)) ||
    requiresAnotherAction(actions) ||
    requiresLastActionRemoved(actions) ||
    (requiresSingleAction(actions) && actions.length > 1)
  )
}

const requiresLastActionRemoved = (actions: CodeRuleActionJS[]): boolean =>
  isDollarSplit(actions) && countEmptyAmounts(actions) > 1

export const updateActionsForType = (
  actions: CodeRuleActionJS[],
): CodeRuleActionJS[] => {
  if (!actionsForTypeNeedUpdating(actions)) {
    return actions
  }
  let newActions = [...actions]
  if (isFullyAllocated(actions) || isOverAllocated(actions)) {
    newActions = newActions.filter((a) => !isEmptyAmount(a))
  }
  if (requiresAnotherAction(actions)) {
    newActions.push(nullCodeRuleAction())
  }
  if (requiresLastActionRemoved(actions)) {
    newActions.pop()
  }
  if (requiresSingleAction(actions)) {
    newActions.splice(1)
  }
  return newActions
}

export const actionTypeForActions = (actions: CodeRuleActionJS[]) =>
  actions[0].actionType

export const updateForExtcodeSelected = (
  action: CodeRuleActionJS,
  sections: SectionsList,
): void => {
  const codeDetails = getCodeDetailsFromFormattedCodeString(
    sections,
    action.code_to_code,
  )

  if (codeDetails.found) {
    action.extcode_id = codeDetails.ext.id
    action.code_to_name = codeDetails.ext.name
  } else {
    action.extcode_id = ''
    action.code_to_name = ''
  }
}

export const codeRuleActionsJS = (rule: FarmCodingRule): CodeRuleActionJS[] =>
  rule.rule.actions.toJS() as CodeRuleActionJS[]
