import startCase from 'lodash/startCase'

import { Optional } from '~/activity/entries/ReduxTypes'
import { CountryInfoId } from '~/state/model/farms/FarmRecord'

export const capitalize = (str: string): string =>
  str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const toSlug = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

export const stripCommas = (str: string): string => {
  return str.replaceAll(',', '')
}

// A basic pluralizer that does not consider the rules
// in pluralizing words. It just adds the letter 's' at
// the end if the count is more than 1.
export const pluralizeStr = (count: number, str: string): string =>
  `${count} ${pluralize(count, str)}`

export const pluralize = (num: number, text: string): string =>
  text + (num === 1 ? '' : 's')

export function containsSomeText(s?: Optional<string> | any): s is string {
  if (!s) {
    return false
  }

  // there seems to be a case where the argument is not a string. So double check.
  if (s.trim === undefined) {
    console.warn(`Got to this strange place: "${typeof s}":"${s}"`)
    return false
  }

  return s.trim() !== ''
}

export function isEmpty(s?: Optional<string> | any): boolean {
  return !containsSomeText(s)
}

// Converts any snake case string into a humanized and capitalized value (i.e restricted_user => Restricted user)
export const humanizeSnakeCase = (str: Optional<string>): string => {
  return containsSomeText(str) ? capitalize(str.replace('_', ' ')) : ''
}

// Converts any snake case string into a humanized and capitalized value (i.e gst_type => Gst Type)
export const humanizeCapitalizeSnakeCase = (str: Optional<string>): string => {
  return startCase(humanizeSnakeCase(str))
}

// Removes the last pathname from a string URL (i.e /farm/123/reports/variance => /farm/123/reports)
export const popLastUrlStringPath = (path: Optional<string>): string => {
  if (!containsSomeText(path)) return ''

  const newPath = path.split('/')
  newPath.pop()

  return newPath.length > 1 ? newPath.join('/') : '/'
}

// This formats a 15-digit or 16-digit account number.
// If account number given is lesser than 15, it will return an unformatted number.
export const formatBankAccNumber = (
  accountNumber: string | null | undefined,
  hyphen = false,
  countryCode: CountryInfoId,
): string => {
  // Don't format an empty account number
  if (!accountNumber) return ''

  const isAusAcc = countryCode === 'AUS'
  const spacer = hyphen ? '-' : ' '
  const separator = isAusAcc
    ? (match, p1, p2, p3) => [p1, p2, p3].join(spacer)
    : (match, p1, p2, p3, p4) => [p1, p2, p3, p4].join(spacer)

  const regex = isAusAcc
    ? /^(\d{3})(\d{3})(\d{8,10})$/
    : /^(\d{2})(\d{4})(\d{7})(\d{2,3})$/

  // Remove all whitespace of account number and format it afterwards - if the regex matches
  const trimmedAccountNumber = accountNumber.replace(/\s/g, '')
  if (regex.test(trimmedAccountNumber)) {
    return trimmedAccountNumber.replace(regex, separator)
  } else {
    return accountNumber
  }
}

export const formatFullName = (firstName: string, lastName: string): string => {
  return `${firstName} ${lastName}`
}

export function negateNumber(number: string): string {
  return (parseFloat(number) * -1).toString()
}

export function truncate(value: string, desiredLength: number): string {
  return value.length > desiredLength
    ? `${value.slice(0, desiredLength - 1)} \u2026`
    : value
}

export function isString(value: unknown): value is string {
  return typeof value === 'string'
}

export function minimumIntegerDigits(myNumber: number, digits: number): string {
  return myNumber?.toLocaleString('en-NZ', {
    minimumIntegerDigits: digits,
    useGrouping: false,
  })
}
