import { Record } from 'immutable'

import { MonthAsNumber } from '~/common/utils/DateUtil'
import { GstBasis, GstFrequency } from '~/state/model/farms/FarmFinancialDetail'

import { CountryInfoId } from './FarmRecord'

export type SupportedCurrency = 'NZD' | 'AUD'

export interface FarmCountryInfoFromServer {
  id: CountryInfoId
  default_gst_rate: number
  currency: SupportedCurrency
}
interface FarmCountryInfoFields extends FarmCountryInfoFromServer {
  subscription_gst_rate: number
  defaultGstFrequency: GstFrequency
  defaultGstBasis: GstBasis
  gstRateErrorMessage: string
  defaultFYEndMonth: MonthAsNumber
}

export const farmCountryInfoDefaultsNZL: FarmCountryInfoFields = {
  id: 'NZL',
  default_gst_rate: 15, // Used throughout the app as the default GST rate for NZL
  subscription_gst_rate: 15, // We charge GST on subscriptions for NZL
  currency: 'NZD',
  defaultGstFrequency: 2,
  defaultGstBasis: 'PAYMENTS',
  gstRateErrorMessage:
    'Must be a whole or 2 decimal place number between 0 and 15',
  defaultFYEndMonth: '5',
}
export const farmCountryInfoDefaultsAUS: FarmCountryInfoFields = {
  id: 'AUS',
  default_gst_rate: 10, // Used throughout the app as the default GST rate for AUS
  subscription_gst_rate: 0, // We don't charge GST on subscriptions for AUS
  currency: 'NZD',
  defaultGstFrequency: 3,
  defaultGstBasis: 'CASH',
  gstRateErrorMessage:
    'Must be a whole or 2 decimal place number between 0 and 10',
  defaultFYEndMonth: '6',
}

export class FarmCountryInfo extends Record(farmCountryInfoDefaultsNZL) {
  static fromServerJS(data: FarmCountryInfoFromServer): FarmCountryInfo {
    const defaults =
      data.id === 'NZL'
        ? farmCountryInfoDefaultsNZL
        : farmCountryInfoDefaultsAUS

    return new FarmCountryInfo({
      ...defaults,
      ...data,
    })
  }

  static defaultFromCountry(templateCountryId?: string): FarmCountryInfo {
    switch (templateCountryId) {
      case 'aus':
        return new FarmCountryInfo(farmCountryInfoDefaultsAUS)
      case 'nzl':
      default:
        return new FarmCountryInfo(farmCountryInfoDefaultsNZL)
    }
  }

  isNZL(): boolean {
    return this.id === 'NZL'
  }

  isAUS(): boolean {
    return this.id === 'AUS'
  }
}
