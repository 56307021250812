import { FC, ReactNode, useMemo } from 'react'

import { cn } from '../utils/cn'

interface Props {
  label?: ReactNode
  id?: string
  error?: boolean
  disabled?: boolean
  optional?: boolean
  className?: string
  children?: React.ReactNode
  spanClassName?: string
}

/**
 * While this component is very similar to `FormLabelComponent`, it doesn't place the child component within the label tag.
 * This fixes issues with placement. i.e. the child component appears below the label when it is not full width.
 */
const FieldLabel: FC<Props> = (props) => {
  const {
    label,
    id,
    error,
    disabled,
    optional,
    className,
    spanClassName,
    children,
  } = props

  const classNames = cn('block', className)

  const textColor = useMemo(() => {
    if (disabled) {
      return 'text-gray-600'
    }
    if (error) {
      return 'text-redThunderbird'
    }
    return 'text-gray-800'
  }, [disabled, error])

  return (
    <div>
      <label htmlFor={id} className={classNames}>
        {label && (
          <span
            className={cn(
              textColor,
              'mb-1 ml-0.5 mr-1 inline-block text-xs font-semibold uppercase',
              spanClassName,
            )}
          >
            {label}
            {optional && (
              <>
                {' '}
                <span
                  className={`mr-1 inline-block font-normal ${textColor} normal-case italic`}
                >
                  - optional
                </span>
              </>
            )}
          </span>
        )}
      </label>
      {children}
    </div>
  )
}

export default FieldLabel
