import { styled } from '@mui/material/styles'
import MUIToggleButtonGroup, {
  type ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup'
import { type FC } from 'react'

import { EndInputAdornment } from '../forms/helpers/EndInputAdornment'

const StyledToggleButtonGroup = styled(MUIToggleButtonGroup)(
  ({ theme, size }) => ({
    backgroundColor: theme.palette.common.white,
    height: size === 'small' ? 32 : 40,
    fontSize: size === 'small' ? '0.8125rem' : '1rem',
    lineHeight: size === 'small' ? '1.0625rem' : '1.25rem',
  }),
)

interface Props extends ToggleButtonGroupProps {
  size?: 'small' | 'medium'
}

const ToggleButtonGroup: FC<Props> = ({
  children,
  color = 'primary',
  exclusive = true,
  size = 'small',
  onChange,
  ...rest
}) => (
  <StyledToggleButtonGroup
    color={color}
    exclusive={exclusive}
    size={size}
    onChange={(e, value) => {
      if (value === null) return
      onChange?.(e, value)
    }}
    {...rest}
  >
    {children}
  </StyledToggleButtonGroup>
)

export default ToggleButtonGroup

export const EndAdornmentToggleButtonGroup: FC<Props> = (props) => (
  <EndInputAdornment>
    <ToggleButtonGroup size='small' {...props} />
  </EndInputAdornment>
)
