import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon'
import { FC } from 'react'

export const EnvelopeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g
        xmlns='http://www.w3.org/2000/svg'
        id='Navigation/workspace/envelope'
        stroke='none'
        strokeWidth='1.55'
        fill='none'
        fillRule='evenodd'
      >
        <path
          d='M3.00461426,6.76377778 L3,17.6 C3,18.5848889 3.8028,19.4 4.8,19.4 L19.2,19.4 C20.1972,19.4 21,18.5848889 21,17.6 L21,6.8 L12.984375,14.0415556 C12.433575,14.5522222 11.5662,14.5522222 11.015625,14.0415556 L3.00461426,6.76377778 Z M3.7524,5.33311111 L3.759375,5.34022222 L11.634375,12.6457778 C11.8083,12.8071111 12.191475,12.8071111 12.365625,12.6457778 L20.240625,5.34022222 L20.2476,5.33311111 C19.9524,5.12422222 19.592175,5 19.199775,5 L4.799775,5 C4.4076,5 4.047375,5.12422222 3.7524,5.33311111 Z'
          id='Fill-1'
          fill='currentColor'
        />
      </g>
    </svg>
  </SvgIcon>
)
