import { List, Record } from 'immutable'

import {
  catAndGenCodeSpecified,
  CategoryLinkType,
  CODE_TYPES,
  GENERAL_CODE,
  GST_CATEGORY_NAME,
  GstType,
} from '~/state/model/codes/codeTypes'
import { Extcode, ExtendedCodeFromServer } from '~/state/model/codes/Extcode'

export interface CategoryFromServer {
  extcodes: ExtendedCodeFromServer[]
  active?: boolean
  kpi_reporting_group_id?: string
  position?: number
  name?: string
  code_type?: CODE_TYPES
  code?: string
  gst_type?: GstType
  section_id?: string
  inc_section_id?: string
  exp_section_id?: string
  type?: string
  id: string
  category_link_type?: CategoryLinkType
  tag_ids?: string[] | null
}

interface CategoryRecordFields {
  extcodes: List<Extcode>
  active: boolean
  kpi_reporting_group_id?: string
  position: number
  name: string
  code_type: CODE_TYPES
  code: string
  gst_type: GstType
  section_id: string
  inc_section_id?: string
  exp_section_id?: string
  type: string
  id: string
  category_link_type?: CategoryLinkType

  applyFilter?: boolean
  indeterminate?: boolean
  expanded?: boolean
  tag_ids?: string[] | null
  export_code?: string
}

const categoryRecordDefaults: CategoryRecordFields = {
  extcodes: List(),
  active: true,
  kpi_reporting_group_id: undefined,
  position: 0,
  name: '',
  code_type: CODE_TYPES.TYPE_EMPTY,
  code: '',
  gst_type: 'BUSINESS',
  section_id: '',
  inc_section_id: undefined,
  exp_section_id: undefined,
  type: '',
  id: '',
  category_link_type: undefined,
  applyFilter: undefined,
  indeterminate: undefined,
  expanded: undefined,
  tag_ids: undefined,
  export_code: undefined,
}

const getExtcodeRecords = (data: CategoryFromServer): List<Extcode> => {
  const extcodes = data.extcodes || []

  return List(extcodes.map(Extcode.fromServerJS))
}

const getCategoryExportCode = (extcodes: List<Extcode>): string | undefined => {
  const genCode = extcodes.find((extcode) => extcode.code === GENERAL_CODE)
  return genCode?.export_code
}

export type CategoryList = List<Category>

export class Category extends Record(categoryRecordDefaults) {
  static fromServerJS(data: CategoryFromServer): Category {
    const extcodes = getExtcodeRecords(data)
    const export_code = getCategoryExportCode(extcodes)
    return new Category({
      ...data,
      type: 'CATEGORY',
      export_code,
      extcodes,
    })
  }

  static empty(): Category {
    return new Category()
  }

  isInterestCategory(): boolean {
    return this.get('code') === 'INT'
  }

  isStockCode(): boolean {
    return this.code_type === CODE_TYPES.TYPE_STOCK
  }

  containsExtcode(code: Extcode): boolean {
    return this.id === code.category_id
  }

  isGstCategory(): boolean {
    return this.name === GST_CATEGORY_NAME
  }

  isMilkSolids(): boolean {
    return this.code_type === CODE_TYPES.TYPE_MILK_SOLIDS
  }

  // TODO worst detection of GST ever
  // name and code are read only fields on the gst category, ok for now
  // consider using a static uuid for special codes
  isGstExtcode(ext: Extcode) {
    return this.isGstCategory() && ext.isGstReturnExtcode()
  }

  isOverdraftInterestExtcode(ext: Extcode) {
    return this.isInterestCategory() && ext.isOverdraftInterestCode()
  }

  isReadOnlyExtcode(ext: Extcode): boolean {
    return this.isGstExtcode(ext) || this.isOverdraftInterestExtcode(ext)
  }

  isGeneratedExtcode(ext: Extcode) {
    return this.isGstExtcode(ext) || this.isOverdraftInterestExtcode(ext)
  }

  toFormattedCodeString(ext: Extcode) {
    return ext.isGeneralCode() ? `${this.code}:` : `${this.code}:${ext.code}`
  }

  toNameStringWithSeperator(ext: Extcode, separator: string) {
    return ext.isGeneralCode()
      ? this.name
      : `${this.name}${separator}${ext.name}`
  }

  toNameString(ext: Extcode) {
    return this.toNameStringWithSeperator(ext, ' - ')
  }

  getDerivedGSTType(extcode: Extcode) {
    return extcode.gst_type === 'INHERIT' ? this.gst_type : extcode.gst_type
  }

  getSectionId(): any {
    return this.section_id || this.category_link_type === 'inc'
      ? this.inc_section_id
      : this.exp_section_id
  }

  generalCodeForCategory() {
    return this.extcodes.find((e) => e.isGeneralCode())
  }

  acctCodeForCategoryLinkType(
    categoryLinkType: CategoryLinkType | null | undefined,
  ) {
    const genCode = this.generalCodeForCategory()
    if (
      !genCode ||
      genCode.isStockCode() ||
      genCode.code_type === CODE_TYPES.TYPE_MILK_SOLIDS
    ) {
      return null
    }
    let acctCode = genCode.export_code
    if (categoryLinkType === 'exp') {
      acctCode = genCode.export_code_mirrored
    }
    if (!acctCode || acctCode === '') {
      acctCode = 'None'
    }
    return acctCode
  }
}

const catAndExtSpecified = /^[a-zA-Z0-9_-]+:[a-zA-Z0-9_-]+$/

export function isCodeStringCategory(codeText: string): boolean {
  return Boolean(
    catAndGenCodeSpecified.exec(codeText) || !catAndExtSpecified.exec(codeText),
  )
}
