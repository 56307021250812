export const ROW_TYPES = {
  EXTCODE: 'EXTCODE',
  CATEGORY: 'CATEGORY',
  SECTION: 'SECTION',
  WORKSHEET: 'WORKSHEET',
  SUBTOTAL: 'SUBTOTAL',
  RUNNING_TOTAL: 'RUNNINGTOTAL',
  COMPARISON: 'COMPARISON',
  OPENING_TOTAL_BALANCE: 'OPENING_TOTAL_BALANCE',
  CLOSING_TOTAL_BALANCE: 'CLOSING_TOTAL_BALANCE',
  OPENING_ACCOUNT_BALANCE: 'OPENING_ACCOUNT_BALANCE',
  CLOSING_ACCOUNT_BALANCE: 'CLOSING_ACCOUNT_BALANCE',
  LIVESTOCK_CATEGORY: 'LIVESTOCK_CATEGORY',
  LIVESTOCK_EXTCODE: 'LIVESTOCK_EXTCODE',
  LIVESTOCK_EVENT: 'LIVESTOCK_EVENT',
  LIVESTOCK_CATEGORY_TOTAL: 'LIVESTOCK_CATEGORY_TOTAL',
  LIVESTOCK_OVERALL_TOTAL: 'LIVESTOCK_OVERALL_TOTAL',
  LIVESTOCK_QUANTITY_TOTAL: 'LIVESTOCK_QUANTITY_TOTAL',
  SECTION_TOTAL: 'SECTION_TOTAL',
  CATEGORY_TOTAL: 'CATEGORY_TOTAL',
  EXTCODE_TOTAL: 'EXTCODE_TOTAL',
  SECTION_OVERALL_TOTAL: 'SECTION_OVERALL_TOTAL',
  PLAN_WORKSHEET_ITEM: 'PLAN_WORKSHEET_ITEM',
  ACTUAL_WORKSHEET_ITEM: 'ACTUAL_WORKSHEET_ITEM',
  INVOICE_WORKSHEET_ITEM: 'INVOICE_WORKSHEET_ITEM',
  MILK_WORKSHEET_ITEM: 'MILK_WORKSHEET_ITEM',
}

export const CELL_TYPES = {
  CASHFLOW: 'CASHFLOW',
  TOTAL: 'TOTAL',
  ACTIVE: 'ACTIVE',
  FOCUS: 'FOCUS',
  SUBTOTAL: 'SUBTOTAL',
  RUNNING_TOTAL: 'RUNNINGTOTAL',
  REVISION: 'REVISION',
  LIVESTOCK_REVISION: 'LIVESTOCK_REVISION',
  COMPARISON: 'COMPARISON',
  CASH_BALANCE: 'CASH_BALANCE',
  REVISION_BALANCE: 'REVISION_BALANCE',
  STOCK_ON_HAND: 'STOCK_ON_HAND',
  STOCK_REMAINING: 'STOCK_REMAINING',
  LIVESTOCK: 'LIVESTOCK',
  LIVESTOCK_OPENING: 'LIVESTOCK_OPENING',
  LIVESTOCK_CLOSING: 'LIVESTOCK_CLOSING',
  LIVESTOCK_CLOSING_AGED: 'LIVESTOCK_CLOSING_AGED',
  LIVESTOCK_TOTAL: 'LIVESTOCK_TOTAL',
  LIVESTOCK_OPENING_BALANCE: 'LIVESTOCK_OPENING_BALANCE',
  FINANCIAL_YEAR_VIEW: 'FINANCIAL_YEAR_VIEW',
}

export const HEADER_TYPES = {
  REVISION: 'REVISION',
  MONTH: 'MONTH',
  TOTAL: 'TOTAL',
  STOCK_ON_HAND: 'STOCK_ON_HAND',
  LIVESTOCK_OPENING: 'LIVESTOCK_OPENING',
  LIVESTOCK_CLOSING: 'LIVESTOCK_CLOSING',
  LIVESTOCK_CLOSING_AGED: 'LIVESTOCK_CLOSING_AGED',
  FY_VIEW_COLUMN: 'FY_VIEW_COLUMN',
}

export enum LIVESTOCK_EVENT_TYPES {
  SALE = 'SALE',
  PURCHASE = 'PURCHASE',
  OTHER = 'OTHER',
  BIRTH = 'BIRTH',
  TRANSFER_IN = 'TRANSFER_IN',
  TRANSFER_OUT = 'TRANSFER_OUT',
  DEATH = 'DEATH',
  MISSING = 'MISSING',
  FOUND = 'FOUND',
  KILLED = 'KILLED',
  MILK_PRODUCTION = 'MILKPROD',
}

export type LIVESTOCK_TRANSFER_EVENT =
  | LIVESTOCK_EVENT_TYPES.TRANSFER_IN
  | LIVESTOCK_EVENT_TYPES.TRANSFER_OUT

export const LIVESTOCK_TRANSFERS = [
  LIVESTOCK_EVENT_TYPES.TRANSFER_IN,
  LIVESTOCK_EVENT_TYPES.TRANSFER_OUT,
]

export const LIVESTOCK_EVENT_TYPES_ARRAY = Object.values(LIVESTOCK_EVENT_TYPES)

export const LIVESTOCK_MOVEMENT_TYPES = {
  ...LIVESTOCK_EVENT_TYPES,
  TRANSFER: 'TRANSFER',
}

export const NEGATIVE_EVENT_TYPES = [
  LIVESTOCK_EVENT_TYPES.DEATH,
  LIVESTOCK_EVENT_TYPES.MISSING,
  LIVESTOCK_EVENT_TYPES.KILLED,
  LIVESTOCK_EVENT_TYPES.TRANSFER_OUT,
  LIVESTOCK_EVENT_TYPES.SALE,
]

export enum GRID_TYPES {
  CASHFLOW = 'CASHFLOW',
  LIVESTOCK = 'LIVESTOCK',
  DAIRYFORECASTER = 'DAIRYFORECASTER',
}

export const LIVESTOCK_EVENT_NAMES = {
  [LIVESTOCK_EVENT_TYPES.TRANSFER_IN]: 'Transfers In',
  [LIVESTOCK_EVENT_TYPES.TRANSFER_OUT]: 'Transfers Out',
  [LIVESTOCK_EVENT_TYPES.DEATH]: 'Deaths',
  [LIVESTOCK_EVENT_TYPES.OTHER]: 'Other',
  [LIVESTOCK_EVENT_TYPES.KILLED]: 'Killed',
  [LIVESTOCK_EVENT_TYPES.MISSING]: 'Missing',
  [LIVESTOCK_EVENT_TYPES.FOUND]: 'Found',
  [LIVESTOCK_EVENT_TYPES.SALE]: 'Sales',
  [LIVESTOCK_EVENT_TYPES.PURCHASE]: 'Purchases',
  [LIVESTOCK_EVENT_TYPES.BIRTH]: 'Births',
  [LIVESTOCK_EVENT_TYPES.MILK_PRODUCTION]: 'Milk Production',
}

export const FINANCIAL_LIVESTOCK_EVENTS = [
  LIVESTOCK_EVENT_TYPES.PURCHASE,
  LIVESTOCK_EVENT_TYPES.SALE,
]

export const FINANCIAL_LIVESTOCK_EVENTS_INCL_OTHER =
  FINANCIAL_LIVESTOCK_EVENTS.concat([LIVESTOCK_EVENT_TYPES.OTHER])

export const getGridType = (state): GRID_TYPES =>
  state.getIn(['ui', 'plans', 'grid', 'gridType'])

export function isTransferEvent(eventType: string): boolean {
  return [
    LIVESTOCK_EVENT_TYPES.TRANSFER_OUT,
    LIVESTOCK_EVENT_TYPES.TRANSFER_IN,
  ].includes(eventType as LIVESTOCK_EVENT_TYPES)
}

export function isBirtherEvent(eventType: LIVESTOCK_EVENT_TYPES) {
  return eventType === LIVESTOCK_EVENT_TYPES.BIRTH
}

export function isNonFinancialLivestockEvent(eventType: LIVESTOCK_EVENT_TYPES) {
  return !FINANCIAL_LIVESTOCK_EVENTS.includes(eventType)
}

export function isNonFinancialLivestockEventInclOther(
  eventType: LIVESTOCK_EVENT_TYPES,
) {
  return !FINANCIAL_LIVESTOCK_EVENTS_INCL_OTHER.includes(eventType)
}

export const BREEDING_TYPES = {
  PROGENY: 'PROGENY',
  BREEDING: 'BREEDING',
  SECONDARY_BREEDING: 'SECONDARY_BREEDING',
  NONE: 'NONE',
}
