import { createSvgIcon } from '@mui/material/utils'

export const ExpandSectionIcon = createSvgIcon(
  <path
    d='M9.7025,14.8725 L12.1725,12.4225 L14.6325,14.8825 C15.0225,15.2725 15.6525,15.2725 16.0425,14.8825 C16.4325,14.4925 16.4325,13.8625 16.0425,13.4725 L12.8725,10.2925 C12.4825,9.9025 11.8525,9.9025 11.4625,10.2925 L8.2925,13.4625 C7.9025,13.8525 7.9025,14.4825 8.2925,14.8725 C8.6825,15.2625 9.3125,15.2625 9.7025,14.8725 Z'
    fillRule='nonzero'
    transform='translate(12.1675, 12.5875) scale(1, -1) translate(-12.1675, -12.5875)'
  ></path>,
  'ExpandSection',
)
