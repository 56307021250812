/** @type {import('tailwindcss').Config} */
export default {
  content: ['./app/javascript/frontend/src/**/*.{html,jsx,js,tsx}'],
  theme: {
    colors: {
      // Grays as per design system - https://projects.invisionapp.com/share/FU135N8Q95TC#/screens/471654148
      gray: {
        50: '#F3F5F7',
        100: '#EAEEF1',
        200: '#DFE5EA',
        300: '#D4D9DE',
        400: '#BEC7D1',
        500: '#ABB6C3',
        600: '#8A96A4',
        700: '#728195',
        800: '#58697D',
        900: '#333E48',
      },
      white: '#ffffff',
      black: '#000000',
      transparent: 'transparent',
      current: 'currentColor',
    },
    screens: {
      screen: { raw: 'screen' },
      xs: '0px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    extend: {
      fontSize: {
        xs: '0.75rem',
      },
      dropShadow: {
        'action-bar': 'var(--shadow-action-bar)',
      },
      boxShadow: {
        codingLine: '0px 0px 10px rgba(88, 105, 125,0.5)', // gray 800 and 50% opacity. Can't reference theme variable and get opacity to work
        'flat-sm': 'var(--shadow-flat-sm)',
        'flat-sm-hover': 'var(--shadow-flat-sm-hover)',
        'flat-md': 'var(--shadow-flat-md)',
        'flat-md-hover': 'var(--shadow-flat-md-hover)',
        'action-bar': 'var(--shadow-action-bar)',
      },
      colors: {
        blueDuckEgg: '#6693A2',
        blueLight: '#3f75ae',
        blueMadison: '#0C427C',
        blueNavy: '#1C3059',
        bluePacific: '#0088c4',
        blueSeaSpray: '#ADD4DF',
        blueSky: '#24C2CD',
        cyan: '#51C8D8',
        disabled: '#f0f0f0',
        greenTropical: '#A8E7C6',
        greenEmerald: '#019073',
        greenForest: '#006666',
        greenMint: '#E5F2F2',
        greenLime: '#92D600',
        primary: '#008080',
        polar: '#e5f8f9',
        focusLime: '#92D600',
        redThunderbird: '#c61010', // This is used for form field errors
        redScarlett: '#A40216',
        secondary: '#0f539b',
        tertiary: '#ce031c',
        orangeYellow: '#F6A623',
        orangeAmber: '#EF4E08',
        orangeTrinidad: '#D64607',
        orangeLinen: '#FDF7F1',
        whiteCatskill: '#e5f2f2',
        whiteTitan: '#d1d8df',
        lightPurple: '#e1DFF1',
        darkPurple: '#483d8B',
        yellowUtility: '#FFEB8C',
      },
      keyframes: {
        'fade-in': {
          '0%': { opacity: '0' },
          '50%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
      },
      animation: {
        'fade-in': 'fade-in 1s linear forwards',
        'spin-slow': 'spin 2s linear infinite',
      },
    },
  },
  plugins: [require('@tailwindcss/container-queries')],
}
