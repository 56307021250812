import { styled } from '@mui/material/styles'
import MuiToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton'
import { FC } from 'react'

export type Option<T> = {
  value: T
  label: string
}

export const BooleanOptions: Option<boolean>[] = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

const StyledToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(
  ({ theme, color }) => ({
    textTransform: 'none',
    textWrap: 'nowrap',
    fontWeight: 600,
    // Allow the selected button's border to use the specified color
    '&.Mui-selected': {
      borderColor: theme.palette[color ?? 'primary'].main,
      borderLeftStyle: 'solid',
      '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderLeftColor: theme.palette[color ?? 'primary'].main,
      },
    },
  }),
)

interface Props extends ToggleButtonProps {}

/**
 * A wrapper around [MUI ToggleButton](https://mui.com/material-ui/react-toggle-button/)
 *
 * This is similar to `ButtonSwitch` but allows the buttons to be either exclusive or not.
 */
const ToggleButton: FC<Props> = (props) => {
  return <StyledToggleButton {...props}>{props.children}</StyledToggleButton>
}

export default ToggleButton
