import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { forwardRef, RefObject } from 'react'

import { colors } from '~/tailwindResolver'

import { AutorenewIcon } from '../Icons'

const StyledIconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop: string) =>
    !['htmlColor', 'hoverColor', 'isWaiting'].includes(prop),
})<Props>(({ htmlColor, hoverColor }) => {
  return {
    color: htmlColor,
    '&:hover': {
      backgroundColor: 'transparent', // Doing this instead of using `disableRipple` prop because otherwise we'd lose the focus ripple too
      color: hoverColor,
    },
  }
})

interface Props extends IconButtonProps {
  /** The default `htmlColor` is Gray 600 (from `muiTheme.ts`) */
  htmlColor?: string
  /** The default `hoverColor` is blueLight  */
  hoverColor?: string
  isWaiting?: boolean // show a spinner if the button has been clicked and we are waiting for a response
}

/**
 * A wrapper around [MUI IconButton](https://mui.com/material-ui/react-button/#icon-button).
 *
 * For a white IconButton use `WhiteIconButton`.
 * If `htmlColor` is not specified then the default colour from `muiTheme.ts` will be used - Gray 600.
 * To use the more subtle gray, use - `htmlColor={colors.gray[300]}`
 *
 * Invision Documentation: [IconButton](TBC)
 */
const IconButton = forwardRef(
  (props: Props, ref: RefObject<HTMLButtonElement>) => {
    const {
      children,
      htmlColor,
      hoverColor = colors.blueLight,
      isWaiting,
      ...rest
    } = props

    return (
      <StyledIconButton
        {...rest}
        ref={ref}
        htmlColor={htmlColor}
        hoverColor={hoverColor}
        disabled={isWaiting || props.disabled}
      >
        {isWaiting ? (
          <AutorenewIcon className='motion-safe:animate-spin-slow' />
        ) : (
          props.children
        )}
      </StyledIconButton>
    )
  },
)

IconButton.displayName = 'IconButton'

export type { IconButtonProps }
export default IconButton
