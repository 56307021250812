import { Collection } from 'immutable'

export function isUndefinedOrNull(x: any): x is undefined | null {
  return x === undefined || x === null
}

export function toUndefinedIfNull<T>(x: T | null | undefined): T | undefined {
  return x ?? undefined
}

type Mapper<K, V, R> = (value: V, key: K | undefined) => R | undefined

// findMap :: Immutable.Iterable<a> -> (a -> b?) -> b?
export function findMap<K, V, R>(
  iterable: Collection<K, V>,
  mapperPredicate: Mapper<K, V, R>,
): R | undefined {
  return iterable
    .toSeq()
    .map(mapperPredicate)
    .find((v) => !!v)
}

export type None = null | undefined
export type Maybe<T> = T | None

export function isNone<T>(maybe: Maybe<T>): maybe is None {
  return maybe === null || maybe === undefined
}

export function isNotNone<T>(maybe: Maybe<T>): maybe is T {
  return maybe !== null && maybe !== undefined
}

export function narrow<T>(maybe: Maybe<T>): T {
  if (isNotNone(maybe)) {
    return maybe
  } else {
    throw new Error(`Can not narrow because Maybe<${maybe}> is a None`)
  }
}

export function narrowWithDefault<T>(maybe: Maybe<T>, defaultValue: T): T {
  if (isNotNone(maybe)) {
    return maybe
  } else {
    return defaultValue
  }
}

export function assert(condition: any, msg?: string): asserts condition {
  if (!condition) {
    throw new Error(msg)
  }
}

// remove all attributes with undefined or null from the object
export function removeEmpty(obj: any): any {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v !== null || v !== undefined),
  )
}
