import { List, Map, Record } from 'immutable'

import { removeEmpty } from '~/common/utils'

import {
  DatedGstFrequency,
  DatedGstFrequencyList,
  DatedGstFrequencyServerData,
} from './DatedGstFrequency'
import { FarmCountryInfo } from './FarmCountryInfo'

type RelationshipName = Map<string, any>

type AusGstFrequency = 1 | 3 | 12
type NzGstFrequency = 1 | 2 | 6
export type GstFrequency = AusGstFrequency | NzGstFrequency

type AusGstBasis = 'CASH' | 'ACCRUAL'
type NzlGstBasis = 'INVOICE' | 'PAYMENTS'
export type GstBasis = AusGstBasis | NzlGstBasis

export interface FarmFinancialDetailServerData {
  id: number
  accounts_payable_code: string | null
  accounts_receivable_code: string | null
  currency: string | null
  dated_gst_frequencies: DatedGstFrequencyServerData[] | null
  default_gst_rate: string | null
  exempt_tax_code: string | null
  farm_id: string | null
  generate_gst_invoice: boolean
  gst_basis: GstBasis | null
  gst_frequency: GstFrequency | null
  gst_number: string | null
  initial_gst_frequency: number | null
  input_tax_code: string | null
  is_gst_registered: boolean | null
  nil_tax_code: string | null
  output_tax_code: string | null
  relationshipNames: RelationshipName[] | null
  suspense_code: string | null
  type: string | null
}

interface FarmFinancialDetailFields {
  id: number
  accounts_payable_code: string
  accounts_receivable_code: string
  currency: string
  dated_gst_frequencies: DatedGstFrequencyList
  default_gst_rate: string
  exempt_tax_code: string | null
  farm_id: string
  generate_gst_invoice: boolean
  gst_basis: GstBasis
  gst_frequency: GstFrequency
  gst_number: string
  initial_gst_frequency: number
  input_tax_code: string | null
  is_gst_registered: boolean
  nil_tax_code: string | null
  output_tax_code: string | null
  relationshipNames: List<RelationshipName>
  suspense_code: string | null
  type: string
}

export const farmFinancialDetailDefaults: FarmFinancialDetailFields = {
  accounts_payable_code: '',
  accounts_receivable_code: '',
  currency: 'NZD',
  dated_gst_frequencies: List([]),
  default_gst_rate: '',
  exempt_tax_code: null,
  farm_id: '',
  generate_gst_invoice: true,
  gst_basis: 'PAYMENTS',
  gst_frequency: 2,
  gst_number: '',
  id: -1,
  initial_gst_frequency: 0,
  input_tax_code: null,
  is_gst_registered: true,
  nil_tax_code: null,
  output_tax_code: null,
  relationshipNames: List([]),
  suspense_code: null,
  type: '',
}

export class FarmFinancialDetail extends Record(farmFinancialDetailDefaults) {
  static fromServerJS(
    data: FarmFinancialDetailServerData,
  ): FarmFinancialDetail {
    return new FarmFinancialDetail(
      removeEmpty({
        ...data,
        dated_gst_frequencies: DatedGstFrequency.fromServerArray(
          data.dated_gst_frequencies || [],
        ),
      }),
    )
  }

  static nullRecord(countryInfo: FarmCountryInfo): FarmFinancialDetail {
    return new FarmFinancialDetail({
      ...farmFinancialDetailDefaults,
      currency: countryInfo.currency,
      gst_basis: countryInfo.defaultGstBasis,
      gst_frequency: countryInfo.defaultGstFrequency,
      initial_gst_frequency: countryInfo.defaultGstFrequency,
    })
  }

  static nzlPlaceholder(): FarmFinancialDetail {
    return new FarmFinancialDetail({
      ...farmFinancialDetailDefaults,
      currency: 'NZD',
      gst_frequency: 2,
      initial_gst_frequency: 2,
      default_gst_rate: '15',
    })
  }

  isPaymentGstBasis(): boolean {
    return this.gst_basis === 'PAYMENTS' || this.gst_basis === 'CASH'
  }

  isInvoiceGstBasis(): boolean {
    return this.gst_basis === 'INVOICE' || this.gst_basis === 'ACCRUAL'
  }
}
