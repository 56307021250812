import Bugsnag, { Client as BugsnagClient, NotifiableError } from '@bugsnag/js'
import BugsnagReact from '@bugsnag/plugin-react'
import * as React from 'react'

// interface ENVVAR
type CMFWindow = Window &
  typeof globalThis & {
    __env?: any
  }
// Suppress logout failure error on bugsnag
function isLogOutFailure(event: any): boolean {
  return (
    event?.response?.status === 404 &&
    event?.request?.responseURL?.includes('api/v1/logout')
  )
}

// Suppress 409 error(Conflict error) because we shows error message to users.
function isConflictError(event: any): boolean {
  return event?.response?.status === 409
}

function buildBugsnagClient(): BugsnagClient {
  const mockedNotify = {
    notify: (error: NotifiableError): void => {
      console.log(error)
    },
  } as BugsnagClient

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test' ||
    process.env.CI === 'true' ||
    process.env.STORYBOOK
  ) {
    // mock out our notify
    return mockedNotify
  }

  const cmfWindow: CMFWindow = window

  if (cmfWindow.__env.BUGSNAG_API_KEY_FRONTEND) {
    const client = Bugsnag.start({
      apiKey: cmfWindow.__env.BUGSNAG_API_KEY_FRONTEND,
      plugins: [new BugsnagReact(React)],
      releaseStage: cmfWindow.__env.BUGSNAG_BRANCH,
      appVersion: cmfWindow.__env.BUGSNAG_APP_VERSION,
      onError: function (event) {
        // If FullStory object exists.
        if (window['FS'] && window['FS'].getCurrentSessionURL) {
          event.addMetadata('fullstory', {
            urlAtTime: window['FS'].getCurrentSessionURL(true),
          })
        }
        return !isLogOutFailure(event) && !isConflictError(event)
      },
    })
    window['Bugsnag'] = client
    return client
  }

  return mockedNotify
}

export const bugsnagClient: BugsnagClient = buildBugsnagClient()

export default bugsnagClient
