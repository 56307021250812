import { FC, MouseEvent, ReactNode } from 'react'
import { Link as RouterLink, LinkProps, To } from 'react-router-dom'

import { OpenInNewIcon } from '../Icons'
import { cn } from '../utils/cn'

type Props = {
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  type?: 'button' | 'submit' | 'reset'
  children: ReactNode
  blockLevel?: boolean
  className?: string
  noExternalLinkIcon?: boolean
} & (({ to: To } & LinkProps) | { to?: never }) &
  (
    | ({ href: string } & React.AnchorHTMLAttributes<HTMLAnchorElement>)
    | { href?: never }
  )
/**
 * A standard link component.
 *
 * Supply:
 *  - `onClick` to render a button, or
 *  - `to` to render a React Router `Link`, or
 *  - `href` to render an anchor tag
 *
 * Invision Documentation: [Text Links](https://projects.invisionapp.com/share/FU135N8Q95TC#/screens/473132174_Text_Links)
 */
export const Link: FC<Props> = (props) => {
  const {
    type = 'button',
    children,
    className,
    noExternalLinkIcon = false,
    blockLevel = true, // maybe the default should be false? but adding this during regression, so making true
    ...rest
  } = props

  const commonClasses = 'text-gray-900 font-semibold text-sm'
  const anchorClasses = cn(
    commonClasses,
    'underline decoration-blueLight underline-offset-4 hover:text-blueLight',
    className,
  )

  if (rest.to) {
    return (
      <RouterLink className={anchorClasses} {...rest}>
        {children}
      </RouterLink>
    )
  }

  if (rest.href) {
    return (
      <span
        className={cn({
          'inline-flex gap-1': props.href && props.target === '_blank',
          block: blockLevel,
        })}
      >
        <a className={anchorClasses} {...rest}>
          {children}
        </a>
        {!noExternalLinkIcon && (
          <OpenInNewIcon fontSize='small' className='text-gray-800' />
        )}
      </span>
    )
  }

  if (rest.onClick) {
    return (
      <button
        type={type}
        onClick={rest.onClick}
        className={cn(
          commonClasses,
          'border-b border-blueLight leading-[18px] hover:text-blueLight',
          className,
        )}
      >
        {children}
      </button>
    )
  }

  return <span className={cn(commonClasses, className)}>{children}</span>
}

export default Link
