import MuiButton, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { forwardRef, ReactNode, RefObject, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { colors } from '~/tailwindResolver'

import { AutorenewIcon, OpenInNewIcon } from '../Icons'

const BaseButton = styled(MuiButton)<ButtonProps>(({ size }) => ({
  whiteSpace: 'nowrap',
  height: !size || size === 'medium' ? 40 : undefined,
  paddingLeft: 16,
  paddingRight: 16,
  lineHeight: 1,
}))
const PrimaryButton = styled(BaseButton)<ButtonProps>(() => ({
  backgroundColor: `${colors.blueLight}`,
  color: 'white',
  '&:hover': {
    backgroundColor: `${colors.secondary}`,
  },
  '&:disabled': {
    backgroundColor: `${colors.gray[300]}`,
    color: 'white',
  },
}))
const SecondaryButton = styled(BaseButton)<ButtonProps>(() => ({
  borderColor: `${colors.gray[600]}`,
  borderStyle: 'solid',
  borderWidth: 'thin',
  backgroundColor: 'white',
  color: `${colors.gray[800]}`,
  '&:hover': {
    borderColor: `${colors.gray[900]}`,
    backgroundColor: 'white',
    color: `${colors.gray[900]}`,
  },
  '&:disabled': {
    backgroundColor: `${colors.gray[100]}`,
    borderColor: `${colors.gray[500]}`,
    color: `${colors.gray[600]}`,
  },
}))
const StandardButton = styled(BaseButton)<ButtonProps>(() => ({
  borderColor: `${colors.gray[400]}`,
  borderStyle: 'solid',
  borderWidth: 'thin',
  backgroundColor: `${colors.gray[50]}`,
  color: `${colors.blueLight}`,
  '&:hover': {
    backgroundColor: `${colors.gray[300]}`,
    borderColor: `${colors.gray[300]}`,
    color: `${colors.blueLight}`,
  },
  '&:disabled': {
    backgroundColor: `${colors.gray[100]}`,
    borderColor: `${colors.gray[400]}`,
    color: `${colors.gray[400]}`,
  },
}))
const ImportantButton = styled(BaseButton)<ButtonProps>(() => ({
  backgroundColor: `${colors.redThunderbird}`,
  color: `white`,
  '&:hover': {
    backgroundColor: `${colors.redScarlett}`,
  },
  '&:disabled': {
    backgroundColor: `${colors.gray[100]}`,
  },
}))
const ChipButton = styled(BaseButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<Props>(({ active, disabled }) => ({
  borderColor: active ? `${colors.gray[700]}` : `${colors.gray[600]}`,
  borderStyle: 'solid',
  borderWidth: 'thin',
  backgroundColor: active ? `${colors.gray[700]}` : 'white',
  height: 32,
  minWidth: 'auto',
  color: active ? 'white' : `${colors.gray[800]}`,
  fontSize: 13,
  '&:hover': {
    borderColor: `${colors.blueLight}`,
    backgroundColor: active ? `${colors.gray[700]}` : 'white',
    color: active ? 'white' : `${colors.blueLight}`,
    filter: active ? 'brightness(90%)' : undefined,
    '.MuiButton-startIcon,.MuiButton-endIcon': {
      color: active ? 'white' : colors.blueLight,
    },
  },
  ...(disabled && {
    borderColor: `${colors.gray[300]}`,
    color: `${colors.gray[400]}`,
  }),
  '.MuiButton-startIcon,.MuiButton-endIcon': {
    color: disabled
      ? `${colors.gray[400]}`
      : active
        ? 'white'
        : `${colors.gray[600]}`,
  },
}))

export type Props = ButtonProps & {
  // Variants
  primary?: boolean
  secondary?: boolean
  standard?: boolean
  important?: boolean
  chip?: boolean
  /** This indicates whether a chip button is 'active' */
  active?: boolean
  waiting?: boolean
  externalLink?: boolean
  waitingText?: ReactNode
} & (
    | { component?: ButtonProps['component'] }
    | {
        component: typeof Link
        to: string
      }
  )

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'important'
  | 'standard'
  | 'chip'
/**
 * Standard Button component.
 *
 * Invision Documentation: [Buttons](https://projects.invisionapp.com/share/FU135N8Q95TC#/screens/472015753)
 */
const Button = forwardRef((props: Props, ref: RefObject<HTMLButtonElement>) => {
  const {
    primary = false,
    secondary = false,
    standard = false,
    important = false,
    chip = false,
    disabled,
    waiting = false,
    waitingText = 'Waiting',
    externalLink = false,
    ...buttonProps
  } = props

  /** Only use a single variant */
  const variant = useMemo((): ButtonVariant => {
    if (primary) {
      return 'primary'
    }
    if (secondary) {
      return 'secondary'
    }
    if (standard) {
      return 'standard'
    }
    if (important) {
      return 'important'
    }
    if (chip) {
      return 'chip'
    }
    // Default if nothing is specified is primary
    return 'primary'
  }, [standard, important, primary, secondary, chip])

  const VariantButton = useMemo(() => {
    switch (variant) {
      case 'primary':
        return PrimaryButton
      case 'secondary':
        return SecondaryButton
      case 'important':
        return ImportantButton
      case 'standard':
        return StandardButton
      case 'chip':
        return ChipButton
    }
  }, [variant])

  const dataProp = { [`data-${variant}-button`]: true }

  const linkProps = externalLink
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {}

  const startIcon = useMemo(() => {
    if (props.waiting) {
      return <AutorenewIcon className='motion-safe:animate-spin-slow' />
    }
    if (externalLink) {
      return <OpenInNewIcon fontSize='small' className='text-gray-800' />
    }
    return undefined
  }, [externalLink, props.waiting])

  return (
    <VariantButton
      ref={ref}
      disabled={disabled || waiting}
      startIcon={startIcon}
      {...linkProps}
      {...dataProp}
      {...buttonProps}
    >
      {waiting ? waitingText : props.children}
    </VariantButton>
  )
})

Button.displayName = 'Button'

export default Button
