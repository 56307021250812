import resolveConfig from 'tailwindcss/resolveConfig'
import invariant from 'ts-invariant'

import tailwindConfig from '~/../../../../tailwind.config.ts'

export const twConfig = resolveConfig(tailwindConfig)
export const colors = twConfig?.theme?.colors

export type BreakpointNames = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export type AppBreakpoints = {
  [k in BreakpointNames]: number
}

const getAppBreakpoints = () => {
  const screens = twConfig?.theme?.screens
  if (!screens) throw new Error('No screens in tailwind config')

  const tailwindBreakpoints = Object.entries(screens).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [key]: parseInt(String(value).replace('px', '')),
      }
    },
    {} as AppBreakpoints,
  )

  return tailwindBreakpoints
}

const getTheme = () => {
  const theme = twConfig?.theme
  invariant(theme, 'No theme in tailwind config')

  return theme
}

export const theme = getTheme()
export const appBreakpoints = getAppBreakpoints()
