import { List, Record } from 'immutable'

import { UUID } from '~/state/model/ModelTypes'

import {
  CodeRuleDefinition,
  CodeRuleDefinitionFromServer,
} from './CodeRuleDefinition'
import { CodingRuleJS } from './CodingRuleJS'

export enum CodingRuleTarget {
  bankTransaction = 'BANK_TRANSACTION',
  invoice = 'INVOICE',
}

// saved in auto_coded, true  = REVIEW_AUTO, false = REVIEW_MANUAL
export const CODING_RULE_REVIEW_TYPES = {
  REVIEW_AUTO: 'review_auto',
  REVIEW_MANUAL: 'review_manual',
}

export interface CodingRuleFromServer {
  auto_coded?: boolean
  deleted_at?: string
  farm_id?: UUID
  id?: UUID
  position?: number
  rule: CodeRuleDefinitionFromServer
  target: CodingRuleTarget
}

export interface FarmCodingRuleCommonFields {
  auto_coded: boolean
  deleted_at?: string
  farm_id: UUID
  id: UUID
  position: number
  target: CodingRuleTarget
  _globalIndex: number
}

interface FarmCodingRuleFields extends FarmCodingRuleCommonFields {
  rule: CodeRuleDefinition
}

export const farmCodingRuleDefaults: FarmCodingRuleFields = {
  auto_coded: false,
  deleted_at: undefined,
  farm_id: '',
  id: '',
  position: 0,
  rule: CodeRuleDefinition.nullRecord(),
  target: CodingRuleTarget.bankTransaction,
  _globalIndex: 0,
}

export type CodingRuleList = List<FarmCodingRule>

export class FarmCodingRule extends Record(farmCodingRuleDefaults) {
  static fromServerJS(data: CodingRuleFromServer): FarmCodingRule {
    const fields: FarmCodingRuleFields = {
      ...farmCodingRuleDefaults,
      ...data,
      rule: CodeRuleDefinition.fromServerJS(data.rule, data.target),
    }
    return new FarmCodingRule(fields)
  }

  static fromServerArray(src: CodingRuleFromServer[]): CodingRuleList {
    return List(src.map((a) => FarmCodingRule.fromServerJS(a)))
  }

  static findRule(
    rules: CodingRuleList,
    id: UUID,
    target: CodingRuleTarget,
  ): FarmCodingRule {
    return (
      rules.find((rule) => rule.id === id) ||
      FarmCodingRule.nullRecord({ target })
    )
  }

  static nullRecord(data = {}): FarmCodingRule {
    return new FarmCodingRule({ ...farmCodingRuleDefaults, ...data })
  }

  static toJSArray(codingRules: CodingRuleList): CodingRuleJS[] {
    return codingRules.toJS() as CodingRuleJS[]
  }

  static toJS(codingRule: FarmCodingRule): CodingRuleJS {
    return codingRule.toJS() as CodingRuleJS
  }

  isTransaction(): boolean {
    return this.target === 'BANK_TRANSACTION'
  }

  isInvoice(): boolean {
    return this.target === 'INVOICE'
  }
}

export const isExtcodeInAnyofTheseRules = (
  rules: CodingRuleList,
  extcodeId: string,
): boolean => rules.some((rule) => rule.rule.isExtcodeInRule(extcodeId))
