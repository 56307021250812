import { List, Record } from 'immutable'

import {
  CodeRuleActionFromServer,
  CodeRuleActionJS,
  CodeRuleActionList,
  CodeRuleActionRecord,
  nullCodeRuleAction,
} from './CodeRuleAction'
import {
  CodeRuleCriteriaFromServer,
  CodeRuleCriteriaJS,
  CodeRuleCriteriaList,
  CodeRuleCriteriaRecord,
  nullCodeRuleCriteria,
} from './CodeRuleCriteria'
import { CodingRuleTarget } from './FarmCodingRule'

export interface CodeRuleDefinitionFromServer {
  actions: CodeRuleActionFromServer[]
  criteria: CodeRuleCriteriaFromServer[]
  version?: number
}

interface CodeRuleDefinitionFields {
  actions: CodeRuleActionList
  criteria: CodeRuleCriteriaList
  version: number
}

export interface CodeRuleDefinitionJS {
  actions: CodeRuleActionJS[]
  criteria: CodeRuleCriteriaJS[]
  version: number
}

const codeRuleDefinitionDefaults: CodeRuleDefinitionFields = {
  actions: List(),
  criteria: List([CodeRuleCriteriaRecord.nullRecord()]),
  version: 1,
}

export class CodeRuleDefinition extends Record(codeRuleDefinitionDefaults) {
  static fromServerJS(
    data: CodeRuleDefinitionFromServer,
    target: CodingRuleTarget,
  ): CodeRuleDefinition {
    const fields: CodeRuleDefinitionFields = {
      ...codeRuleDefinitionDefaults,
      ...data,
      actions: CodeRuleActionRecord.fromServerArray(data.actions),
      criteria: CodeRuleCriteriaRecord.fromServerArray(data.criteria, target),
    }
    return new CodeRuleDefinition(fields)
  }

  static nullRecord(): CodeRuleDefinition {
    return new CodeRuleDefinition(codeRuleDefinitionDefaults)
  }

  isExtcodeInRule(extcodeId): boolean {
    return this.actions.some((action) => action.extcode_id === extcodeId)
  }
}

///

export const nullCodeRuleDefinition = (data = {}): CodeRuleDefinitionJS => ({
  actions: [nullCodeRuleAction()],
  criteria: [nullCodeRuleCriteria()],
  version: 1,
  ...data,
})
