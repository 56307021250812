import { List, Record } from 'immutable'

import { Option } from '~/common/ui/forms/selects/Select'
import { DateISOString } from '~/common/utils/DateRangeTypes'
import { today, todayDateISOString, yyyymmdd } from '~/common/utils/DateUtil'
import { TimeStampString, UUID } from '~/state/model/ModelTypes'

export const FEED_STATUS_TYPES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  BROKEN: 'BROKEN', // used for auth issue - which requires reauthentication
}

type ImportStatus = 'NEVER' | 'CONNECTED' | 'ONGOING' | 'STOPPED' | null

export type BankAccountFeedStatusType = string | undefined

export enum BankAccountStatus {
  API = 'API',
  FEED = 'FEED',
  MANUAL = 'MANUAL',
  INACTIVE = 'INACTIVE',
}

export interface FarmBankAccountServerData {
  account_alias?: string
  accountant_code?: string
  account_name?: string
  account_number?: string
  active?: boolean
  allow_manual_balance_for_bank_feed?: boolean
  bank_id?: string
  bank_name?: string
  connector_id?: string | number
  created_at?: TimeStampString
  current_balance_on?: string
  current_balance?: string
  direct_feed_start_date?: string
  feed_status?: BankAccountFeedStatusType
  first_transaction_date?: DateISOString
  has_overdraft?: boolean
  id: UUID
  import_status?: ImportStatus
  inactive_from?: string
  last_imported_at?: string
  opening_balance_date: DateISOString
  opening_balance?: string
  overdraft_limit?: string
  overdraft_rate?: string
  updated_at?: TimeStampString
  yearly_overdraft_rates?: any
  account_type: 'standard' | 'credit_card'
}

interface FarmBankAccountRecordFields {
  account_alias?: string
  accountant_code?: string
  account_name?: string
  account_number?: string
  active: boolean
  allow_manual_balance_for_bank_feed: boolean
  bank_id: string
  bank_name: string
  checked: boolean
  connector_id?: number
  created_at?: TimeStampString
  current_balance_on?: string
  current_balance?: string
  direct_feed_start_date?: string
  feed_status?: string
  first_transaction_date?: DateISOString
  has_overdraft?: boolean
  id: UUID
  import_status?: ImportStatus
  inactive_from?: string
  last_imported_at?: string
  opening_balance_date: DateISOString
  opening_balance?: string
  overdraft_limit?: string
  overdraft_rate?: string
  updated_at?: TimeStampString
  selected: boolean
  yearly_overdraft_rates?: any[]
  account_type: 'standard' | 'credit_card'
}

export const farmBankAccountDefaults: FarmBankAccountRecordFields = {
  account_alias: '',
  accountant_code: '',
  account_name: '',
  account_number: '',
  active: false,
  allow_manual_balance_for_bank_feed: false,
  bank_id: '',
  bank_name: '',
  checked: false, // TODO this is only for SOMETHING..., BUT it shouldn't be on this Record!
  connector_id: undefined,
  created_at: '',
  current_balance_on: undefined,
  current_balance: undefined,
  direct_feed_start_date: undefined,
  feed_status: undefined,
  first_transaction_date: undefined,
  has_overdraft: false,
  id: '',
  import_status: null,
  inactive_from: undefined,
  last_imported_at: undefined,
  opening_balance_date: todayDateISOString,
  opening_balance: undefined,
  overdraft_limit: '0',
  overdraft_rate: undefined,
  selected: false, // TODO this is only for the select to import to BNZ, BUT it shouldn't be on this Record
  updated_at: '',
  yearly_overdraft_rates: [],
  account_type: 'standard',
}

export type FarmBankAccountList = List<FarmBankAccountRecord>

export class FarmBankAccountRecord extends Record(farmBankAccountDefaults) {
  static fromServerJS(data: FarmBankAccountServerData): FarmBankAccountRecord {
    const fields: FarmBankAccountRecordFields = {
      ...farmBankAccountDefaults,
      ...data,
      connector_id: Number(data.connector_id),
      account_alias: data.account_alias?.toLocaleUpperCase(),
    }
    return new FarmBankAccountRecord(fields)
  }

  static fromServerArray(
    src: FarmBankAccountServerData[],
  ): FarmBankAccountList {
    return List(src.map((a) => FarmBankAccountRecord.fromServerJS(a)))
  }

  static nullRecord(
    overrides?: Partial<FarmBankAccountRecordFields>,
  ): FarmBankAccountRecord {
    return new FarmBankAccountRecord({
      ...farmBankAccountDefaults,
      ...overrides,
    })
  }

  static findExactAccountIdInList(
    id: UUID | undefined | null,
    bankAccounts: FarmBankAccountList | undefined,
  ): FarmBankAccountRecord | undefined {
    if (id && bankAccounts) {
      return bankAccounts.find((acct) => acct.id === id)
    }
    return undefined
  }

  static findAccountIdInList(
    id: UUID | undefined | null,
    bankAccounts: FarmBankAccountList,
  ): FarmBankAccountRecord {
    return this.findExactAccountIdInList(id, bankAccounts) || this.nullRecord()
  }

  openingBalanceDate(): string {
    return this.opening_balance_date || yyyymmdd(today())
  }

  isActiveAccount(): boolean {
    return this.active && this.isEverOperational()
  }

  isInactiveAccount(): boolean {
    return !this.active && this.isEverOperational()
  }

  // can we have a consistent display for bank accounts?
  displayName(): string {
    return `${this.account_alias} - ${this.account_name}`
  }

  // this covers cases live the BNZ API where we create bank accounts from the API, but they may NEVER be used in that farm
  // it does not cover OLD inactive accounts that are no longer used - they where once active.
  isEverOperational(): boolean {
    if (this.active) {
      return true
    }

    if (this.connector_id) {
      // connected accounts that have never been imported have never been operational
      return !!(this.import_status && this.import_status !== 'NEVER')
    }

    return true
  }

  accountStatus(): BankAccountStatus {
    if (
      this.import_status === 'ONGOING' ||
      this.import_status === 'CONNECTED'
    ) {
      return BankAccountStatus.API
    }
    if (this.feed_status === FEED_STATUS_TYPES.ACTIVE) {
      return BankAccountStatus.FEED
    }
    if (this.active) {
      return BankAccountStatus.MANUAL
    }
    return BankAccountStatus.INACTIVE
  }

  isBankFeedActive(): boolean {
    return this.feed_status === FEED_STATUS_TYPES.ACTIVE
  }

  isBankFeedInactive(): boolean {
    return this.feed_status !== FEED_STATUS_TYPES.ACTIVE
  }

  accountNumberWithHyphen(): string | undefined {
    return this.account_number?.replace(/\s/g, '-')
  }

  isCreditCard(): boolean {
    return this.account_type === 'credit_card'
  }
}

export const getBankAccountOptions = (
  bankAccounts: FarmBankAccountList,
): Option[] => {
  return bankAccounts
    .map((account) => {
      return {
        value: account.id,
        label: account.account_alias || account.bank_name,
      }
    })
    .toArray()
}

export const getBankAccountAlias = (
  bankAccounts: FarmBankAccountList,
  accountId: string,
): string | undefined => {
  return FarmBankAccountRecord.findAccountIdInList(accountId, bankAccounts)
    .account_alias
}

export const sortBankAccountsFn = (
  a: FarmBankAccountRecord,
  b: FarmBankAccountRecord,
  defaultBankAccountId: UUID,
) => {
  if (a.id === defaultBankAccountId) return -1
  if (b.id === defaultBankAccountId) return 1

  if (!a.account_alias) return 1
  if (!b.account_alias) return -1

  if (!a.active && b.active) return 1
  if (a.active && !b.active) return -1

  return a.account_alias.localeCompare(b.account_alias)
}
