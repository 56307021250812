import {
  add,
  endOfMonth,
  format,
  formatISO,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isValid,
  parseISO,
  setMonth,
  setYear,
  startOfDay as startOfDayDateFns,
  startOfMonth,
  sub,
} from 'date-fns'
import { List } from 'immutable'
import moment, { MomentInput } from 'moment'

import { assert, isUndefinedOrNull, Maybe } from '~/common/utils'
import { LocalDate } from '~/common/utils/LocalDate'
import {
  FormattedDateInUi,
  FormattedMonthInUi,
  ISODate,
  YYYYMM,
} from '~/state/model/ModelTypes'

import { DateISOString, YYYYString } from './DateRangeTypes'
import { minimumIntegerDigits } from './StringUtils'

export {
  add,
  format,
  formatDistanceToNow,
  isSameDay,
  isSameMonth,
  isValid,
  isBefore,
  isAfter,
  isEqual,
  sub,
  startOfDay as startOfDayDateFns,
  parseISO,
  endOfMonth,
  startOfMonth,
  isDate,
  isFuture,
  setMonth,
  lastDayOfMonth,
  getMonth as getMonthDateFns,
  getYear,
  getDate,
} from 'date-fns'

// We previously used moment-range extend, which has been removed
// however extendMoment means everything becomes any -
// we need to ensure all the functions in this file are typed correctly
// and then we can remove the any
// export const extendedMoment = extendMoment(moment)
/** @deprecated please don't use Moment */
export const extendedMoment = moment as any
/** @deprecated please don't use Moment */
export type Moment = moment.Moment

const DEFAULT_FORMAT = 'YYYY-MM-DD'

export type DefaultDateFormat = string // YYYY-MM-DD
export type CurrentFinYearList = List<DefaultDateFormat> // List['YYYY-MM-DD', 'YYYY-MM-DD']

// try and replace MomentInput with this
type DateFormatterInput = Date | string | DateISOString

export type MonthAsNumber =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'

export const arrayOfMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const arrayOfShortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

function generateDateFormats(): string[] {
  const separators = ['-', '/', '.']
  const dayFormats = ['D', 'DD']
  const monthFormats = ['M', 'MM']
  const monthFormatsWithSpaces = ['MMM', 'MMMM']
  const yearFormats = ['YY', 'YYYY']

  const formats: string[] = []
  for (const y of yearFormats) {
    for (const d of dayFormats) {
      for (const m of monthFormats) {
        for (const sep of separators) {
          formats.push(`${d}${sep}${m}${sep}${y}`)

          if (y === 'YYYY') {
            formats.push(`${y}${sep}${m}${sep}${d}`)
          }
        }
      }

      for (const m of monthFormatsWithSpaces) {
        formats.push(`${d} ${m} ${y}`)

        if (y === 'YYYY') {
          formats.push(`${y} ${m} ${d}`)
        }
      }
    }
  }

  return formats
}

function generateMonthFormats(): string[] {
  const separators = ['-', '/', '.']
  const monthFormats = ['M', 'MM']
  const monthFormatsWithSpaces = ['M', 'MM', 'MMM', 'MMMM']
  const yearFormats = ['YY', 'YYYY']

  const formats: string[] = []
  for (const y of yearFormats) {
    for (const m of monthFormats) {
      for (const sep of separators) {
        formats.push(`${m}${sep}${y}`)
      }
    }

    for (const m of monthFormatsWithSpaces) {
      formats.push(`${m} ${y}`)
    }
  }

  return formats
}

const allowedUserEnteredDateFormats = generateDateFormats()
const allowedUserEnteredMonthFormats = generateMonthFormats()

export const todayDate: Date = startOfDayDateFns(new Date())

export function formatISODate(date: Date): DateISOString {
  return formatISO(date, { representation: 'date' })
}

export function getPreviousMonth(date: Date): Date {
  return sub(date, { months: 1 })
}

export function getNextMonth(date: Date): Date {
  return add(date, { months: 1 })
}

export function getPreviousMonthISODate(date: DateISOString): DateISOString {
  return yyyymmdd(getPreviousMonth(parseISO(date)))
}

export function getNextMonthISODate(dateIso: DateISOString): DateISOString {
  return yyyymmdd(getNextMonth(parseISO(dateIso)))
}

export function endOfPreviousMonth(date: Date): Date {
  return endOfMonth(getPreviousMonth(date))
}

export function addYearsToISODate(
  dateIso: DateISOString,
  years: number,
): DateISOString {
  return yyyymmdd(add(parseISO(dateIso), { years }))
}

export function subtractYearsToISODate(
  dateIso: DateISOString,
  years: number,
): DateISOString {
  return yyyymmdd(sub(parseISO(dateIso), { years }))
}

export function addMonthsToISODate(
  dateIso: DateISOString,
  months: number,
): DateISOString {
  return yyyymmdd(add(parseISO(dateIso), { months }))
}

export function subtractMonthsToISODate(
  dateIso: DateISOString,
  months: number,
): DateISOString {
  return yyyymmdd(sub(parseISO(dateIso), { months }))
}

export function addDaysToISODate(
  dateIso: DateISOString,
  days: number,
): DateISOString {
  return yyyymmdd(add(parseISO(dateIso), { days }))
}

export function subtractDaysToISODate(
  dateIso: DateISOString,
  days: number,
): DateISOString {
  return yyyymmdd(sub(parseISO(dateIso), { days }))
}

export function getNextDay(date: Date): DateISOString {
  return formatISODate(add(date, { days: 1 }))
}

export function getEndOfMonth(date: Date): DateISOString {
  return formatISODate(endOfMonth(date))
}

export function getEndOfMonthIsoDate(date: DateISOString): DateISOString {
  return formatISODate(endOfMonth(parseISO(date)))
}

export function isBeforeLimit(date: Date, limit: Date): boolean {
  return isBefore(date, limit)
}

export function isBeforeOrEqual(date: Date, limit: Date): boolean {
  return isBefore(date, limit) || isSameDay(date, limit)
}

export function isAfterOrEqual(date: Date, limit: Date): boolean {
  return isAfter(date, limit) || isSameDay(date, limit)
}

export const isBetweenLimitsInclusive = (
  date: Date,
  startLimit: Date,
  endLimit: Date,
): boolean => {
  return isAfterOrEqual(date, startLimit) && isBeforeOrEqual(date, endLimit)
}

export function parseUserEnteredDate(
  date: string,
  allowWholeMonth?: boolean,
): Date {
  const trimmedDate = date.trim()

  if (allowWholeMonth) {
    const parsedDate = parseUserEnteredMonth(trimmedDate)
    if (isValid(parsedDate)) return parsedDate
  }

  return moment(trimmedDate, allowedUserEnteredDateFormats, true).toDate()
}

export function parseUserEnteredMonth(date: string): Date {
  const trimmedDate = date.trim()
  return moment(trimmedDate, allowedUserEnteredMonthFormats, true).toDate()
}

export function datePartsToDateInUI(
  day: number,
  month: number,
  year: number,
): FormattedDateInUi | FormattedMonthInUi | string {
  const isMonthDate = day === 0
  const dateObject = new Date(year, month, isMonthDate ? 1 : day)
  if (!isValid(dateObject)) return '' // return empty string for invalid dates

  const formattedMonth = minimumIntegerDigits(dateObject.getMonth() + 1, 2) // parse functions expect 1 based index

  if (isMonthDate) {
    const monthDate = parseUserEnteredMonth(`${formattedMonth}/${year}`)
    return isValid(monthDate) ? formattedMonthInUI(monthDate) : ''
  }

  const formattedDay = minimumIntegerDigits(dateObject.getDate(), 2)
  const date = parseUserEnteredDate(`${formattedDay}/${formattedMonth}/${year}`)
  return isValid(date) ? formattedDateInUI(date) : ''
}

export const isUserEnteredDateValid = (value: string): boolean => {
  const parsedDate = parseUserEnteredDate(value)
  return isValid(parsedDate)
}

export const isUserEnteredMonthValid = (value: string): boolean => {
  const parsedDate = parseUserEnteredMonth(value)
  return isValid(parsedDate)
}

export function newDateInUI(): FormattedDateInUi {
  return formattedDateInUI(new Date(), false) as FormattedDateInUi
}

export function newMonthInUI(): FormattedMonthInUi {
  return formattedDateInUI(new Date(), true) as FormattedMonthInUi
}

export function formattedDateInUI(
  date?: string | Date,
  isWholeMonth?: boolean,
): FormattedDateInUi | FormattedMonthInUi | string {
  if (isWholeMonth) {
    return formattedMonthInUI(date)
  }
  return date ? dmmmyy(date) : ''
}

export function formattedMonthInUI(date?: string | Date): string {
  if (!date) return ''

  if (typeof date === 'string') {
    const parsedDate = parseUserEnteredMonth(date)
    return format(parsedDate, 'MMMM yyyy')
  }

  return format(date, 'MMMM yyyy')
}

export const formattedDateInReport = (
  date,
  day: number | undefined | null | boolean = true,
): string => (day ? formattedDateInUI(date) : (mmmyy(date) as string))

export function getMonth(date): string {
  return moment(date).format('MMMM')
}

export function startOfDay(date: Date): Date {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0,
  )
}

/* Helpers */

export function todayWithTimeStamp(): Date {
  return new Date()
}

// TODO: we may need to obtain today from server
export function today(): Date {
  const now = new Date()
  return startOfDay(now)
}

export const todayISODate = (): ISODate => formatISODate(today())

export function yesterday(): Date {
  return startOfDay(sub(today(), { days: 1 }))
}

export function oneMonthFromToday(): Date {
  return add(today(), { months: 1 })
}

const thisDayCached: LocalDate = LocalDate.today()

export function getCurrentMonth(): number {
  return thisDayCached.month
}
export function getCurrentYear(): number {
  return thisDayCached.year
}
export function getCurrentDay(): number | undefined {
  return thisDayCached.day
}
export function getRevisionMonth(currentMonth: number): number {
  return currentMonth - 1
}

export const getPrevDateISOString = (date: DateISOString): DateISOString =>
  yyyymmdd(extendedMoment(date).subtract(1, 'day'))

export const hhmmDDMMMYYYY = (date: MomentInput): Maybe<string> =>
  date && extendedMoment(date).format('hh:mm DD MMM YYYY')

export const dmy = (date: MomentInput): Maybe<string> =>
  date && extendedMoment(date).format('D/MM/YY')

export const ddmmyy = (date: MomentInput): Maybe<string> =>
  date && extendedMoment(date).format('DD/MM/YY')

export const ddmmyyyy = (date: MomentInput): Maybe<string> =>
  date && extendedMoment(date).format('DD/MM/YYYY')

// used in many places, so harder to type - for now
// export const mmmm = (date: DateFormatterInput): string =>
//   extendedMoment(date).format('MMMM')
export const mmmm = (date: MomentInput) =>
  date && extendedMoment(date).format('MMMM')

export const mmm = (date: DateFormatterInput): string =>
  extendedMoment(date).format('MMM')

export const dd = (date: DateFormatterInput): string =>
  extendedMoment(date).format('DD')

export const yyyy = (date: DateFormatterInput): string =>
  extendedMoment(date).format('YYYY')

export const yy = (date: DateFormatterInput): string =>
  extendedMoment(date).format('YY')

export const dmmm = (date: DateFormatterInput): string =>
  extendedMoment(date).format('D MMM')

export const dmmmmyyyy = (date: MomentInput): Maybe<string> =>
  date && extendedMoment(date).format('D MMMM YYYY')

export const mmmyyyy = (date: DateFormatterInput): string =>
  extendedMoment(date).format('MMM YYYY')

export const mmmDashyyyy = (date: ISODate): string =>
  extendedMoment(date).format('MMM-YYYY')
export const mmmyy = (date: MomentInput): Maybe<string> =>
  date && extendedMoment(date).format('MMM YY')

const dmmmyy = (date: MomentInput): FormattedDateInUi =>
  extendedMoment(date).format('D MMM YY')
export const ddmmm = (date: MomentInput): Maybe<string> =>
  date && extendedMoment(date).format('DD MMM')
export const ddDashmmmDashyy = (date: MomentInput) =>
  date && extendedMoment(date).format('DD-MMM-YY')

export const mmmmyyyy = (date: MomentInput): Maybe<string> =>
  date && extendedMoment(date).format('MMMM YYYY')

export const ddmmmyyyy = (date: DateFormatterInput): string =>
  extendedMoment(date).format('DD MMM YYYY')

export const dmmmyyyy = (date: DateFormatterInput): string =>
  extendedMoment(date).format('D MMM YYYY')

export const ddmmmmyyyy = (date: MomentInput) =>
  date && extendedMoment(date).format('DD MMMM YYYY')

export const yyyymm = (date: MomentInput): YYYYMM =>
  date && extendedMoment(date).format('YYYY-MM')

export const yyyymmdd = (date: MomentInput): DateISOString =>
  date && extendedMoment(date).format('YYYY-MM-DD')

export const ddmmmyyWithSlash = (date: MomentInput) =>
  date && extendedMoment(date).format('DD/MMM/YY')

export const ddthmmmyyyy = (date: MomentInput) =>
  date && extendedMoment(date).format('Do MMM YYYY')

export const dmmmyyyyhhmm = (date: MomentInput) => {
  return date && extendedMoment(date).format('D MMM YYYY HH:mm')
}

export const todayDateISOString: DateISOString = yyyymmdd(today())
export const yesterdayISOString: DateISOString = yyyymmdd(
  sub(today(), { days: 1 }),
)
export const tomorrowISOString: DateISOString = yyyymmdd(
  add(today(), { days: 1 }),
)

export type stringOrUndefined = string | undefined

export interface StringOrUndefinedArray {
  [index: number]: stringOrUndefined | StringOrUndefinedArray
}

export function toDateStr(
  date: any,
): stringOrUndefined | StringOrUndefinedArray {
  if (Array.isArray(date)) {
    return date.map((d) => toDateStr(d))
  } else if (typeof date === 'string') {
    const re = /^(\d\d\d\d-\d\d-\d\d)(.*)$/
    const result = re.exec(date)
    if (result && result.length > 1) {
      return result[1]
    }
  } else if (date instanceof Date) {
    return extendedMoment(date).format('YYYY-MM-DD')
  } else if (extendedMoment.isMoment(date)) {
    return (date as Moment).format('YYYY-MM-DD')
  }
}

export function dateMapToString(date) {
  return moment({
    year: date.get('year'),
    month: date.get('month'),
    date: date.get('day'),
  }).format('YYYY-MM-DD')
}

export const toDate = (date: Date | string): Date => {
  if (typeof date === 'string') {
    return extendedMoment(date).toDate()
  } else {
    return date
  }
}

export function dateOrSomethingToISODateString(
  dateOrSomething: Moment | Date | string,
): string {
  if (dateOrSomething instanceof extendedMoment) {
    return (dateOrSomething as Moment).format('YYYY-MM-DD')
  }
  if (dateOrSomething instanceof Date) {
    return extendedMoment(dateOrSomething).format('YYYY-MM-DD')
  }
  return extendedMoment(dateOrSomething).format('YYYY-MM-DD')
}

export const getUTCFromStringDate = (date: string): Date => {
  const convertedDate = new Date(date)
  return new Date(
    Date.UTC(
      convertedDate.getFullYear(),
      convertedDate.getMonth(),
      convertedDate.getDate(),
      0,
      0,
      0,
    ),
  )
}

export const formatRange = (range, format: string): string =>
  range.start.format(format) + '/' + range.end.format(format)

export const firstDayOfFinancialYear = (
  date: DateISOString,
  financial_year_end_month: number,
): Date => {
  let startDate = startOfMonth(parseISO(date))
  // zero based firstMonth == 1 based end month, except for December -> January
  const firstMonth =
    financial_year_end_month === 12 ? 0 : financial_year_end_month

  while (startDate.getMonth() !== firstMonth) {
    startDate = sub(startDate, { months: 1 })
  }

  return startDate
}

export const startOfFinancialYearFromEndYYYYMM = (endYYYYMM: string): string =>
  yyyymm(
    moment(endYYYYMM + '-01')
      .subtract(1, 'year')
      .add(1, 'month'),
  )

export const endOfFinancialYearFromStartDate = (
  startDate: string | Moment,
): Moment => moment(startDate).clone().add(1, 'year').subtract(1, 'month')

export const priorMonthYYYYMM = (d: YYYYMM): YYYYMM =>
  yyyymm(sub(parseISO(d + '-01'), { months: 1 }))

export const priorYearYYYYMM = (d: YYYYMM): YYYYMM =>
  yyyymm(sub(parseISO(d + '-01'), { years: 1 }))

export const nextMonthYYYYMM = (d: YYYYMM): YYYYMM =>
  yyyymm(add(parseISO(d + '-01'), { months: 1 }))

export const nextYearYYYYMM = (d: YYYYMM): YYYYMM =>
  yyyymm(add(parseISO(d + '-01'), { years: 1 }))

export const getOneWholeYearYYYYMMDD = (d: DateISOString): DateISOString =>
  yyyymmdd(moment(d).add(1, 'year').subtract(1, 'day'))

export const getFYRangeFromPlan = (plan) =>
  `${plan.get('financial_year_start')}/${plan.get('financial_year_end')}`

const monthOnly = (date) => date.substr(0, 7)
export const firstOfMonth = (date) => `${monthOnly(date)}-01`

// Returns all months (January-December) as an array of object with the following properties { value, name }
// Where value is an integer (0-based is used so Jan == 0) and name is the full month name (January)
export interface MonthWithName {
  name: string
  value: number
}

// Return date string in 'DD/MM/YYYY - DD/MM/YYYY' format
export const formatCustomDate = (customDate) => {
  const fromDate = new Date(customDate.split('/')[0])
  const toDate = new Date(customDate.split('/')[1])
  customDate = dmmmyy(fromDate) + ' - ' + dmmmyy(toDate)
  return customDate
}

export const formatCustomMonthRange = (startDate, endDate) =>
  `${mmmyyyy(startDate)} - ${mmmyyyy(endDate)}`

export const getYearMonthsInRange = (
  start?: Moment,
  end?: Moment,
): List<Moment> => {
  if (!start || !end) {
    return List()
  }

  start = start.clone()
  const financialMonths: List<Moment> = List([]).asMutable()
  while (start.format(DEFAULT_FORMAT) <= end.format(DEFAULT_FORMAT)) {
    financialMonths.push(start.clone())
    start.add(1, 'month')
  }
  return financialMonths.asImmutable()
}

export const getISODateYearMonthsInRange = (
  start?: DateISOString,
  end?: DateISOString,
): DateISOString[] => {
  if (!start || !end) {
    return []
  }

  let curr = start
  const months: DateISOString[] = []
  while (curr <= end) {
    months.push(curr)
    curr = addMonthsToISODate(curr, 1)
  }
  return months
}

// FIXME: remove undefined as input, but callers currently can provide undefined
export const countYearsBeforeCurrent = (startYear: Moment | undefined) => {
  return moment().diff(startYear, 'years')
}

export const countYearsBeforeIso = (startYear: DateISOString): number =>
  today().getFullYear() - parseISO(startYear).getFullYear()

export function earlierDate(
  date1: LocalDate | undefined,
  date2: LocalDate | undefined,
): LocalDate | undefined {
  if (!date1) return date2
  if (!date2) return date1

  return date1.isAfter(date2) ? date2 : date1
}

export function dateOneIsBeforeDateTwo(
  date1: string | undefined,
  date2: string | undefined,
): boolean {
  if (!date1 || !date2) return false
  return moment(date1).isBefore(moment(date2)) ? true : false
}

/**
 * returns the latest of two dates
 * @param {moment.Moment} date1
 * @param {moment.Moment} date2
 * @returns {moment.Moment}
 */
export function laterDate(
  date1: LocalDate | undefined,
  date2: LocalDate | undefined,
): LocalDate | undefined {
  if (!date1) return date2
  if (!date2) return date1

  return date1.isAfter(date2) ? date1 : date2
}

export function diffInDaysBetweenDates(date1: LocalDate, date2: LocalDate) {
  const date1Moment = date1.toMoment()
  const date2Moment = date2.toMoment()

  return date1Moment.diff(date2Moment, 'days')
}

export function diffInMonthsBetweenDates(date1: LocalDate, date2: LocalDate) {
  const date1Moment = date1.toMoment()
  const date2Moment = date2.toMoment()

  return date1Moment.diff(date2Moment, 'months')
}

export function diffInYearsBetweenDates(
  date1: LocalDate | undefined,
  date2: LocalDate | undefined,
) {
  if (!date1 || !date2) return 0
  const date1Moment = date1.toMoment()
  const date2Moment = date2.toMoment()

  return date1Moment.diff(date2Moment, 'years')
}

const toPaddedString = (num: number, places: number): string =>
  String(num).padStart(places, '0')

export const getEventDateAsISOString = (item): DateISOString => {
  const eventDate = item.get('event_date')
  if (eventDate) {
    return eventDate
  }
  const year = item.get('event_year')
  const calMonth = toPaddedString(parseInt(item.get('event_month')) + 1, 2)
  const day = toPaddedString(
    !item.get('event_day') || item.get('event_day') === 0
      ? 1
      : item.get('event_day'),
    2,
  )

  return `${year}-${calMonth}-${day}`
}

export const getFinYearForISODate = (
  date: DateISOString,
  fyStart: DateISOString,
  fyEnd: DateISOString,
  format: 'fullISODates' | 'yyyy/yy',
): string | undefined => {
  let finYearStart = fyStart
  let finYearEnd = fyEnd

  while (finYearEnd >= date) {
    if (date >= finYearStart && date <= finYearEnd) {
      if (format === 'yyyy/yy') {
        return `${yyyy(finYearStart)}/${yy(finYearEnd)}`
      } else {
        return `${finYearStart}/${finYearEnd}`
      }
    }
    finYearStart = addYearsToISODate(finYearStart, -1)
    finYearEnd = addYearsToISODate(finYearEnd, -1)
  }
}

export const getFinYearForEventDate = (
  item,
  fyStart: DateISOString,
  fyEnd: DateISOString,
): string | undefined =>
  getFinYearForISODate(getEventDateAsISOString(item), fyStart, fyEnd, 'yyyy/yy')

export const isInvDateAfterTxnDate = (invoiceDate, transactionDate) => {
  if (invoiceDate && transactionDate) {
    return isDateAfter(invoiceDate, transactionDate)
  }
}

export const isDateAfter = (
  date1: DateISOString | Moment,
  date2: DateISOString | Moment,
): boolean => {
  return moment(date1).isAfter(moment(date2))
}

export const subtractSettlementDelayFromDate = (
  date: Date | Moment,
  settlementDelay: number,
): Date => {
  return moment(date).subtract(settlementDelay, 'days').toDate()
}

export const getDefaultRevisionDateMoment = () => {
  return moment().startOf('month')
}

export const getDefaultRevisionYYYYMM = () => {
  return yyyymm(moment().subtract(1, 'month'))
}

export const getDefaultRevisionDate = (): ISODate =>
  formatISODate(startOfMonth(today()))

const regExYYYYMM = (yearMonth) => /^\d{4}-\d{2}$/.test(yearMonth)

const regExYYYYMMDD = (yearMonth) => /^\d{4}-\d{2}-\d{2}$/.test(yearMonth)

function assertYYYYMM(yearMonth) {
  assert(regExYYYYMM(yearMonth))
}

// returns month from an year month in the format of 'YYYY-MM'
export const getMonthFromYYYYMM = (yearMonth: string): string => {
  assertYYYYMM(yearMonth)
  return yearMonth.split('-')[1]
}

// converts an year month in the format of 'YYYY-MM' or 'YYYY-MM-DD' to
// year and month which is zero based and day.
export const getYearAndZeroBasedMonth = (yearMonth: string) => {
  assert(regExYYYYMM(yearMonth) || regExYYYYMMDD(yearMonth))
  const [year, month, day] = yearMonth.split('-')
  return [
    parseInt(year),
    parseInt(month) - 1,
    isUndefinedOrNull(day) ? 1 : parseInt(day),
  ]
}

interface isBetweenRangeOptions {
  start?: string
  end?: string
  date?: string
}
export const isBetweenRangeExclusive = (options: isBetweenRangeOptions) => {
  const { start, end, date } = options
  return start && end && date ? moment(date).isBetween(start, end) : false
}

export const formatRevisionDateForUI = (revisionDate: ISODate): string =>
  mmmyyyy(getPreviousMonthISODate(revisionDate))

export const formatRevisionDateForClient = (
  revisionDate?: string,
): string | undefined => {
  if (!moment(revisionDate).isSame(futureDateForActualsOnlyRevision)) {
    return (
      'End of ' + moment(revisionDate).subtract(1, 'month').format('MMM YYYY')
    )
  }
}

export const formatRevisionDateForServer = (revisionDate) =>
  moment(revisionDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD')

const actualsOnlyRevisionDate = '3000-06-01'
export const futureDateForActualsOnlyRevision = moment(actualsOnlyRevisionDate)

export const revisionDateEqualsFutureDateForActualsOnly = (
  revisionDate: LocalDate,
): boolean => {
  return isSameMonth(
    new Date(revisionDate.year, revisionDate.month, revisionDate.day),
    new Date(actualsOnlyRevisionDate),
  )
}

export const datesAreTheSame = (inv1, inv2, dateKey) => {
  return inv1 && inv2 ? inv1[dateKey].localeCompare(inv2[dateKey]) === 0 : false
}

export const formatDateRange = (startDate, endDate) => {
  const formattedStart = dateOrSomethingToISODateString(startDate)
  const formattedEnd = dateOrSomethingToISODateString(endDate)
  return `${formattedStart}/${formattedEnd}`
}

// export const isoDateSub = (date: ISODate, duration: Duration): ISODate =>
//   formatISODate(sub(parseUserEnteredDate(date), duration))

export const extractYYYYMMFromISODate = (date: ISODate): YYYYMM =>
  date.slice(0, 7)

export const extractYearFromYYYYMM = (yearMonth: YYYYMM): YYYYString => {
  return yearMonth.slice(0, 4) as YYYYString
}

export const updateIsoDate = (
  date: ISODate,
  field: 'month' | 'year',
  value: number,
  makeDay: 'first' | 'last',
): ISODate => {
  let asDate = dateFromIso(date)
  asDate.setDate(1) // set to the 1st, so theres no overflow into next month issues
  asDate = field === 'month' ? setMonth(asDate, value) : setYear(asDate, value)
  if (makeDay === 'last') asDate = endOfMonth(asDate)
  return yyyymmdd(asDate)
}

export const extractYearFromISODate = (date: ISODate): number =>
  parseInt(date.slice(0, 4))

export const extractMonthFromISODate = (date: ISODate): number =>
  parseInt(date.slice(5, 7))

export const dateFromIso = (string: ISODate): Date => {
  return parseISO(string)
}

export const convertISODateToNumber = (date: ISODate): number =>
  parseISO(date).getTime()

export const startOfMonthDate = (date: ISODate): ISODate => {
  if (!date) {
    return date
  }
  return startOfMonth(parseISO(date)).toISOString()
}
