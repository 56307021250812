import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon'
import { FC } from 'react'

export const InvoiceScannerIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      viewBox='4.5455 3.2727 10.9091 9.4545'
      xmlns='http://www.w3.org/2000/svg'
      height={props.height || 24}
      width={props.width || 24}
      data-testid='invoice scanner'
    >
      <g fill='none' fillRule='evenodd'>
        <path
          d='m11.6363636 9.45454545c.3681899 0 .6724761.27360377.7206336.62858615l.0066392.0986866v1.8181818c0 .3681898-.2736038.6724761-.6285862.7206336l-.0986866.0066391h-1.81818178c-.40166164 0-.72727273-.3256111-.72727273-.7272727 0-.3681898.27360376-.6724761.62858613-.7206336l.0986866-.0066391 1.09072728.0005454.0001818-1.0914545c0-.40166165.3256111-.72727275.7272727-.72727275zm-7.27272724 0c.40166164 0 .72727273.3256111.72727273.72727275l.00018182 1.0914545 1.09072727-.0005454c.40166164 0 .72727273.3256111.72727273.7272727s-.32561109.7272727-.72727273.7272727h-1.81818182c-.40166163 0-.72727272-.3256111-.72727272-.7272727v-1.8181818c0-.40166165.32561109-.72727275.72727272-.72727275zm8.36363634-2.18181818c.4016617 0 .7272728.32561109.7272728.72727273s-.3256111.72727273-.7272728.72727273h-9.45454543c-.40166163 0-.72727272-.32561109-.72727272-.72727273s.32561109-.72727273.72727272-.72727273zm-1.0909091-4c.4016617 0 .7272728.32561109.7272728.72727273v1.81818182c0 .40166163-.3256111.72727273-.7272728.72727273-.4016616 0-.7272727-.3256111-.7272727-.72727273l-.0001818-1.09145455-1.09072728.00054546c-.40166164 0-.72727273-.32561109-.72727273-.72727273s.32561109-.72727273.72727273-.72727273zm-7.27272724 0h1.81818182c.40166164 0 .72727273.32561109.72727273.72727273 0 .36818983-.27360376.67247608-.62858613.72063358l-.0986866.00663915-1.09072727-.00054546-.00018182 1.09145455c0 .40166163-.32561109.72727273-.72727273.72727273-.36818983 0-.67247608-.27360377-.72063358-.62858613l-.00663914-.0986866v-1.81818182c0-.36818983.27360376-.67247608.62858612-.72063358l.0986866-.00663915h1.81818182z'
          fill='currentColor'
          transform='translate(2)'
        />
      </g>
    </svg>
  </SvgIcon>
)
