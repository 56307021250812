import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch, // eslint-disable-line no-restricted-imports
  useSelector as useReduxSelector, // eslint-disable-line no-restricted-imports
} from 'react-redux'
// import type { RootState, AppDispatch } from './index'
import { AnyAction } from 'redux'

import { AppDispatch, RootState } from './index'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => useReduxDispatch<AppDispatch>()
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export type AppAction = AnyAction
