import { fromJS, List, Record, Set } from 'immutable'

import { GstMode } from '~/common/ui/utils/GstToggle'
import { isUndefinedOrNull } from '~/common/utils'
import { DateISOString } from '~/common/utils/DateRangeTypes'
import { LocalDate } from '~/common/utils/LocalDate'
import { UserFarmAccessLevel } from '~/services/AbilityAdjudicator'
import {
  createSectionListFromServerData,
  emptySectionsList,
  SectionsList,
  SectionsListFromServer,
} from '~/state/model/codes/SectionsList'
import {
  FarmBankAccountList,
  FarmBankAccountRecord,
  FarmBankAccountServerData,
} from '~/state/model/farms/FarmBankAccount'
import {
  CodeSplitRule,
  CodeSplitRuleFromServer,
  CodeSplitRuleList,
} from '~/state/model/farms/FarmCodeSplitRule'
import {
  CodingRuleFromServer,
  CodingRuleList,
  FarmCodingRule,
} from '~/state/model/farms/FarmCodingRule'
import {
  ContactFromServer,
  FarmContactList,
  FarmContactRecord,
} from '~/state/model/farms/FarmContacts'
import {
  FarmFinancialDetail,
  FarmFinancialDetailServerData,
} from '~/state/model/farms/FarmFinancialDetail'
import { FarmUserRecord, UserFromServer } from '~/state/model/farms/FarmUsers'

import { AUS_GST_TYPES, GST_TYPES, NZL_GST_TYPES } from '../GstTypes'
import { ISODate, UUID } from '../ModelTypes'
import { AddressRecord } from './AddressRecord'
import {
  FarmCountryInfo,
  FarmCountryInfoFromServer,
  SupportedCurrency,
} from './FarmCountryInfo'
import {
  PendingUserFromServer,
  PendingUserList,
  PendingUserRecord,
} from './PendingUser'

type SubscriptionMigrationStatus = 'active' | 'complete' | null

export type CountryInfoId = 'NZL' | 'AUS'
export type FarmCountry = 'New Zealand' | 'Australia'

interface AnalyticsData {
  dairy_companies: string[]
  farm_type: string[]
  active_bank_feeds: string[]
  manual_bank_feeds: string[]
  subscription_created_at: string
  farmlands_connector_count?: number
  farmsource_connector_count?: number
  paysauce_connector_count?: number
  xtracta_connector_count?: number
  management_tag_count?: number
  unsetup_management_tag_count?: number
}

const emptyAnalyticsData: AnalyticsData = {
  dairy_companies: [],
  farm_type: [],
  active_bank_feeds: [],
  manual_bank_feeds: [],
  subscription_created_at: '',
}

export interface FarmRecordFromServerData {
  id: string
  legal_name?: string
  trading_name?: string
  financial_year_end_month?: number
  template_country_code?: string
  template_type?: string
  is_demo_template?: boolean
  wizard_steps_completed?: string[]
  entity_type?: string
  area_ha?: number
  default_bank_account_id?: string | null
  subscription_id?: string
  demo_started_at?: string
  demo_expires_at?: string
  subscription_use_type?: string
  completed_coding_lines_count_for_limited_farm?: number | null
  tax_invoice_logo_path?: string
  tax_invoice_note?: string
  tax_invoice_default_gst_mode?: GstMode

  coding_rules?: CodingRuleFromServer[]

  code_split_rules?: CodeSplitRuleFromServer[]

  sections?: SectionsListFromServer
  bank_accounts?: FarmBankAccountServerData[]
  haveBankAccountsLoaded?: boolean
  financial_detail?: FarmFinancialDetailServerData
  contact_person?: ContactFromServer
  users?: UserFromServer[]
  pending_users?: PendingUserFromServer[]
  contacts?: ContactFromServer[]
  available_roles?: string[]
  current_financial_year?: [DateISOString, DateISOString]
  subscription_status?: string
  subscription_type?: string
  migration_status?: SubscriptionMigrationStatus
  migration_id?: string
  management_tag_allocation?: number
  merge_lines_in_payment_batch?: boolean

  codes?: any
  kpi_type: KpiType
  analytics_data?: AnalyticsData

  country_info: FarmCountryInfoFromServer
  former_bank_link_farm?: boolean
  phone?: string
  email?: string
  business_number?: string

  access_level: UserFarmAccessLevel
  assigned_via_workspace?: {
    id: UUID
    name: string
    logo_url: string
  }
  limited_farm?: boolean
}

export type KpiType = 'dairy' | 'livestock' | 'mixed' | 'none'
export type KpiOrderColumn =
  | 'dairy_kpi_order'
  | 'livestock_kpi_order'
  | 'mixed_kpi_order'
  | 'none_kpi_order'

interface FarmRecordFields {
  id: string
  legal_name: string
  trading_name: string
  financial_year_end_month: number
  template_country_code: string
  template_type: string
  is_demo_template: boolean
  subscription_use_type: string
  wizard_steps_completed: List<string>
  entity_type: string
  area_ha?: number | null

  default_bank_account_id?: string | null
  subscription_id?: string
  demo_started_at?: string
  demo_expires_at?: string
  completed_coding_lines_count_for_limited_farm?: number | null
  tax_invoice_logo_path: string
  tax_invoice_note: string
  tax_invoice_default_gst_mode: GstMode

  sections?: SectionsList
  bank_accounts: FarmBankAccountList
  haveBankAccountsLoaded: boolean
  financial_detail: FarmFinancialDetail
  contact_person?: FarmContactRecord
  users: List<FarmUserRecord>
  pending_users: PendingUserList
  contacts?: List<FarmContactRecord> | null
  available_roles: List<string>
  current_financial_year: List<string>
  subscription_status: string
  subscription_type?: string
  migration_status?: SubscriptionMigrationStatus
  migration_id?: string
  merge_lines_in_payment_batch?: boolean
  coding_rules?: CodingRuleList
  code_split_rules?: CodeSplitRuleList
  management_tag_allocation?: number
  codes?: any
  kpi_type: KpiType
  hasSupplementaryDataLoaded: boolean
  analytics_data: AnalyticsData
  country_info: FarmCountryInfo
  former_bank_link_farm: boolean
  phone: string
  email: string
  business_number: string
  access_level: UserFarmAccessLevel
  assigned_via_workspace?: {
    id: UUID
    name: string
    logo_url: string
  }
  limited_farm?: boolean
}

export const farmDefaults: FarmRecordFields = {
  id: '',
  legal_name: '',
  trading_name: '',
  financial_year_end_month: 0,
  template_country_code: '',
  template_type: '',
  is_demo_template: false,
  subscription_use_type: 'PRODUCTION',
  wizard_steps_completed: List(),
  entity_type: '',
  area_ha: undefined,
  default_bank_account_id: undefined,
  subscription_id: '',
  demo_started_at: undefined,
  demo_expires_at: undefined,
  completed_coding_lines_count_for_limited_farm: undefined,
  tax_invoice_logo_path: '',
  tax_invoice_note: '',
  tax_invoice_default_gst_mode: GstMode.excl,

  sections: undefined,
  bank_accounts: List(),
  haveBankAccountsLoaded: false,
  financial_detail: new FarmFinancialDetail(),
  contact_person: new FarmContactRecord(),
  users: List(),
  pending_users: List(),
  contacts: undefined,
  available_roles: List(),
  current_financial_year: List(['', '']),
  subscription_status: '',
  subscription_type: undefined,
  migration_status: undefined,
  migration_id: undefined,
  coding_rules: undefined,
  code_split_rules: undefined,
  merge_lines_in_payment_batch: true,
  codes: undefined,
  management_tag_allocation: 0,
  kpi_type: 'none',
  hasSupplementaryDataLoaded: false,
  analytics_data: {
    dairy_companies: [],
    farm_type: [],
    active_bank_feeds: [],
    manual_bank_feeds: [],
    subscription_created_at: '',
  },
  country_info: new FarmCountryInfo(),
  former_bank_link_farm: false,
  phone: '',
  email: '',
  business_number: '',
  access_level: 'demo_viewer',
  assigned_via_workspace: undefined,
  limited_farm: false,
}

function safeContactList(
  contacts: ContactFromServer[] | undefined,
): FarmContactList {
  return FarmContactRecord.fromServerArray(contacts)
}

function safeUserList(
  users: UserFromServer[] | undefined,
  existingValue?: List<FarmUserRecord>,
): List<FarmUserRecord> {
  if (!users && existingValue) {
    return existingValue
  }

  return FarmUserRecord.fromServerArray(users || [])
}

function safePendingUserList(
  users: PendingUserFromServer[] | undefined,
  existingValue?: PendingUserList,
): PendingUserList {
  if (!users && existingValue) {
    return existingValue
  }

  return PendingUserRecord.fromServerArray(users || [])
}

function getContactPerson(
  data: FarmRecordFromServerData,
): FarmContactRecord | undefined {
  return (
    data.contact_person && FarmContactRecord.fromServerJS(data.contact_person)
  )
}

function getCountryInfo(data: FarmRecordFromServerData): FarmCountryInfo {
  return data.country_info
    ? FarmCountryInfo.fromServerJS(data.country_info)
    : FarmCountryInfo.defaultFromCountry(data.template_country_code)
}

function getSections(data: FarmRecordFromServerData): SectionsList | undefined {
  return data.sections && createSectionListFromServerData(data.sections)
}

function getFinancialDetail(
  data: FarmRecordFromServerData,
  countryInfo: FarmCountryInfo,
): FarmFinancialDetail {
  return data.financial_detail
    ? FarmFinancialDetail.fromServerJS(data.financial_detail)
    : FarmFinancialDetail.nullRecord(countryInfo)
}

function getCurrentFinancialYear(data: FarmRecordFromServerData) {
  return List(data.current_financial_year || [])
}

function getFarmBankAccounts(data: FarmRecordFromServerData) {
  return FarmBankAccountRecord.fromServerArray(data.bank_accounts || [])
}

// the main farm api (now) does not include bank_accounts, sometimes
// we do have bank accounts & they are loaded during this load
function getFarmBankAccountsHaveLoaded(data: FarmRecordFromServerData) {
  return !!data.bank_accounts
}

function getCodesFromData(data: FarmRecordFromServerData) {
  return (data.codes && fromJS(data.codes)) || List()
}

export const WIZARD_STEPS = List(
  [
    'farm-business',
    'users',
    'financial',
    'bank-accounts',
    'tags',
    'connectors',
  ].filter(Boolean),
)
export const WIZARD_STEPS_REQUIRED = Set([
  'farm-business',
  'users',
  'financial',
  'bank-accounts',
  'connectors',
])

export class FarmRecord extends Record(farmDefaults) {
  static fromServerJS(data: FarmRecordFromServerData): FarmRecord {
    const countryInfo = getCountryInfo(data)
    const fields: FarmRecordFields = {
      codes: getCodesFromData(data),
      id: data.id,
      legal_name: data.legal_name || '',
      trading_name: data.trading_name || '',
      financial_year_end_month: data.financial_year_end_month || 0,
      template_country_code: data.template_country_code || '',
      template_type: data.template_type || '',
      is_demo_template: data.is_demo_template || false,
      subscription_use_type: data.subscription_use_type || 'PRODUCTION',
      wizard_steps_completed: List(data.wizard_steps_completed || []),
      entity_type: data.entity_type || '',
      area_ha: data.area_ha || 0,
      default_bank_account_id: data.default_bank_account_id,
      subscription_id: data.subscription_id,
      demo_started_at: data.demo_started_at,
      demo_expires_at: data.demo_expires_at,
      completed_coding_lines_count_for_limited_farm:
        data.completed_coding_lines_count_for_limited_farm,
      tax_invoice_logo_path: data.tax_invoice_logo_path || '',
      tax_invoice_note: data.tax_invoice_note || '',
      tax_invoice_default_gst_mode:
        data.tax_invoice_default_gst_mode || GstMode.excl,
      sections: getSections(data),
      bank_accounts: getFarmBankAccounts(data),
      haveBankAccountsLoaded: getFarmBankAccountsHaveLoaded(data),
      // Be aware financial_detail is loaded as part of supplementary_data API call not farms.
      financial_detail: getFinancialDetail(data, countryInfo),
      contact_person: getContactPerson(data),
      contacts: isUndefinedOrNull(data.contacts)
        ? undefined
        : safeContactList(data.contacts),
      available_roles: List(data.available_roles || []),
      current_financial_year: getCurrentFinancialYear(data),
      subscription_status: data.subscription_status || '',
      subscription_type: data.subscription_type || '',
      migration_status: data.migration_status,
      migration_id: data.migration_id,
      // coding_rules * code_split_rules are loaded as part of supplementary_data API call not farms. - however there are jest specs that load these via fromServerJS
      coding_rules:
        data.coding_rules && FarmCodingRule.fromServerArray(data.coding_rules),
      code_split_rules: CodeSplitRule.fromServerArray(
        data.code_split_rules || [],
      ),
      management_tag_allocation: isUndefinedOrNull(
        data.management_tag_allocation,
      )
        ? undefined
        : data.management_tag_allocation,
      kpi_type: data.kpi_type,
      hasSupplementaryDataLoaded: !!data.analytics_data?.dairy_companies,
      analytics_data: data.analytics_data || emptyAnalyticsData, // now loaded via SupplementaryData api
      users: safeUserList(data.users), // now loaded via SupplementaryData api
      pending_users: safePendingUserList(data.pending_users), // now loaded via SupplementaryData api
      merge_lines_in_payment_batch: data.merge_lines_in_payment_batch,
      country_info: countryInfo,
      former_bank_link_farm: data.former_bank_link_farm || false,
      phone: data.phone || '',
      email: data.email || '',
      business_number: data.business_number || '',
      access_level: data.access_level,
      assigned_via_workspace: data.assigned_via_workspace,
      limited_farm: data.limited_farm,
    }
    return new FarmRecord(fields)
  }

  static placeholder(countryInfoId?: CountryInfoId): FarmRecord {
    const farmValues =
      countryInfoId === 'AUS'
        ? {
            ...farmDefaults,
            country_info: FarmCountryInfo.fromServerJS({
              id: 'AUS',
              default_gst_rate: 10,
              currency: 'AUD',
            }),
          }
        : farmDefaults
    return new FarmRecord(farmValues)
  }

  get countryCode(): CountryInfoId {
    return this.country_info.id
  }

  get currency(): SupportedCurrency {
    return this.country_info.currency
  }

  get countryName(): FarmCountry {
    return this.country_info.id === 'NZL' ? 'New Zealand' : 'Australia'
  }

  get gstTypes(): GST_TYPES {
    return this.countryCode === 'NZL' ? NZL_GST_TYPES : AUS_GST_TYPES
  }

  isNzlFarm(): boolean {
    return this.country_info.id === 'NZL'
  }

  isAusFarm(): boolean {
    return this.country_info.id === 'AUS'
  }

  isFormerBankLinkFarm(): boolean {
    return this.former_bank_link_farm
  }

  mergeInNewServerData(data: FarmRecordFromServerData): FarmRecord {
    // only override some of the fields we get from the server,
    // and retaining other loaded data
    // if we start to reload more, we can make this smarter
    const countryInfo = data.country_info
      ? getCountryInfo(data)
      : this.country_info

    const financialDetail = data.financial_detail
      ? getFinancialDetail(data, countryInfo)
      : this.financial_detail

    const valueFromDataOrFarm = (property, defaultVal: null | string = null) =>
      data.hasOwnProperty(property)
        ? data[property] || defaultVal
        : this.get(property)

    const contactPerson = data.contact_person
      ? getContactPerson(data)
      : this.contact_person

    const taxInvoiceLogoPath = valueFromDataOrFarm('tax_invoice_logo_path', '')
    const taxInvoiceNote = valueFromDataOrFarm('tax_invoice_note', '')

    const newRec = this.set('codes', getCodesFromData(data))
      .set('legal_name', data.legal_name || this.get('legal_name'))
      .set('trading_name', data.trading_name || this.get('trading_name'))
      .set(
        'financial_year_end_month',
        data.financial_year_end_month || this.get('financial_year_end_month'),
      )
      .set(
        'current_financial_year',
        (data.current_financial_year && List(data.current_financial_year)) ||
          this.get('current_financial_year'),
      )
      .set('entity_type', data.entity_type || this.get('entity_type'))
      .set('area_ha', data.area_ha || this.get('area_ha'))
      .set('template_type', data.template_type || this.get('template_type'))
      .set(
        'default_bank_account_id',
        data.default_bank_account_id || this.get('default_bank_account_id'),
      )
      .set(
        'subscription_id',
        data.subscription_id || this.get('subscription_id'),
      )
      .set(
        'subscription_use_type',
        data.subscription_use_type || this.get('subscription_use_type'),
      )
      .set(
        'former_bank_link_farm',
        data.former_bank_link_farm || this.get('former_bank_link_farm'),
      )
      .set('tax_invoice_logo_path', taxInvoiceLogoPath)
      .set('tax_invoice_note', taxInvoiceNote)
      .set(
        'tax_invoice_default_gst_mode',
        valueFromDataOrFarm('tax_invoice_default_gst_mode', ''),
      )
      .set('financial_detail', financialDetail)
      .set(
        'contacts',
        isUndefinedOrNull(data.contacts)
          ? this.get('contacts')
          : safeContactList(data.contacts),
      )
      .set('contact_person', contactPerson)
      .set(
        'analytics_data',
        data.analytics_data || this.get('analytics_data') || emptyAnalyticsData,
      )
      .set('users', safeUserList(data.users, this.get('users')))
      .set(
        'pending_users',
        safePendingUserList(data.pending_users, this.get('pending_users')),
      )
      .set('hasSupplementaryDataLoaded', true)
      .set(
        'merge_lines_in_payment_batch',
        valueFromDataOrFarm('merge_lines_in_payment_batch'),
      )
      .set('phone', valueFromDataOrFarm('phone'))
      .set('email', valueFromDataOrFarm('email'))
      .set('business_number', valueFromDataOrFarm('business_number'))
      .set(
        'completed_coding_lines_count_for_limited_farm',
        valueFromDataOrFarm('completed_coding_lines_count_for_limited_farm'),
      )
      .set(
        'available_roles',
        data.available_roles
          ? List(data.available_roles || [])
          : this.available_roles,
      )
      .set(
        'current_financial_year',
        data.current_financial_year
          ? getCurrentFinancialYear(data)
          : this.current_financial_year,
      )
      .set('subscription_status', valueFromDataOrFarm('subscription_status'))
      .set('subscription_type', valueFromDataOrFarm('subscription_type'))
      .set(
        'management_tag_allocation',
        data.management_tag_allocation
          ? isUndefinedOrNull(data.management_tag_allocation)
            ? undefined
            : data.management_tag_allocation
          : this.management_tag_allocation,
      )
      .set('migration_status', valueFromDataOrFarm('migration_status'))
      .set('migration_id', valueFromDataOrFarm('migration_id'))
      .set('kpi_type', valueFromDataOrFarm('kpi_type'))
      .set('country_info', countryInfo)
      .set(
        'assigned_via_workspace',
        valueFromDataOrFarm('assigned_via_workspace'),
      )

    // more items? - think about the context if we force more realtime farm updates
    return newRec
  }

  haveSectionsLoaded(): boolean {
    return !!this.sections
  }

  haveContactsLoaded(): boolean {
    return !!this.contacts
  }

  haveCodingRulesLoaded(): boolean {
    return !!this.coding_rules
  }

  haveCodeSplitRulesLoaded(): boolean {
    return !!this.code_split_rules
  }

  isWizardComplete(): boolean {
    const wizardStepsCompleted = this.wizard_steps_completed || List()
    if (this.isLimitedSubscription()) {
      return WIZARD_STEPS_REQUIRED.filter(
        (step) => step !== 'connectors',
      ).every((stepRequired) => wizardStepsCompleted.includes(stepRequired))
    } else {
      return WIZARD_STEPS_REQUIRED.every((stepRequired) =>
        wizardStepsCompleted.includes(stepRequired),
      )
    }
  }

  isLimitedSubscription(): boolean {
    return this.limited_farm ?? false
  }

  isDirectAccessFarm(): boolean {
    return !this.assigned_via_workspace
  }

  getSections(): SectionsList {
    return this.sections || emptySectionsList()
  }

  getValidCodes(): string[] {
    return (this.sections || List())
      .map((section) =>
        section.categories.map((category) =>
          category.extcodes.map(
            (extcode) =>
              `${category.code}:${
                extcode.code === '__GEN' ? '' : extcode.code
              }`,
          ),
        ),
      )
      .flatten(true)
      .flatten(true)
      .toJS() as string[]
  }

  isUserDemoFarm(): boolean {
    return !!this.demo_started_at && this.subscription_use_type === 'DEMO'
  }

  isUserTrainingFarm(): boolean {
    return !!this.demo_started_at && this.subscription_use_type === 'TRAINING'
  }

  isPlaygroundFarm(): boolean {
    return this.isUserDemoFarm() || this.isUserTrainingFarm()
  }

  isFarmFromRural(): boolean {
    return !!this.migration_id
  }

  hasMilking(): boolean {
    return this.analytics_data.farm_type.includes('Milking')
  }

  hasLivestock(): boolean {
    return this.analytics_data.farm_type.includes('Livestock')
  }

  hasCropping(): boolean {
    return this.analytics_data.farm_type.includes('Cropping')
  }

  hasHorticulture(): boolean {
    return this.analytics_data.farm_type.includes('Horticulture')
  }

  hasViticulture(): boolean {
    return this.analytics_data.farm_type.includes('Viticulture')
  }

  currentFinancialYearRange(): [ISODate, ISODate] {
    return [
      this.current_financial_year.get<ISODate>(0, ''),
      this.current_financial_year.get<ISODate>(1, ''),
    ]
  }

  currentFinancialYearStart(): LocalDate {
    return LocalDate.constructWithString(this.currentFinancialYearRange()[0])
  }

  preferredPostalAddress(): AddressRecord {
    return this.contact_person?.postal_address?.address_type
      ? this.contact_person?.postal_address
      : this.contact_person?.physical_address || AddressRecord.nullRecord()
  }

  defaultBankAccountId(): string {
    const firstBankAccount =
      this.bank_accounts.first<FarmBankAccountRecord>() ||
      FarmBankAccountRecord.nullRecord()
    return this.default_bank_account_id || firstBankAccount.id
  }

  defaultBankAccount(): FarmBankAccountRecord {
    return (
      this.bank_accounts.find((ba) => ba.id === this.defaultBankAccountId()) ||
      FarmBankAccountRecord.nullRecord()
    )
  }

  isPaymentGstBasis(): boolean {
    return this.financial_detail.isPaymentGstBasis()
  }

  isInvoiceGstBasis(): boolean {
    return this.financial_detail.isInvoiceGstBasis()
  }

  getLinkToLogoFile(): string | undefined {
    if (this.tax_invoice_logo_path) {
      return `/api/v1/farms/${
        this.id
      }/invoice_logo_raw?cache-buster=${new Date().getMilliseconds()}`
    }
  }
}
