import MuiCheckbox from '@mui/material/Checkbox'
import { createSvgIcon } from '@mui/material/utils'

interface Props extends React.ComponentProps<typeof MuiCheckbox> {
  defaultChecked?: boolean
  checked?: boolean
  disabled?: boolean
  className?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  'data-e2e-ci'?: string
  /**
   * @default 'default'
   */
  variant?: 'default' | 'filled'
}

/**
 * A wrapper around the [MUI Checkbox](https://mui.com/material-ui/react-checkbox/) component.
 *
 * Invision Documentation: [Checkbox](https://projects.invisionapp.com/d/main/default/#/console/22221912/471907110/preview)
 */
export const Checkbox: React.FC<Props> = (props) => {
  const { color = 'default', variant = 'default', ...rest } = props

  return (
    <MuiCheckbox
      color={color}
      icon={variant === 'filled' ? <UncheckedFilledIcon /> : <UncheckedIcon />}
      {...rest}
    />
  )
}

const UncheckedIcon = createSvgIcon(
  <path d='M19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L19,3 Z M19,4 L5,4 C4.44771525,4 4,4.44771525 4,5 L4,19 C4,19.5522847 4.44771525,20 5,20 L19,20 C19.5522847,20 20,19.5522847 20,19 L20,5 C20,4.44771525 19.5522847,4 19,4 Z' />,
  'UncheckedIcon',
)

const UncheckedFilledIcon = createSvgIcon(
  <>
    <rect
      width='18'
      height='18'
      fill='currentColor'
      opacity='.3'
      rx='3'
      x='3'
      y='3'
    />
    <path
      d='M19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 L19,3 Z M19,4 L5,4 C4.44771525,4 4,4.44771525 4,5 L4,19 C4,19.5522847 4.44771525,20 5,20 L19,20 C19.5522847,20 20,19.5522847 20,19 L20,5 C20,4.44771525 19.5522847,4 19,4 Z'
      fill='currentColor'
    />
  </>,
  'UncheckedFilledIcon',
)
