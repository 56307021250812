import { useField } from 'formik'
import { FC } from 'react'

import { TextField } from '../textfields/TextField'

export interface FormikTextFieldProps
  extends Omit<React.ComponentProps<typeof TextField>, 'required'> {
  name: string
  /** This indicates whether the field label should indicate the field as optional */
  optional?: boolean
  /** If helper text isn't supplied then still hold the space for it (default is true) */
  reserveHelperTextSpace?: boolean
  /** If true then show default message if required field isn't entered, if string then show that instead */
  required?: boolean | string
  /** Use the standard MUI shrinking label */
  useMuiLabel?: boolean
}

const FormikTextField: FC<FormikTextFieldProps> = (props) => {
  const {
    label,
    name,
    optional = false,
    required,
    helperText: propsHelperText,
    reserveHelperTextSpace = true,
    useMuiLabel = false,
    ...rest
  } = props

  const helperText = reserveHelperTextSpace
    ? propsHelperText ?? ' '
    : propsHelperText

  function isRequired(value: string) {
    if (!value) {
      if (typeof required === 'string') {
        return required
      }
      return `Enter ${label}`
    }
    return undefined
  }

  const [field, meta] = useField({
    name,
    validate: props.required ? isRequired : undefined,
  })

  return (
    <TextField
      label={label}
      optional={optional}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      helperText={meta.touched ? meta.error ?? helperText : helperText}
      useMuiLabel={useMuiLabel}
      error={meta.touched && !!meta.error}
      sx={{ display: 'block' }}
      {...rest}
    />
  )
}

export default FormikTextField
