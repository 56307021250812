import { List, Record } from 'immutable'

import { USER_ROLE_REPORTING_ACCESS } from '~/services/AbilityAdjudicator'

export interface UserFromServer {
  id: number
  first_name: string
  last_name: string
  email: string
  role: string
  workspace?: {
    id: number
    workspace_type: 'Accountancy' | 'Bank' | 'Consultancy'
    name: string
    physical_address_id: number
  } | null
}
interface FarmUserRecordFields extends UserFromServer {}

export const farmUserDefaults: FarmUserRecordFields = {
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  workspace: null,
}

export type FarmUserList = List<FarmUserRecord>

export class FarmUserRecord extends Record(farmUserDefaults) {
  constructor(data: FarmUserRecordFields) {
    super(data)
  }
  static fromServerJS(data: UserFromServer): FarmUserRecord {
    return new FarmUserRecord(data)
  }
  static fromServerArray(data: UserFromServer[]): List<FarmUserRecord> {
    return List(data.map(FarmUserRecord.fromServerJS))
  }

  get displayName(): string {
    return `${this.first_name} ${this.last_name}`
  }
  isReadOnlyUser(): boolean {
    return this.role === USER_ROLE_REPORTING_ACCESS
  }
}
