import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon'
import { FC } from 'react'

export const CreateCodingRuleIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg
      fill='currentColor'
      height='32'
      width='32'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16,12 C18.76,12 21,14.24 21,17 C21,19.76 18.76,22 16,22 C13.24,22 11,19.76 11,17 C11,14.24 13.24,12 16,12 Z M17,14 L15,14 L15,16 L13,16 L13,18 L15,18 L15,20 L17,20 L17,18 L19,18 L19,16 L17,16 L17,14 Z M3.40319149,-1.42108547e-13 L3.40319149,1.77226552 L1.70019149,1.77241039 L1.70019149,12.2277345 L3.40319149,12.2277345 L3.40319149,14 L0.85,14 C0.416668889,14 0.0590733201,13.6619535 0.00662270672,13.2250218 L-5.35571587e-13,13.1138672 L-6.61692923e-13,0.886132762 C-6.66577904e-13,0.434381121 0.324262406,0.0615844757 0.743377806,0.00690423223 L0.85,-1.15463195e-13 L3.40319149,-1.42108547e-13 Z M16.0531915,-1.15463195e-13 L16.1598137,0.00657429831 C16.5789291,0.0586415261 16.9031915,0.413623264 16.9031915,0.843786959 L16.9031915,0.843786959 L16.9031915,10 L15.2031915,10 L15.2031064,1.68820211 L13.5,1.68757392 L13.5,-1.42108547e-13 L16.0531915,-1.15463195e-13 Z M13.212766,8.23435053 L13.212766,9.93647819 L3,9.93647819 L3,8.23435053 L13.212766,8.23435053 Z M9.38297872,5.61717526 L9.38297872,7.31930292 L3,7.31930292 L3,5.61717526 L9.38297872,5.61717526 Z M12,3 L12,4.70212766 L3,4.70212766 L3,3 L12,3 Z'
        id='Combined-Shape'
      ></path>
    </svg>
  </SvgIcon>
)
