import { List, Record } from 'immutable'

export interface PendingUserFromServer {
  email: string
  role: string
  sent_at: string
}

interface PendingUserRecordFields extends PendingUserFromServer {}

export const farmDefaults: PendingUserRecordFields = {
  email: '',
  role: '',
  sent_at: '',
}

export type PendingUserList = List<PendingUserRecord>

export class PendingUserRecord extends Record(farmDefaults) {
  constructor(data: PendingUserRecordFields) {
    super(data)
  }
  static fromServerJS(data: PendingUserFromServer): PendingUserRecord {
    return new PendingUserRecord(data)
  }
  static fromServerArray(data: PendingUserFromServer[]): PendingUserList {
    return List(data.map(PendingUserRecord.fromServerJS))
  }
  get displayName(): string {
    return this.email
  }
}
