import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

export const useQuery = () => {
  const [searchParams] = useSearchParams()
  return Object.fromEntries([...searchParams])
}

export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = { ...useParams() }
    const query = useQuery()

    return (
      <Component
        {...props}
        router={{ location, navigate, params, query }}
        location={location}
        navigate={navigate}
        params={params}
        query={query}
      />
    )
  }

  return ComponentWithRouterProp
}
