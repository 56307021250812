import { List, Map } from 'immutable'
import { useParams } from 'react-router-dom'
import { createSelector } from 'reselect'

import { assert } from '~/common/utils'
import { RootState } from '~/state/'
import { useSelector } from '~/state/hooks'
import {
  emptySectionsList,
  SectionsList,
} from '~/state/model/codes/SectionsList'
import { FarmBankAccountList } from '~/state/model/farms/FarmBankAccount'
import { FarmFinancialDetail } from '~/state/model/farms/FarmFinancialDetail'
import { FarmRecord, WIZARD_STEPS } from '~/state/model/farms/FarmRecord'
import { GST_TYPES, NZL_GST_TYPES } from '~/state/model/GstTypes'
import { UUID } from '~/state/model/ModelTypes'

export const NO_CURRENT_FARM_ERROR = 'No current farm found'

export type FarmRecordMap = Map<string, FarmRecord>
export function farmsAsMap(farms: FarmRecord[]): FarmRecordMap {
  let map = Map<string, FarmRecord>()
  farms.forEach((farm) => {
    map = map.set(farm.id, farm)
  })
  return map
}

export function getRootState(state: RootState): RootState {
  return state
}

function getAllFarmsRaw(state: RootState): FarmRecordMap {
  return state.getIn(['model', 'farms']) || Map()
}

// export function getCurrentFarmId(state): string | undefined {
export function getCurrentFarmId(state: RootState): string {
  return state.getIn(['ui', 'farms', 'currentFarmId'])
}

export function currentFarmId(state: RootState): string {
  const id = getCurrentFarmId(state)
  const errorMessage = 'No current farm ID found'
  assert(id, errorMessage)
  return id
}

export function getFarmById(
  state: RootState,
  farmId: string | undefined,
): FarmRecord | undefined {
  if (!farmId) {
    return undefined
  }

  return getAllFarmsRaw(state).get(farmId)
}

export function getCurrentFarm(state: RootState): FarmRecord | undefined {
  return getFarmById(state, getCurrentFarmId(state))
}

export function currentFarm(state: RootState): FarmRecord {
  const farm = getCurrentFarm(state)
  assert(farm, NO_CURRENT_FARM_ERROR)
  return farm
}

export const useCurrentFarm = () => useSelector(currentFarm)

export const useCurrentFarmId = () => useSelector(currentFarmId)
export const useCurrentFarmIdViaParams = () => useParams().farmId

export function currentFarmById(
  state: RootState,
  farmId: string | undefined,
): FarmRecord {
  const farm = getFarmById(state, farmId)
  assert(farm, NO_CURRENT_FARM_ERROR)
  return farm
}

export function getSectionsFromFarm(
  farm: FarmRecord | undefined,
): SectionsList {
  if (!farm || !farm.sections) {
    return emptySectionsList()
  }

  return farm.sections
}

export function sectionsHaveLoaded(state: RootState): boolean {
  const farm = getCurrentFarm(state)
  if (!farm) {
    return false
  }

  return farm.haveSectionsLoaded()
}

export function hasSupplementaryDataLoaded(state: RootState): boolean {
  const farm = getCurrentFarm(state)
  if (!farm) {
    return false
  }

  return farm.hasSupplementaryDataLoaded
}

export function contactsHaveLoaded(state: RootState): boolean {
  const farm = getCurrentFarm(state)
  if (!farm) {
    return false
  }

  return farm.haveContactsLoaded()
}

export function codingRulesHaveLoaded(state: RootState): boolean {
  const farm = getCurrentFarm(state)
  if (!farm) {
    return false
  }

  return farm.haveCodingRulesLoaded()
}

export function codeSplitRulesHaveLoaded(state: RootState): boolean {
  const farm = getCurrentFarm(state)
  if (!farm) {
    return false
  }

  return farm.haveCodeSplitRulesLoaded()
}

function getDefaultBankAccountIdFromFarm(
  farm: FarmRecord | undefined,
): string | undefined | null {
  if (!farm) {
    return undefined
  }
  return (
    farm.default_bank_account_id || getBankAccountsFromFarm(farm).get(0)?.id
  )
}

export const getDefaultBankAccount = createSelector(
  [getCurrentFarm],
  getDefaultBankAccountIdFromFarm,
)

export const getDefaultBankAccountId = createSelector(
  currentFarm,
  (farm: FarmRecord): UUID => {
    const bankAccountId = getDefaultBankAccountIdFromFarm(farm)
    assert(bankAccountId, 'No current Bank Account Id')
    return bankAccountId
  },
)

export const getGSTTypes = createSelector(
  [getCurrentFarm],
  (farm: ReturnType<typeof getCurrentFarm>): GST_TYPES => {
    return farm ? farm.gstTypes : NZL_GST_TYPES
  },
)

function getAllBankAccountsFromFarm(
  farm: FarmRecord | undefined,
): FarmBankAccountList {
  if (!farm?.bank_accounts) {
    return List()
  }
  return (
    farm &&
    farm.bank_accounts.sortBy((acc) => {
      const account = acc.bank_name + acc.account_name
      if (acc.id === farm.default_bank_account_id) {
        return '' // empty string, so default sorts at the top
      }
      return account.toString().toLowerCase()
    })
  )
}

function getBankAccountsFromFarm(farm: FarmRecord): FarmBankAccountList {
  return getAllBankAccountsFromFarm(farm).filter((ba) => ba.isEverOperational())
}

export const getAllFarmBankAccounts = createSelector(
  [currentFarm],
  getAllBankAccountsFromFarm,
)

export const getCurrentFarmBankAccounts = createSelector(
  [currentFarm],
  getBankAccountsFromFarm,
)
export function getActiveBankAccountsFromFarm(
  farm: FarmRecord,
): FarmBankAccountList {
  return farm.bank_accounts.filter((bank) => bank.active)
}

export const getCurrentFarmActiveBankAccounts = createSelector(
  [getCurrentFarm],
  getActiveBankAccountsFromFarm,
)

export const getAllFarms = createSelector(getAllFarmsRaw, (farms) =>
  farms.filter((farm) => farm.id),
)

export const getWorkspaceLogoUrlFromFarmAccess = createSelector(
  getCurrentFarm,
  (farm) => farm?.assigned_via_workspace?.logo_url,
)

export const getOwnedFarms = createSelector(getAllFarms, (farms) =>
  farms.filter((farm) => farm.access_level === 'subscription_admin'),
)

export const getDirectAccessFarms = createSelector(getAllFarms, (farms) =>
  farms.filter((farm) => farm.isDirectAccessFarm()),
)

const getDemoFarms = createSelector(getAllFarms, (farms) =>
  farms.filter((farm) => isUserDemoFarm(farm)),
)

export function isUserDemoFarm(farm: FarmRecord | undefined): boolean {
  if (!farm) {
    return false
  }
  return farm.isUserDemoFarm()
}

export function getUserDemoFarm(state: RootState): FarmRecord | undefined {
  const farmList = getDemoFarms(state)
  if (farmList.size === 0) {
    return undefined
  }
  return farmList.first()
}

export function getUserDemoFarmId(state: RootState): string | undefined {
  const userDemoFarm = getUserDemoFarm(state)
  return userDemoFarm && userDemoFarm.id
}

export function currentFarmIsUserDemoFarm(state: RootState): boolean {
  return isUserDemoFarm(getCurrentFarm(state))
}

export function getWizardCompletionInfo(farm) {
  const wizardSteps = WIZARD_STEPS.toSet()

  const completedSteps = (farm.wizard_steps_completed || List([]))
    .toSet()
    .intersect(wizardSteps)
  const remainingSteps = wizardSteps.subtract(completedSteps)
  return { completedSteps, remainingSteps }
}

export function farmWizardCompleted(farm: FarmRecord | undefined): boolean {
  if (!farm) return false
  return farm.isWizardComplete()
}

export function currentFarmWizardCompleted(state: RootState): boolean {
  return farmWizardCompleted(getCurrentFarm(state))
}

export function getWizardSteps(
  state,
  farmId: string | undefined,
): List<string> {
  const currentFarm = getFarmById(state, farmId)
  if (!currentFarm) {
    return List()
  }
  return currentFarm.wizard_steps_completed || List()
}

export function getCurrentOrganisationWizardSteps(state: RootState): number {
  return getWizardSteps(state, getCurrentFarmId(state)).size
}

export function getFinancialDetail(
  farm: FarmRecord | undefined,
): FarmFinancialDetail | undefined {
  return farm && farm.financial_detail
}

export function isFarmGstRegistered(farm: FarmRecord | undefined) {
  const financialDetail = getFinancialDetail(farm)
  return !!financialDetail?.is_gst_registered
}

export function farmIsGSTRegistered(state: RootState): boolean {
  const farm = getCurrentFarm(state)
  return isFarmGstRegistered(farm)
}

export function getFarmIdForSubscriptionId(
  state,
  subscriptionId: string,
): string | undefined {
  const farms = getAllFarms(state)
  const farm = farms.find(
    (farm: FarmRecord) => farm.subscription_id === subscriptionId,
  )
  return farm && farm.get('id')
}

export const isLimitedFarm = createSelector(
  [getCurrentFarm],
  (farm: FarmRecord) => farm?.isLimitedSubscription() ?? false,
)
