import { List, Record } from 'immutable'

import { Category, CategoryList } from '~/state/model/codes/Category'
import { GST_SECTION_NAME } from '~/state/model/codes/codeTypes'

export interface SectionFromServer {
  type?: string
  id: string
  name?: string
  section_type?: string
  position?: number
  categories?: any
  category_link_type?: null
}

export interface SectionFields {
  type: string
  id: string
  name: string
  section_type: string
  position: number
  categories: CategoryList
  applyFilter?: boolean
  indeterminate?: boolean
  expanded?: boolean
  category_link_type?: null
}

const sectionDefaults: SectionFields = {
  type: '',
  id: '',
  name: '',
  section_type: '',
  position: -1,
  categories: List(),
  applyFilter: undefined,
  indeterminate: undefined,
  expanded: undefined,
  category_link_type: null,
}

export class Section extends Record(sectionDefaults) {
  static empty() {
    return new Section()
  }

  static fromServerJS(data: SectionFromServer): Section {
    const categoryFromServers = data.categories || []
    return new Section({
      type: data.type,
      id: data.id,
      name: data.name,
      section_type: data.section_type,
      position: data.position,
      categories: List(categoryFromServers.map(Category.fromServerJS)),
    })
  }

  isGstSection() {
    return (
      this.get('name') === GST_SECTION_NAME &&
      (this.get('type') === 'SECTION' || this.has('categories'))
    )
  }

  isTotalSection() {
    return ['SUBTOTAL', 'RUNNINGTOTAL'].includes(this.get('section_type'))
  }
}
