import { styled } from '@mui/material/styles'
import MuiTextField from '@mui/material/TextField'
import { ComponentProps, FocusEvent, forwardRef, useId } from 'react'

import FieldLabel from '../FieldLabel'

export interface TextFieldProps extends ComponentProps<typeof MuiTextField> {
  /** This indicates whether the field label should indicate the field as optional */
  optional?: boolean
  /** Whether the field is read-only. This is distinct to a field being disabled. */
  readOnly?: boolean
  'data-e2e-ci'?: string
  /** Use the standard MUI shrinking label */
  useMuiLabel?: boolean
  selectAllOnFocus?: boolean
}

const StyledTextField = styled(MuiTextField)(() => ({
  '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-multiline': {
    paddingBlock: 0,
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    fontSize: 16,
    paddingBlock: '8.5px',
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall': {
    fontSize: 14,
    lineHeight: '20px',
    paddingBlock: '6px',
  },
}))

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    const {
      label,
      optional,
      readOnly = false,
      size = 'medium',
      InputProps,
      inputProps,
      'data-e2e-ci': dataE2ECi,
      useMuiLabel = false,
      selectAllOnFocus = false,
      id: idProp,
      ...rest
    } = props

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      inputProps?.onFocus?.(event)
      if (selectAllOnFocus && props.value) {
        const valueLen = props.value.toString().length
        const start = props.value.toString().indexOf('-') + 1
        setTimeout(() => event.target.setSelectionRange(start, valueLen), 0)
      }
    }

    const reactId = useId()
    const id = idProp ?? reactId

    const field = (
      <StyledTextField
        variant='outlined'
        id={id}
        size={size}
        InputProps={{
          ...InputProps,
          inputProps: {
            ...InputProps?.inputProps,
            ...inputProps,
            'data-e2e-ci': dataE2ECi,
            onFocus: handleFocus,
          },
          readOnly,
        }}
        label={useMuiLabel ? label : undefined}
        {...rest}
        ref={ref}
      />
    )

    if (label && !useMuiLabel) {
      return (
        <FieldLabel
          id={id}
          label={label}
          optional={optional}
          disabled={props.disabled}
          error={props.error}
        >
          {field}
        </FieldLabel>
      )
    }

    return field
  },
)

TextField.displayName = 'TextField'
