import { List, Map, Record } from 'immutable'
import moment from 'moment'

import { Optional } from '~/activity/entries/ReduxTypes'
import { isUndefinedOrNull } from '~/common/utils'
import { ISODate } from '~/state/model/ModelTypes'

interface OldDateFormatFields {
  year: number
  month: number // zero based
  day?: number
}

const OldDateFormatDefaults: OldDateFormatFields = {
  year: undefined as any,
  month: undefined as any,
  day: undefined,
}

export class LocalDate extends Record(OldDateFormatDefaults) {
  static YEAR_MONTH_FORMAT_STRING = 'YYYY-MM'
  static YEAR_MONTH_DAY_FORMAT_STRING = 'YYYY-MM-DD'
  static DAY_MONTH_YEAR_FORMAT_STRING = 'DD MMM YYYY'
  private static warpedToday: Optional<LocalDate>

  constructor(data: OldDateFormatFields) {
    super(data)
  }

  static warpTodayTo(
    todayForTest: LocalDate,
    methodWithWarpedTime: () => void,
  ) {
    LocalDate.warpedToday = todayForTest
    try {
      methodWithWarpedTime()
    } finally {
      LocalDate.warpedToday = undefined
    }
  }

  static today(): LocalDate {
    return LocalDate.warpedToday || LocalDate.constructFromMoment(moment())
  }

  static constructWithImmutableObject(
    immutableDate: Map<string, any> | Record<any>,
  ): LocalDate {
    const today = LocalDate.today()

    // return LocalDate.constructWithJSObject(immutableDate.toJS() as any)
    // when the day was not provided, this was leaving it null, I'm not sure
    // if we can change that now? ...{ year: today.year, month: today.month, day: today.day },
    return LocalDate.constructWithJSObject({
      ...{ year: today.year, month: today.month, day: 1 },
      ...(immutableDate.toJS() as any as OldDateFormatFields),
    })
  }

  static constructFromMoment(date: moment.Moment): LocalDate {
    return LocalDate.constructWithComponents(
      date.year(),
      date.month(),
      date.date(),
    )
  }

  static constructWithString(newFormat: string): LocalDate {
    if (!newFormat) {
      return LocalDate.today()
    }
    const [year, month, day]: number[] = newFormat
      .split('-')
      .map((x: string) => parseInt(x, 10))
    return LocalDate.constructWithComponents(year, month - 1, day)
  }

  static constructWithJSObject(obj: OldDateFormatFields): LocalDate {
    return new LocalDate(obj)
  }

  static constructWithJSObjectList(
    obj: OldDateFormatFields[],
  ): List<LocalDate> {
    return List(obj).map(LocalDate.constructWithJSObject).toList()
  }

  static constructWithComponents(
    year: number,
    month: number, // zero based
    day?: number | null,
  ): LocalDate {
    return LocalDate.constructWithJSObject({
      year: year,
      month: month,
      day: isUndefinedOrNull(day) ? undefined : day,
    })
  }

  static constructWithStringComponents(year: string, month: string, day = '1') {
    // Month is zero based
    return LocalDate.constructWithJSObject({
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day, 10),
    })
  }

  static constructWithYearEndStringComponents(toYYYYMM: string) {
    // Receives the GQL toYYYYMM and returns returns the date to the end of that year month
    const yearEndMonth = LocalDate.constructWithJSObject({
      year: parseInt(toYYYYMM.substr(0, 4), 10),
      month: parseInt(toYYYYMM.substr(5, 2), 10) - 1, // Month is NOT zero based, convert to zero base month
      day: 1,
    })

    return LocalDate.constructFromMoment(yearEndMonth.toMoment().endOf('month'))
  }

  /**
   * Converts our old date format to a moment.
   *
   * @param {Map} LocalDate
   * @returns {extendedMoment}
   */
  toMoment(): moment.Moment {
    let result = moment({
      year: this.year,
      month: this.month,
    })

    // a day of 0 isn't really valid, so it's OK to use falsy
    if (this.day) {
      result = result.set('date', this.day)
      result = result.startOf('date')
    } else {
      result = result.startOf('month')
    }

    return result
  }

  toJSObject(): OldDateFormatFields {
    return { year: this.year, month: this.month, day: this.day }
  }

  formatAsYearMonth(): string {
    return this.format(LocalDate.YEAR_MONTH_FORMAT_STRING)
  }

  // formatAsYYYYMM is faster than formatAsYearMonth but produces the same result
  formatAsYYYYMM(): string {
    return `${this.year}-${(this.month + 1).toString().padStart(2, '0')}` // LocalDate is zero based month
  }

  formatAsYearMonthDay(): ISODate {
    return this.format(LocalDate.YEAR_MONTH_DAY_FORMAT_STRING)
  }

  formatAsHumanDate() {
    return this.format(LocalDate.DAY_MONTH_YEAR_FORMAT_STRING)
  }

  format(formatString: string): string {
    return this.toMoment().format(formatString)
  }

  isBefore(otherDate: LocalDate): boolean {
    return this.toMoment().isBefore(otherDate.toMoment())
  }

  isBeforeOrEqual(otherDate: LocalDate): boolean {
    return !this.isAfter(otherDate)
  }

  isAfter(otherDate: LocalDate): boolean {
    return this.toMoment().isAfter(otherDate.toMoment())
  }

  isAfterOrEqual(otherDate: LocalDate): boolean {
    return !this.isBefore(otherDate)
  }

  isSameAs(otherDate: Optional<LocalDate>): boolean {
    if (!otherDate) {
      return false
    }
    return this.toMoment().isSame(otherDate.toMoment())
  }

  subtractMonths(numberOfMonths: number) {
    return LocalDate.constructFromMoment(
      this.toMoment().subtract(numberOfMonths, 'months'),
    )
  }

  addMonths(numberOfMonths: number) {
    return LocalDate.constructFromMoment(
      this.toMoment().add(numberOfMonths, 'months'),
    )
  }

  atEndOfMonth(): LocalDate {
    return LocalDate.constructFromMoment(this.toMoment().endOf('month'))
  }

  atBeginningOfMonth(): LocalDate {
    if (this.day === 1) return this

    return LocalDate.constructWithComponents(this.year, this.month, 1)
  }

  addYears(numberOfYears: number): LocalDate {
    return LocalDate.constructFromMoment(
      this.toMoment().add(numberOfYears, 'years'),
    )
  }

  subtractYears(numberYears: number): LocalDate {
    return this.subtractMonths(numberYears * 12)
  }

  subtractDays(numberOfDays: number): LocalDate {
    return LocalDate.constructFromMoment(
      this.toMoment().subtract(numberOfDays, 'days'),
    )
  }
}
