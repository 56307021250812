import { List, Record } from 'immutable'

import { Option } from '~/common/ui/forms/selects/Select'
import { UUID } from '~/state/model/ModelTypes'

import { AddressRecord } from './AddressRecord'

export interface ContactFromServer {
  id: UUID
  active?: boolean
  first_name: string | null
  last_name: string | null
  email: string | null
  email_invoice_document_when_generated: boolean
  phone: string | null
  mobile: string | null
  other_party_name: string | null
  phone_home: string | null
  bank_account_number: string | null
  bank_code: string | null
  bank_particulars: string | null
  business_number: string | null
  website: string | null
  physical_address?: AddressRecord
  postal_address?: AddressRecord
}
interface FarmContactRecordFields extends ContactFromServer {}

export const farmContactDefaults: FarmContactRecordFields = {
  id: '',
  active: true,
  first_name: '',
  last_name: '',
  email: '',
  email_invoice_document_when_generated: false,
  phone: '',
  mobile: '',
  other_party_name: '',
  phone_home: '',
  bank_account_number: '',
  bank_code: '',
  bank_particulars: '',
  business_number: '',
  website: '',
  physical_address: AddressRecord.nullRecord(),
  postal_address: AddressRecord.nullRecord(),
}

export type FarmContactList = List<FarmContactRecord>
export class FarmContactRecord extends Record(farmContactDefaults) {
  static fromServerJS(data: ContactFromServer): FarmContactRecord {
    return new FarmContactRecord({
      ...farmContactDefaults,
      ...data,
      physical_address: AddressRecord.fromServerJS(data.physical_address),
      postal_address: AddressRecord.fromServerJS(data.postal_address),
    })
  }
  static fromServerArray(data?: ContactFromServer[]): FarmContactList {
    return List((data || []).map((r) => FarmContactRecord.fromServerJS(r)))
  }

  static nullRecord(
    overides?: Partial<FarmContactRecordFields>,
  ): FarmContactRecord {
    return new FarmContactRecord({ ...farmContactDefaults, ...overides })
  }

  sortValue(): string {
    return (this.other_party_name || '').toUpperCase()
  }

  toApiJS(): ContactFromServer {
    return this.toJS() as ContactFromServer
  }
}

export const getContactOptions = (contacts: FarmContactList): Option[] => {
  return contacts
    .map((contact) => ({
      label: contact.other_party_name || contact.id,
      value: contact.other_party_name || contact.id,
      id: contact.id || undefined,
    }))
    .toArray()
}
