import { FC } from 'react'

export const ErrorContainer: FC<{
  children?: React.ReactNode
  image?: {
    src: string
    alt?: string
  }
}> = (props) => {
  const image = props.image
  return (
    <div className='mx-auto flex max-w-screen-lg flex-col items-center justify-center bg-white px-10 py-20 text-center shadow-lg'>
      {image && (
        <img
          src={image.src}
          alt={image.alt ?? ''}
          className='mb-8 w-1/2 max-w-64'
        />
      )}
      <div className='max-w-screen-sm'>{props.children}</div>
    </div>
  )
}

export const ErrorTitle: FC<{ children?: React.ReactNode }> = (props) => {
  return <h1 className='text-2xl text-gray-700'>{props.children}</h1>
}

export const ErrorText: FC<{ children?: React.ReactNode }> = (props) => {
  return <p className='text-balance text-2xl text-gray-900'>{props.children}</p>
}

export const ErrorSubText: FC<{ children?: React.ReactNode }> = (props) => {
  return <p className='text-base text-gray-800'>{props.children}</p>
}
