import { Decimal } from 'decimal.js'
import numeral from 'numeral'

import { Optional } from '~/activity/entries/ReduxTypes'
import { FeatureFlag, featureSwitcher } from '~/services/FeatureFlag'
import { FarmFinancialDetail } from '~/state/model/farms/FarmFinancialDetail'
import { CountryInfoId } from '~/state/model/farms/FarmRecord'
import { LIVESTOCK_EVENT_TYPES } from '~/state/selectors/grid/types'

import { isUndefinedOrNull } from '../utils'

type FormattableNumberThingy = number | Decimal | string

// True if amount has negative and amount not equal to 0
export function isAmountNegative(
  amount: FormattableNumberThingy,
): boolean | number {
  const val: any = amount
  if (isNaN(val)) return NaN
  return Math.sign(val) < 0 && Math.sign(val) !== 0
}

export function isAmountNegativeOrNegativeZero(
  amount: FormattableNumberThingy,
): boolean | number {
  const val: any = amount
  if (isNaN(val)) return NaN
  return Math.sign(val) <= 0 && !Object.is(0, Math.sign(val))
}

const formatAmountInThousandsImpl = (
  amount: FormattableNumberThingy | null,
  { decimals, absolute }: { decimals: number; absolute: boolean },
): string => {
  // keep this private
  const groupInThousandsRegex = /\B(?=(\d{3})+(?!\d))/g

  const val: any = amount
  if (val === null || isNaN(val)) {
    return ''
  } // isNaN(undefined) is true
  let tmpAmount = toDecimal(val)
  if (absolute) {
    tmpAmount = tmpAmount.abs()
  }
  const fixedAmount = tmpAmount.toDP(decimals).toFixed(decimals)

  const wholePart = fixedAmount.split('.')[0]
  const decimalPart = fixedAmount.split('.')[1]
  // we need to apply the , only to the whole number part (before the decimal)
  let formattedAmount = wholePart.replace(groupInThousandsRegex, ',')
  if (decimals) {
    // put the decimal back if there was one
    formattedAmount = `${formattedAmount}.${decimalPart}`
  }

  return formattedAmount
}

// Generic formatter
export const formatAmountInThousands = (
  amount: FormattableNumberThingy | null,
  decimals,
): string => formatAmountInThousandsImpl(amount, { decimals, absolute: true })

export const formatSignedAmountInThousands = (
  amount: FormattableNumberThingy | null,
  decimals,
): string => formatAmountInThousandsImpl(amount, { decimals, absolute: false })

export function formatAsCommaSeperatedCurrency(amount: Decimal): string {
  return numeral(amount.toNumber()).format('0,0.00')
}

export function safeAmount(amount?: string, nullable?: boolean): string {
  if (!amount) {
    return nullable ? '' : '0'
  }
  if (amount === '-') {
    return nullable ? '' : '0'
  }
  return amount
}

// If negative amount --> ($1.00)DairyForecaster.tsx
// If positive amount --> $1.00
export const formatAmountOpt = {
  absolute: true,
  nonAbsolute: false,
  twoDP: 2,
}

export function decimalToDpString(num: Decimal, decimals: number): string {
  return toDecimal(num.toFixed(decimals)).toString()
}

export function formatAmount(
  amount: Optional<FormattableNumberThingy>,
  returnAbsoluteValue = false,
  decimalPlaces?: number | undefined,
): string {
  const val: any = amount
  if (val === null || val === undefined) {
    return ''
  }
  // default to 0 DP's unless provided
  const decimals =
    (decimalPlaces == null || isNaN(decimalPlaces) ? 2 : decimalPlaces) || 0
  // if (isNaN(val)) throw new Error('Amount should not be NaN.')
  // TODO: I wonder if we should remove this line? leaving it here as it's been here for ever, but maybe it's time to say goodbye?
  if (isNaN(val)) throw new Error(`Amount should not be NaN.****${val}****`)

  const formattedAmount = formatAmountInThousandsImpl(val, {
    decimals,
    absolute: true,
  })

  if (isAmountNegative(val) && !returnAbsoluteValue)
    return `($${formattedAmount})`
  return `$${formattedAmount}`
}

export function formatUnitPrice(amount, decimalPlaces): string {
  if (amount === null || amount === undefined) {
    return ''
  }

  const formattedAmount = formatAmountInThousandsImpl(amount, {
    decimals: decimalPlaces,
    absolute: true,
  })

  if (isAmountNegative(amount)) {
    return `($${formattedAmount})`
  } else {
    return `$${formattedAmount}`
  }
}

const shouldShowBrackets = (
  amount: number | Decimal | string,
  eventType,
): boolean | 0 => {
  return (
    (isAmountNegative(amount) ||
      eventType === LIVESTOCK_EVENT_TYPES.DEATH ||
      eventType === LIVESTOCK_EVENT_TYPES.MISSING ||
      eventType === LIVESTOCK_EVENT_TYPES.KILLED) &&
    eventType !== LIVESTOCK_EVENT_TYPES.FOUND
  )
}

export function flexibleFormatAmount(
  amount: number | Decimal | string,
  decimalPlaces: number,
  currency?: Optional<string>,
  eventType?: string,
): string {
  let formattedAmount: string = formatAmountInThousands(
    amount,
    decimalPlaces || 0,
  )

  // Don't add currency symbol if empty string
  if (Boolean(formattedAmount) && currency) {
    formattedAmount = `${currency} ${formattedAmount}`
  }

  if (shouldShowBrackets(amount, eventType)) {
    formattedAmount = `(${formattedAmount})`
  }

  return formattedAmount
}

// The regex used here adds a comma thousands separator to a given amount (e.g 300000 => 300,000)
// Alternatively, you could use the number.toLocaleString() but Safari does not support passing options
// to that function so a regex is used instead.
export function formatAmountInGrid(amount: number, eventType?: string): string {
  return flexibleFormatAmount(amount, 0, undefined, eventType)
}

export function formatAmountInItemRow(
  amount: Decimal,
  eventType: string,
): string {
  const formattedAmount = formatAmount(amount)
  return eventType === LIVESTOCK_EVENT_TYPES.PURCHASE && amount.isZero()
    ? `(${formattedAmount})`
    : formattedAmount
}

export const formatAmountInReport = (
  amount: FormattableNumberThingy,
  decimalPlaces?: number,
  currency?: string,
): string => flexibleFormatAmount(amount, decimalPlaces || 0, currency)

// Accepts a positive Decimal.js Object, String, Integer, Float and
// does it's best to return a string formatted `X,XXX.XX`
export const formatGSTReturnAmount = (
  amount: Optional<FormattableNumberThingy>,
  countryCode: CountryInfoId = 'NZL',
  separateThousands = true,
): string => {
  let formattedAmount = ''
  if (isUndefinedOrNull(amount)) {
    return formattedAmount
  }
  const decimalAmount = toDecimal(amount)
  if (decimalAmount === zero) {
    return '0.00'
  }

  let decimals: number
  if (countryCode === 'AUS') {
    decimals = 0
    formattedAmount = decimalAmount.toFixed(decimals, Decimal.ROUND_DOWN)
  } else {
    decimals = 2
    formattedAmount = decimalAmount.toFixed(decimals)
  }

  return separateThousands
    ? formatAmountInThousandsImpl(decimalAmount, { decimals, absolute: false })
    : formattedAmount
}

export const zero = new Decimal(0)

export function isANumber(arg): boolean {
  if (typeof arg === 'string' && arg.trim() === '') {
    // an empty string is not a number
    return false
  }
  return arg && !isNaN(arg) // TODO: if arg is Number zero, this is broken
}

// strip all '-', except for one at first char
const stripOtherMinuses = (val: string): string => val.replace(/(?!^)-/g, '')

export function trimAndStripLeadingPlus(arg: string): string {
  return stripOtherMinuses(
    arg
      .toString()
      .replace(/[\+\$\)\,]/g, '') // replace '+', '$', ')', ',' with space
      .replace(/\(/g, '-') // replace '(' with '-'
      .replace(/^\s*([0-9\.]+)\s*$/, '$1'), // trim space
  )
}

export function toDecimal(s: Optional<number | Decimal | string>): Decimal {
  if (!s) {
    return zero
  }

  if (typeof s === 'number') {
    return new Decimal(s)
  }

  if (typeof s === 'object' && s instanceof Decimal) {
    return s
  }

  const trimmed = trimAndStripLeadingPlus(s)
  if (isANumber(trimmed)) {
    return new Decimal(trimmed)
  }

  return zero
}

export const DECIMAL_PLACES = 4

export function round(
  value: string | number | Decimal | undefined,
  dp = 2,
): string | undefined {
  if (value !== undefined) {
    if (typeof value === 'string' || typeof value === 'number') {
      value = toDecimal(value)
    }
    return value.toDP(dp, Decimal.ROUND_HALF_UP).toFixed(dp)
  }
  return value
}
export function safeRound(value: Decimal, dp = 2): string {
  return value.toDP(dp, Decimal.ROUND_HALF_UP).toFixed(dp)
}

export function areExactSameSign(val1: Decimal, val2: Decimal): boolean {
  return Decimal.sign(val1) === Decimal.sign(val2)
}
export function areSameSign(val1: Decimal, val2: Decimal): boolean {
  // Note sign returns:
  // 1	if the value of x is non-zero and its sign is positive
  // -1	if the value of x is non-zero and its sign is negative
  // 0	if the value of x is positive zero
  // -0	if the value of x is negative zero
  // however we don't really care about the sign of zero, if either number is zero, then it's like
  // it's the same sign as the other?
  if (val1.isZero() || val2.isZero()) {
    return true
  }
  return areExactSameSign(val1, val2)
}

export function isAtMostDP(val: Decimal | number | string, dp = 2): boolean {
  return toDecimal(val).toDP(dp).toString() === toDecimal(val).toString()
}

export function formatCurrency(
  value: string | Decimal | number | undefined,
  absolute = false,
): string | undefined {
  if (value) {
    if (typeof value === 'string' || typeof value === 'number') {
      value = toDecimal(value)
    }
    if (absolute) {
      return value.abs().toFixed(2)
    }
    return value.toFixed(2)
  }
}

export function formatCurrencyToFourDP(
  value: string | Decimal | number | undefined,
): string | undefined {
  if (value) {
    if (typeof value === 'string' || typeof value === 'number') {
      value = toDecimal(value)
    }
    return value.toFixed(DECIMAL_PLACES)
  }
}

// returns with either 2 or 3 or 4 decimal places (depending on whats "best", ideally only 2 DP)
export const formatWithMin2DP = (value: string): string => {
  const val = toDecimal(value)
  if (val.toDP(2).toDP(4).toString() === val.toDP(4).toString()) {
    return val.toFixed(2)
  } else if (val.toDP(3).toDP(4).toString() === val.toDP(4).toString()) {
    return val.toFixed(3)
  }
  return val.toFixed(4)
}

export function formatWholePrice(price: Decimal): string {
  return `$${price.toFixed(0)}`
}

export const GST_RATE = 15

export interface GstCalculationResult {
  gst: Decimal
  amountIncl: Decimal
  amountExcl: Decimal
}

export function roundUp(amount: Decimal, dp = DECIMAL_PLACES): Decimal {
  return amount.toDP(dp, Decimal.ROUND_HALF_UP)
}

export function roundAmountToCents(amount: Decimal): Decimal {
  return roundUp(amount, 2)
}

export function roundLineAmount(amount: Decimal): Decimal {
  return roundUp(amount, 4)
}

export function roundLineUnitAmount(amount: Decimal): Decimal {
  return roundUp(amount, 8)
}

export function calcUnroundedGST(
  amountOfMixedTypes: FormattableNumberThingy,
  inclExcl: 'E' | 'I',
  gstRateOfMixedTypes: FormattableNumberThingy,
): GstCalculationResult {
  const amount: Decimal = toDecimal(amountOfMixedTypes)
  const gstRate: Decimal = toDecimal(gstRateOfMixedTypes)

  let result: GstCalculationResult
  if (inclExcl === 'I') {
    // amountExcl = amountIncl - gst based on incl amount
    const gst = amount.div(gstRate.plus(toDecimal(100.0))).mul(gstRate)

    result = {
      gst: gst,
      amountExcl: amount.sub(gst),
      amountIncl: amount,
    }
  } else {
    // amountIncl = amountExcl + gst based on excl amount
    const gst = amount.mul(gstRate).div(toDecimal(100.0))

    result = {
      gst: gst,
      amountExcl: amount,
      amountIncl: amount.plus(gst),
    }
  }
  return result
}

export function calcGST(
  amountOfMixedTypes: FormattableNumberThingy,
  inclExcl: 'E' | 'I',
  gstRateOfMixedTypes: FormattableNumberThingy,
  decimalPlaces = DECIMAL_PLACES,
): GstCalculationResult {
  const result = calcUnroundedGST(
    amountOfMixedTypes,
    inclExcl,
    gstRateOfMixedTypes,
  )
  return {
    gst: roundUp(result.gst, decimalPlaces),
    amountExcl: roundUp(result.amountExcl, decimalPlaces),
    amountIncl: roundUp(result.amountIncl, decimalPlaces),
  }
  // is this better???? I'm wondering - but now is not the time to find out
  // maybe have another look at this after the old invoice form is removed
  // const amountExcl = roundUp(result.amountExcl, decimalPlaces)
  // const amountIncl = roundUp(result.amountIncl, decimalPlaces)
  // return {
  //   // gst: roundUp(result.gst, decimalPlaces),
  //   gst: amountIncl.minus(amountExcl),
  //   amountExcl,
  //   amountIncl,
  // }
}

// I wonder if we should have a BusinessCurrencyUtils file, where this function
// would be moved to - so things that know about out business domain would be in
// there rather than in this file?
export function getGstRateForGstType(
  gstType: string,
  financialDetails: FarmFinancialDetail,
  forceForNonGstRegistered: boolean,
): number {
  return gstType === 'BUSINESS'
    ? (financialDetails &&
        (financialDetails.is_gst_registered || forceForNonGstRegistered) &&
        financialDetails.default_gst_rate &&
        Number(financialDetails.default_gst_rate)) ||
        0
    : 0
}

export function isNumber(...args): boolean {
  const numbers = Array.from(args)
  return numbers.every(isANumber)
}

export function trimAndStripLeadingMinus(arg: string): string {
  return arg.toString().replace(/\-/g, '').trim()
}

export const getDecimalRegexValue = (
  value: string,
  absolute?: boolean,
): string => {
  const thisVal = stripOtherMinuses(value.replace(/(?!^)-/g, ''))
  if (absolute) {
    const absoluteRegex = /[^\d.]/g
    return thisVal.replace(absoluteRegex, '')
  } else {
    const nonAbsoluteRegex = /[^\d.-]/g
    return thisVal.replace(nonAbsoluteRegex, '')
  }
}

export const getWholeNumberRegexValue = (
  value: string,
  absolute?: boolean,
): string => {
  const thisVal = stripOtherMinuses(value.replace(/(?!^)-/g, ''))
  if (absolute) {
    const absoluteRegex = /[^\d]/g
    return thisVal.replace(absoluteRegex, '')
  } else {
    const nonAbsoluteRegex = /[^\d-]/g
    return thisVal.replace(nonAbsoluteRegex, '')
  }
}

export function groupDigitsWithCommaSeparator(amount: number): string {
  if (isAmountNegative(amount)) {
    return `(${numeral(amount * -1).format('0,0')})`
  } else {
    return numeral(amount).format('0,0')
  }
}

export function groupDigitsWithCommaSeparatorAnd1DP(amount: number): string {
  if (isAmountNegative(amount)) {
    return `(${numeral(amount * -1).format('0,0.0')})`
  } else {
    return numeral(amount).format('0,0.0')
  }
}

export function groupDigitsWithCommaSeparatorAnd2DP(
  amount: number | undefined,
): string {
  if (amount === undefined) {
    return ''
  }

  if (isAmountNegative(amount)) {
    return `(${numeral(amount * -1).format('0,0.00')})`
  } else {
    return numeral(amount).format('0,0.00')
  }
}

export const calculateProportionedAmount = (
  lineAmount: undefined | string | number | Decimal,
  invoiceTotal: undefined | string | number | Decimal,
  transactionTotalAmount: undefined | string | number | Decimal,
): Decimal => {
  if (
    lineAmount === undefined ||
    invoiceTotal === undefined ||
    transactionTotalAmount === undefined
  ) {
    return toDecimal(0)
  }

  const lineAmountDecimal = toDecimal(lineAmount)
  const invoiceTotalDecimal = toDecimal(invoiceTotal)
  const transactionTotalAmountDecimal = toDecimal(transactionTotalAmount)

  if (invoiceTotalDecimal.isZero() || invoiceTotalDecimal.isNaN()) {
    return toDecimal(0)
  }

  return new Decimal(lineAmountDecimal)
    .mul(transactionTotalAmountDecimal)
    .div(invoiceTotalDecimal)
    .toDP(2)
}

export const apply50PercentDiscountIfFeatureIsOn = (
  price: Decimal,
): Decimal => {
  const discountFeatureIsOn = featureSwitcher.isOn(
    FeatureFlag.DiscountManagementTagsBy50PercentUntil2023,
  )

  return discountFeatureIsOn && price ? price.dividedBy(2) : price
}

export const isZero = (val: string | number | undefined | null): boolean =>
  toDecimal(val).isZero()

export const isInvalidValue = (value: Decimal): boolean =>
  value.isZero() || value.isNaN() || !value.isFinite()

export const safeDecimalForMultiplication = (
  value: Optional<number | Decimal | string>,
): Decimal => {
  return toDecimal(value).isZero() ? toDecimal(1) : toDecimal(value)
}

export const isValidAmount = (value: Decimal): boolean => !isInvalidValue(value)

interface FormatNumberAmountInReportProps {
  value: number | Decimal
  decimals: number
}

export const formatNumberAmountInReport = ({
  value,
  decimals,
}: FormatNumberAmountInReportProps): string =>
  isInvalidValue(toDecimal(value)) ? '' : formatAmountInReport(value, decimals)

interface FormatQuantityInReportProps extends FormatNumberAmountInReportProps {
  zeroAsBlank: boolean
}

export const formatQuantityInReport = ({
  value,
  decimals,
  zeroAsBlank,
}: FormatQuantityInReportProps): string =>
  isInvalidValue(toDecimal(value))
    ? zeroAsBlank
      ? ''
      : '0'
    : formatAmountInReport(value, decimals)

export const formatStockQuantity = (value): string =>
  formatNumberAmountInReport({
    value,
    decimals: 0,
  })

export const formatStockUnits = (value): string =>
  formatNumberAmountInReport({
    value,
    decimals: 1,
  })

export const formatWeight = (value): string =>
  formatNumberAmountInReport({
    value,
    decimals: 2,
  })

export const formatTotalWeight = (value): string =>
  formatNumberAmountInReport({
    value,
    decimals: 0,
  })

export const formatValue = (value): string =>
  formatNumberAmountInReport({
    value,
    decimals: 2,
  })

export const formatTotalValue = (value): string =>
  formatNumberAmountInReport({
    value,
    decimals: 0,
  })

export const invertSigns = (amount): string => {
  if (toDecimal(amount).isPositive()) {
    return toDecimal(amount).isZero() ? '-0' : `${-Math.abs(Number(amount))}`
  } else {
    return `${Math.abs(Number(amount))}`
  }
}
