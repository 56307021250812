import { useField } from 'formik'
import { FC } from 'react'

import { Checkbox } from '../Checkbox'

interface Props extends React.ComponentProps<typeof Checkbox> {
  name: string
}

/**
 * A Formik enabled Checkbox based on the [MUI Checkbox](https://mui.com/material-ui/react-checkbox/) component.
 *
 * Invision Documentation: [Checkbox](https://projects.invisionapp.com/d/main/default/#/console/22221912/471907110/preview)
 */
const FormikCheckBox: FC<Props> = (props) => {
  const { name, onChange, ...rest } = props

  const [field] = useField({
    name,
  })

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    field.onChange(event)
    if (onChange) {
      onChange(event)
    }
  }

  return (
    <Checkbox
      name={field.name}
      checked={field.value}
      onChange={handleChange}
      {...rest}
    />
  )
}

export default FormikCheckBox
