import { List, Record } from 'immutable'

enum CodingRuleTarget {
  bankTransaction = 'BANK_TRANSACTION',
  invoice = 'INVOICE',
}

export enum FieldToDescription {
  amount_incl = 'Amount is equal to $',
  line_amount_incl = 'Line amount is equal to $',
  bank_account_id = 'Bank Account',
  line_description = 'Line Description contains',
  other_party = 'Other Party contains',
  particulars = 'Particulars contains',
  reference = 'Reference contains',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  supplier = 'Other Party contains',
  none = '',
}

type CriteriaOperation = 'contains' | 'equals' | ''

export type CriteriaField = keyof typeof FieldToDescription

export type CriteriaLabel = //= keyof typeof FieldToDescription

    | 'Other Party contains'
    | 'Reference contains'
    | 'Amount is equal to $'
    | 'Line amount is equal to $'
    | 'Bank Account'
    | 'Line Description contains'
    | 'Particulars contains'
    | ''

export type CriteriaValue =
  | 'supplier'
  | 'other_party'
  | 'reference'
  | 'line_description'
  | 'amount_incl'
  | 'bank_account_id'
  | 'particulars'

export type CriteriaOption = { label: CriteriaLabel; value: CriteriaValue }

export interface CodeRuleCriteriaFromServer {
  field?: CriteriaField
  operation: CriteriaOperation
  value: string
  displayValue?: string
  field_plus_operation?: CriteriaLabel
}

interface CodeRuleCriteriaCommonFields {
  field: CriteriaField
  operation: CriteriaOperation
  value: string
  field_plus_operation: CriteriaLabel
  displayValue?: string
}

interface CodeRuleCriteriaFields extends CodeRuleCriteriaCommonFields {}
export interface CodeRuleCriteriaJS extends CodeRuleCriteriaCommonFields {}

const codeRuleCriteriaDefaults: CodeRuleCriteriaFields = {
  field: 'none',
  operation: '',
  value: '',
  // the UI uses a combination of the field & the operation
  // while the DB saves these as two distinct values
  // It would probably have been easier to save as a combined value...
  // but we need to map between these & it's better to do that in here
  field_plus_operation: '',
  // a value to display - populated in the selector, resolves things like bank account name etc
  displayValue: '',
}

export type CodeRuleCriteriaList = List<CodeRuleCriteriaRecord>

export class CodeRuleCriteriaRecord extends Record(codeRuleCriteriaDefaults) {
  static fromServerJS(
    data: CodeRuleCriteriaFromServer,
    target?: CodingRuleTarget,
  ): CodeRuleCriteriaRecord {
    const fields: CodeRuleCriteriaFields = {
      ...codeRuleCriteriaDefaults,
      ...data,
      ...this.inferFieldOrOperation(data, target),
    }
    return new this(fields)
  }
  static fromServerArray(
    src: CodeRuleCriteriaFromServer[],
    target?: CodingRuleTarget,
  ): CodeRuleCriteriaList {
    return List(src.map((a) => this.fromServerJS(a, target)))
  }

  static nullRecord(): CodeRuleCriteriaRecord {
    return new this(codeRuleCriteriaDefaults)
  }

  static fieldPlusOperation(field: CriteriaField): CriteriaLabel {
    return FieldToDescription[field] || 'Other Party contains'
  }

  static inferFieldOrOperation(
    data: CodeRuleCriteriaFromServer,
    target?: CodingRuleTarget,
  ): Partial<CodeRuleCriteriaFromServer> {
    if (!data) {
      return {}
    }
    // if theres an field_plus_operation, we know this is a UI set, so update field & operation
    if (data.field_plus_operation) {
      if (
        target === CodingRuleTarget.invoice &&
        (data.field === 'supplier' || data.field === 'other_party')
      ) {
        return {
          field: 'supplier',
          operation: fieldOperationToOperation(data.field_plus_operation),
        }
      } else {
        return {
          field: fieldOperationToField(data.field_plus_operation),
          operation: fieldOperationToOperation(data.field_plus_operation),
        }
      }
    }
    if (data.field) {
      return {
        field_plus_operation: this.fieldPlusOperation(data.field),
      }
    }
    return {}
  }
}

export const nullCodeRuleCriteria = (data = {}): CodeRuleCriteriaJS => ({
  ...codeRuleCriteriaDefaults,
  ...data,
})

// CodeRuleCriteriaJS helpers

// Field names CodingRule JSON structure
export const FIELD_OTHER_PARTY = 'other_party'
export const FIELD_SUPPLIER = 'supplier'
export const FIELD_REFERENCE = 'reference'
export const FIELD_AMOUNT_INCL = 'amount_incl'
export const FIELD_BANK_ACCOUNT = 'bank_account_id'
export const ENTRY_LINES = 'entry_lines'
export const FIELD_CODING_LINE_DESCRIPTION = 'line_description'
export const FIELD_PARTICULARS = 'particulars'

// Note this function doesn't work for 'supplier' as it shares the same key in the object as 'other_party'
export const fieldOperationToField = (
  field_plus_operation: CriteriaLabel,
): CriteriaField => {
  return {
    // [FieldToDescription.supplier]: FIELD_SUPPLIER,  FieldToDescription.supplier == FieldToDescription.other_party TS5.1 detected this
    [FieldToDescription.reference]: FIELD_REFERENCE,
    [FieldToDescription.other_party]: FIELD_OTHER_PARTY,
    [FieldToDescription.bank_account_id]: FIELD_BANK_ACCOUNT,
    [FieldToDescription.amount_incl]: FIELD_AMOUNT_INCL,
    [FieldToDescription.line_description]: FIELD_CODING_LINE_DESCRIPTION,
    [FieldToDescription.particulars]: FIELD_PARTICULARS,
  }[field_plus_operation]
}

export const isTextOperation = (criteriaRule: CodeRuleCriteriaJS) =>
  criteriaRule.operation === 'contains'

export const isNumberOperation = (criteriaRule: CodeRuleCriteriaJS) =>
  criteriaRule.operation === 'equals'

export const isBankAcountOperation = (criteriaRule: CodeRuleCriteriaJS) =>
  criteriaRule.field_plus_operation === FieldToDescription.bank_account_id

export const fieldOperationToOperation = (
  field_plus_operation: CriteriaLabel,
): CriteriaOperation =>
  field_plus_operation === FieldToDescription.amount_incl
    ? 'equals'
    : 'contains'

export const formatCriteria = (criteria: CodeRuleCriteriaJS): string =>
  FieldToDescription[criteria.field] || ''
