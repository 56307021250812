import { FC } from 'react'

import Link from '~/common/ui/buttons/Link'

import {
  ErrorContainer,
  ErrorSubText,
  ErrorText,
  ErrorTitle,
} from './components/Error'
import noPermissionImageUrl from './no-permission.svg'

interface Props {
  unpaid?: boolean
}
export const FourZeroOne: FC<Props> = ({ unpaid }) => (
  <ErrorContainer image={{ src: noPermissionImageUrl }}>
    <ErrorTitle>You don’t have permission to view this page</ErrorTitle>
    <ErrorText>
      {unpaid
        ? 'Access to this farm business is not available because there is a problem with the subscription'
        : 'If someone gave you this link, they may need to add you as a user to their subscription'}
    </ErrorText>
    <ErrorSubText>
      Contact them or our{' '}
      <Link
        className='text-base'
        href='https://www.farmfocus.co.nz/support/contact-us/'
        target='_blank'
        rel='noopener noreferrer'
      >
        support team
      </Link>{' '}
      for assistance.
    </ErrorSubText>
  </ErrorContainer>
)

export default FourZeroOne
