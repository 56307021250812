import { List, Map } from 'immutable'
import { lruMemoize } from 'reselect'

import { mapFromList } from '~/common/utils/ImmutableUtils'
import { Category, CategoryList } from '~/state/model/codes/Category'
import {
  CodeDetails,
  FoundCodeDetails,
  GENERAL_CODE,
} from '~/state/model/codes/codeTypes'
import { Extcode } from '~/state/model/codes/Extcode'
import { Section, SectionFromServer } from '~/state/model/codes/Section'

import { UUID } from '../ModelTypes'

export type SectionsListFromServer = SectionFromServer[]

export type SectionsList = List<Section>

export function createSectionListFromServerData(
  sections: SectionsListFromServer | undefined,
): SectionsList {
  if (!sections) {
    return List()
  }

  return List(sections.map(Section.fromServerJS))
}

export function emptySectionsList(): SectionsList {
  return List()
}

// returns sec with filtered categories
function filterExts(
  cat: Category,
  match: (ext: Extcode) => boolean,
): Category | null {
  const extCodes = cat.extcodes.filter(match)
  return extCodes.size > 0 ? cat.set('extcodes', extCodes) : null
}

function filterCats(
  sec: Section,
  match: (match: Extcode | Category) => boolean,
): Section {
  const filteredCats = sec.categories
    .map((cat) => (match(cat) ? cat : filterExts(cat, match)))
    .filter(
      (cat) => !!cat && cat.extcodes.filter((ext) => !!ext).size > 0,
    ) as CategoryList // we know that the empty cats have been filtered out, but TS doesn't

  return sec.set('categories', filteredCats)
}

export function filterSections(
  sections: SectionsList,
  match: (match: Extcode | Category | Section) => boolean,
): SectionsList {
  return (sections || emptySectionsList())
    .map((sec: Section) => (match(sec) ? sec : filterCats(sec, match)))
    .filter((sec: Section) => sec.categories.size > 0)
    .toList()
}

const getCodeDetailsFlattenned =
  (idFunction: (dets: FoundCodeDetails) => string) =>
  (sections: SectionsList): Map<string, FoundCodeDetails> => {
    const codeDetailsList: List<FoundCodeDetails> = sections.flatMap(
      (section: Section) => {
        if (!section.categories) return List()
        return section.categories.flatMap((category: Category) => {
          if (!category.extcodes) return List()
          return category.extcodes.map((extcode: Extcode): FoundCodeDetails => {
            return {
              sec: section,
              cat: category,
              ext: extcode,
              found: true,
            }
          })
        })
      },
    )

    return mapFromList(codeDetailsList, idFunction)
  }

/*
By converting the sections list to a map indexed by extcode id, we can do really fast searches for code
details. This is wrapped in a selector so that the conversion to a map is cached for speed.
 */
const codeDetailsByExtcodeId = lruMemoize(
  getCodeDetailsFlattenned((details: FoundCodeDetails) => details.ext.id),
)

/*
By converting the sections list to a map indexed by code string, we can do really fast searches for code
details. This is wrapped in a selector so that the conversion to a map is cached for speed.
 */
const codeDetailsByCodeString = lruMemoize(
  getCodeDetailsFlattenned((details: FoundCodeDetails) =>
    details.cat.toFormattedCodeString(details.ext).toUpperCase(),
  ),
)

export function getCodeDetailsFromFormattedCodeString(
  sections: SectionsList,
  search: string | undefined | null,
): CodeDetails {
  if (!search) {
    return {
      found: false,
    }
  }

  let [cat, ext] = search.split(':')
  cat = cat.toUpperCase().trim()
  ext = ext ? ext.toUpperCase().trim() : ''
  if (ext === GENERAL_CODE) {
    ext = ''
  }

  const revisedSearch = `${cat}:${ext}`

  const flattennedDetails = codeDetailsByCodeString(sections)

  const searchResults = flattennedDetails.get(revisedSearch)

  return searchResults || { found: false }
}

export function getExtcodeIdFromFormattedCodeString(
  sections: SectionsList,
  codeString?: string,
): string | undefined {
  const codeDetails = getCodeDetailsFromFormattedCodeString(
    sections,
    codeString,
  )
  return codeDetails.found ? codeDetails.ext.id : undefined
}

export function resolveCodes(values, sections: SectionsList) {
  values.entry_lines.forEach((el) => {
    el.extcode_id = getExtcodeIdFromFormattedCodeString(sections, el.code)
  })
}

export function isValidCode(
  sections: SectionsList,
  formattedCodeString: string,
): boolean {
  const codeDetails = getCodeDetailsFromFormattedCodeString(
    sections,
    formattedCodeString,
  )

  if (codeDetails.found) {
    return codeDetails.ext.active
  }

  return false
}

export function getCodeDetailsForExtCodeId(
  sections: SectionsList | undefined,
  extCodeId?: UUID | null,
): CodeDetails {
  if (!sections || !extCodeId) {
    return { found: false }
  }

  // use a cached flattened list for performance reasons
  const flattenedCodes = codeDetailsByExtcodeId(sections)

  const dets = flattenedCodes.get(extCodeId)
  if (dets) {
    return dets
  }

  return {
    found: false,
  }
}

export function isCodeExpenseCode(
  sections: SectionsList,
  extcodeId: UUID | null | undefined,
): boolean {
  const codeDetails = getCodeDetailsForExtCodeId(sections, extcodeId)
  return codeDetails.found && codeDetails.ext.isExpenseCode()
}

export function isGeneratedCode(
  sections: SectionsList,
  extcodeId?: UUID | null,
): boolean {
  const codeDetails = getCodeDetailsForExtCodeId(sections, extcodeId)
  return (
    codeDetails.found && codeDetails.cat.isGeneratedExtcode(codeDetails.ext)
  )
}

export function convertExtIdToCodeText(
  sections: SectionsList,
  extId: string,
): string | undefined {
  const details = getCodeDetailsForExtCodeId(sections, extId)
  return getFullCode(details)
}

export function getFullCode(details: CodeDetails): string | undefined {
  if (details.found) {
    return details.cat.toFormattedCodeString(details.ext)
  }
}

export function getParentExtcodeIds(
  sections: SectionsList,
  codes: CodeDetails,
  primaryOnly: boolean,
): string[] {
  if (!codes.found) {
    return []
  }

  const matchingCategory: Category | undefined = sections
    .flatMap((section: Section) => section.categories)
    .find((category: Category) => category.id === codes.cat.id)

  if (!matchingCategory) {
    return []
  }

  const extCodeIds: List<string> = matchingCategory.extcodes
    .filter((code: Extcode) =>
      primaryOnly ? code.isCodePrimaryBreeding() : code.isCodeBreedingCode(),
    )
    .map((code: Extcode) => code.id)

  return extCodeIds.toArray()
}

function isHidden(iterator, list: List<any>) {
  function isDeepHidden(code): boolean {
    if (code.has('categories')) {
      return iterator(isDeepHidden, code.get('categories'))
    }
    if (code.has('extcodes')) {
      return iterator(isDeepHidden, code.get('extcodes'))
    }
    return !code.get('applyFilter')
  }

  return iterator(isDeepHidden, list)
}

type Predicate<T> = (value: T) => boolean

export function areSomeVisible(codes: SectionsList): boolean {
  return !isHidden(
    <T>(predicate: Predicate<T>, list: List<T>): boolean =>
      list.every(predicate),
    codes,
  )
}

export function areSomeHidden(codes: SectionsList): boolean {
  return isHidden(
    <T>(predicate: Predicate<T>, list: List<T>): boolean =>
      list.some(predicate),
    codes,
  )
}

export function findSectionByName(
  sections: SectionsList,
  name: string,
): Section | undefined {
  return sections.find((sec) => sec.name === name)
}

export function findCategory(
  sections: SectionsList,
  sectionName: string,
  categoryCode: string,
): Category | undefined {
  const section = findSectionByName(sections, sectionName)
  return section?.categories.find((cat) => cat.code === categoryCode)
}

export function findExtcode(
  sections: SectionsList,
  sectionName: string,
  categoryCode: string,
  extCode: string,
): Extcode | undefined {
  const category = findCategory(sections, sectionName, categoryCode)
  return category && category.extcodes.find((ext) => ext.code === extCode)
}
