import { Record } from 'immutable'

import { Category } from '~/state/model/codes/Category'
import {
  CategoryLinkType,
  CODE_TYPES,
  GENERAL_CODE,
  GST_GENERAL_EXTCODE_NAME,
  GST_RETURN_EXTCODE,
  GstType,
  OVERDRAFT_INTEREST_EXTCODE,
} from '~/state/model/codes/codeTypes'
import { Section } from '~/state/model/codes/Section'
import { BREEDING_TYPES } from '~/state/selectors/grid/types'

import { UnitOfMeasure } from '../codingLines/types'

export interface ExtendedCodeFromServer {
  applyFilter?: boolean
  export_code?: string
  export_code_mirrored?: string
  expanded?: boolean
  category_link_type?: CategoryLinkType
  kpi_reporting_group_yield?: string
  milk_production_share_percent?: number
  breeding_status?: string
  kpi_reporting_group_unit_of_measure?: UnitOfMeasure
  stock_units?: string
  active?: boolean
  kpi_reporting_group_id?: string
  dairy_company_id?: string
  position?: number
  name?: string
  code_type?: CODE_TYPES
  milk_production_business_model?: string
  code?: string
  tag_ids?: string[] | null
  settlement_delay?: number
  gst_type?: GstType | 'INHERIT'
  dairy_company?: string
  default_bank_account_id?: string
  dairy_season_start_month?: number
  type?: string
  id?: string
  category_id?: string
  age_to_id?: string
  age?: string
}

interface ExtcodeFields {
  applyFilter?: boolean
  expanded?: boolean
  export_code?: string
  export_code_mirrored?: string
  category_link_type?: CategoryLinkType | null
  kpi_reporting_group_yield?: string
  milk_production_share_percent?: number
  breeding_status?: string
  kpi_reporting_group_unit_of_measure?: UnitOfMeasure
  stock_units?: string
  active: boolean
  kpi_reporting_group_id?: string
  dairy_company_id?: string
  position: number
  name: string
  code_type: CODE_TYPES
  milk_production_business_model?: string
  code: string
  tag_ids?: string[] | null
  settlement_delay?: number
  gst_type: GstType | 'INHERIT'
  dairy_company?: string
  default_bank_account_id?: string
  dairy_season_start_month?: number
  type?: string
  id: string
  category_id: string
  age_to_id?: string
  age: any
}

export const extendedCodeDefaults: ExtcodeFields = {
  applyFilter: undefined,
  export_code: undefined,
  export_code_mirrored: undefined,
  expanded: undefined,
  category_link_type: undefined,
  kpi_reporting_group_yield: undefined,
  milk_production_share_percent: undefined,
  breeding_status: undefined,
  kpi_reporting_group_unit_of_measure: undefined,
  stock_units: undefined,
  active: true,
  kpi_reporting_group_id: undefined,
  dairy_company_id: undefined,
  position: 0,
  name: '',
  code_type: CODE_TYPES.TYPE_EMPTY,
  milk_production_business_model: undefined,
  code: '',
  tag_ids: undefined,
  settlement_delay: undefined,
  gst_type: 'BUSINESS',
  dairy_company: undefined,
  default_bank_account_id: undefined,
  dairy_season_start_month: undefined,
  type: undefined,
  id: '',
  category_id: '',
  age: undefined,
  age_to_id: undefined,
}

export class Extcode extends Record(extendedCodeDefaults) {
  static fromServerJS(data: ExtendedCodeFromServer): Extcode {
    return new Extcode(data)
  }

  static empty(): Extcode {
    return new Extcode()
  }

  isCodePrimaryBreeding(): boolean {
    return this.breeding_status === BREEDING_TYPES.BREEDING
  }

  isCodeSecondaryBreeding(): boolean {
    return this.breeding_status === BREEDING_TYPES.SECONDARY_BREEDING
  }

  isCodeBreedingCode(): boolean {
    return this.isCodePrimaryBreeding() || this.isCodeSecondaryBreeding()
  }

  isGeneralCode(): boolean {
    return this.code === GENERAL_CODE
  }

  isCodeProgeny(): boolean {
    return this.breeding_status === BREEDING_TYPES.PROGENY
  }

  isStockCode(): boolean {
    return this.code_type === CODE_TYPES.TYPE_STOCK
  }

  isIncomeCode(): boolean {
    return this.code_type === CODE_TYPES.TYPE_INCOME
  }

  isExpenseCode(): boolean {
    return this.code_type === CODE_TYPES.TYPE_EXPENSE
  }

  isMilkSolids(): boolean {
    return (
      !this.isGeneralCode() && this.code_type === CODE_TYPES.TYPE_MILK_SOLIDS
    )
  }

  // This function can only be called for extcodes, not category codes or section codes
  isMilkProductionCode(milkProductionCategoryId: string | undefined): boolean {
    return this.category_id !== milkProductionCategoryId
  }

  isGstGeneralCode(): boolean {
    return this.name === GST_GENERAL_EXTCODE_NAME // TODO: Not correct. Some GST Component Code does not have 'GST Component' as name but 'General'.
  }

  isProductCode(): boolean {
    return this.code_type === CODE_TYPES.TYPE_PRODUCT
  }

  isOverdraftInterestCode(): boolean {
    return this.code === OVERDRAFT_INTEREST_EXTCODE
  }

  isGstReturnExtcode(): boolean {
    return this.code === GST_RETURN_EXTCODE
  }

  isInactiveCode(): boolean {
    return !this.active
  }

  isCodeClearable(): boolean {
    return (
      !this.isMilkSolids() &&
      !this.isOverdraftInterestCode() &&
      !this.isInactiveCode()
    )
  }

  acctCodeForCategoryLinkType(
    categoryLinkType: CategoryLinkType | null | undefined,
  ): string | null {
    let acctCode = this.export_code
    if (categoryLinkType === 'exp') {
      acctCode = this.export_code_mirrored
    }
    if (!acctCode || acctCode === '') {
      acctCode = 'None'
    }
    return acctCode
  }
}

export function isExtcode(code: Extcode | Category | Section): code is Extcode {
  // only extcodes have a category_id field - a link to the containing category. Sections don't have a category_id either.
  return !!code.get('category_id', undefined)
}

export function isMilkSolids(extCode: Extcode | undefined): boolean {
  return !!extCode && extCode.isMilkSolids()
}

export function isGeneral(extCode: Extcode | undefined): boolean {
  return !!extCode && extCode.isGeneralCode()
}

export const DUMMY_GENERAL_CODE = Extcode.fromServerJS({
  code: GENERAL_CODE,
})

const catAndExtSpecified = /^[a-zA-Z0-9_ \-&]+:[a-zA-Z0-9_ \-&]+$/
const noWhiteSpaceToTrim = /^[^\s]+(\s+[^\s]+)*$/

export function isCodeStringExtcode(codeText: string): boolean {
  return Boolean(
    noWhiteSpaceToTrim.exec(codeText) && catAndExtSpecified.exec(codeText),
  )
}
