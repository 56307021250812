import InputAdornment from '@mui/material/InputAdornment'
import { type FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export const EndInputAdornment: FC<Props> = (props) => (
  <InputAdornment position='end' sx={{ ml: 1, mr: -1 }}>
    {props.children}
  </InputAdornment>
)
