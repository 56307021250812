import { Decimal } from 'decimal.js'

import { isAmountNegativeOrNegativeZero } from '~/common/utils/CurrencyUtils'
import { CodeTree } from '~/graphql/CodeTypes'
import { Category } from '~/state/model/codes/Category'
import { Extcode } from '~/state/model/codes/Extcode'
import { Section } from '~/state/model/codes/Section'
import { LIVESTOCK_EVENT_TYPES } from '~/state/selectors/grid/types'

import { EventType } from '../codingLines/types'
import { ALL_GST_TYPES } from '../GstTypes'
import { UUID } from '../ModelTypes'

export const GENERAL_CODE = '__GEN'
export const SYSTEM_CODE = '__SYSTEM_CODE'
export const GST_CODE = 'GST'
export const OVERDRAFT_INTEREST_EXTCODE = 'O'
export const OVERDRAFT_INTEREST_EXTCODE_NAME = 'Overdraft Interest'
export const GST_RETURN_EXTCODE = 'RET'
export const INTEREST_CATEGORY_CODE = 'INT'
export const catAndGenCodeSpecified = /^\w+:__GEN$/i
export type GstType = ALL_GST_TYPES

export const DEFAULT_GST_TYPE: GstType = 'BUSINESS'
export const GST_SECTION_NAME = 'GST' // names to match codes.rb ruby file
export const GST_CATEGORY_NAME = GST_SECTION_NAME
export const GST_GENERAL_EXTCODE_NAME = 'GST Component'
export const GST_COMPONENT_ROW_ID = 'GST-COMPONENT'
export const RUNNING_TOTAL_SECTION_CODE = 'RUNNINGTOTAL'
export const SUB_TOTAL_SECTION_CODE = 'SUBTOTAL'

export const GST_CATEGORY_WARNING_MESSAGE =
  'Category Code can not be selected for GST'
export const ZERO_RATED_GST_WARNING_MESSAGE =
  'Line Amount cannot be negative for Zero-rated GST'
export type CategoryLinkType = 'inc' | 'exp' | 'any' | undefined | null

interface NotFoundCodeDetails {
  found: false
}

export interface FoundCodeDetails {
  found: true
  sec?: Section
  cat: Category
  ext: Extcode
}

// Note - I don;t like the name CodeIndex - but it's unique so I can find and replace once I've got a better name :)
export type CodeIndex = string
export type IndexedCodeDetails = Map<CodeIndex, FoundCodeDetails>
export const newIndexedCodeDetails = (): IndexedCodeDetails =>
  new Map<CodeIndex, FoundCodeDetails>()

export type CodeDetails = FoundCodeDetails | NotFoundCodeDetails
export enum CODE_TYPES {
  TYPE_INCOME = 'INCOME',
  TYPE_EXPENSE = 'EXPENSE',
  TYPE_PRODUCT = 'PRODUCT',
  TYPE_STOCK = 'STOCK',
  TYPE_MILK_SOLIDS = 'MILKSOLIDS',
  TYPE_CROP = 'CROP',
  TYPE_PURCHASE = 'PURCHASE',
  TYPE_EMPTY = '',
}

export enum CODE_ROW_TYPES {
  TYPE_EXTCODE = 'extcode',
  TYPE_CATEGORY = 'CATEGORY',
  TYPE_SECTION = 'section',
}

function getMovementTypeForAmount(amount: Decimal): EventType {
  return isAmountNegativeOrNegativeZero(amount)
    ? LIVESTOCK_EVENT_TYPES.PURCHASE
    : LIVESTOCK_EVENT_TYPES.SALE
}

export function getEventTypeFor(
  amount: Decimal,
  { codeDetails, isActuals }: { codeDetails: CodeDetails; isActuals: boolean },
): EventType {
  if (codeDetails.found && !isActuals && codeDetails.ext.isMilkSolids()) {
    // the back end does not accept "MILKPROD" as a movement type
    // the old invoice form saved these as SALE/PURCHASE - based on the amount
    return LIVESTOCK_EVENT_TYPES.MILK_PRODUCTION
  }

  return getMovementTypeForAmount(amount)
}

export const getCodeTypeFor = (codeDetails: FoundCodeDetails): CODE_TYPES =>
  codeDetails.ext.code_type || codeDetails.cat.code_type

export const getCodeTypeFromCodeTree = (
  codeTree?: CodeTree,
): CODE_TYPES | undefined => {
  if (codeTree) {
    return codeTree.extcode_code_type || codeTree.category_code_type
  } else {
    return undefined
  }
}

export const getDefaultGstTypeFor = (
  codeDetails: FoundCodeDetails,
): GstType => {
  if (!codeDetails.ext.gst_type || codeDetails.ext.gst_type === 'INHERIT') {
    if (!codeDetails.cat.gst_type || codeDetails.cat.gst_type === 'INHERIT') {
      return DEFAULT_GST_TYPE
    } else {
      return codeDetails.cat.gst_type
    }
  }
  return codeDetails.ext.gst_type
}

export const getDefaultTagIdFor = (
  codeDetails: FoundCodeDetails,
): UUID | null => {
  if (codeDetails.ext.tag_ids && codeDetails.ext.tag_ids.length > 0) {
    return codeDetails.ext.tag_ids[0]
  } else if (codeDetails.cat.tag_ids && codeDetails.cat.tag_ids.length > 0) {
    return codeDetails.cat.tag_ids[0]
  }
  return null
}

export const getCodeTreeFor = (codeDetails: FoundCodeDetails): CodeTree => ({
  category_code: codeDetails.cat.code,
  category_code_type: codeDetails.cat.code_type,
  category_name: codeDetails.cat.name,
  extcode_code: codeDetails.ext.code,
  extcode_code_type: codeDetails.ext.code_type,
  extcode_id: codeDetails.ext.id,
  extcode_name: codeDetails.ext.name,
  category_kpi_reporting_group_id: codeDetails.cat.kpi_reporting_group_id,
  extcode_kpi_reporting_group_id: codeDetails.ext.kpi_reporting_group_id,
})

export function getCodeIndex(codeDetails: FoundCodeDetails): CodeIndex {
  return codeDetails.ext.isGeneralCode()
    ? codeDetails.cat.code + ':'
    : codeDetails.cat.code + ':' + codeDetails.ext.code
}
