import { FeatureFlag, featureSwitcher } from '~/services/FeatureFlag'
import { WorkspaceMemberRole } from '~/workspaces/models/UserInWorkspace'

export type Ability = keyof typeof ABILITIES
export const ABILITIES = {
  VIEW: 'VIEW',
  UPDATE: 'UPDATE',
} as const

export const USER_ROLE_DEMO_VIEWER = 'demo_viewer'
export const USER_ROLE_SUBSCRIPTION_ADMIN = 'subscription_admin'
export const USER_ROLE_ORGANISATION_ADMIN = 'organisation_admin'
export const USER_ROLE_RESTRICTED_USER = 'restricted_user'
export const USER_ROLE_REPORTING_ACCESS = 'reporting_access'
export const USER_ROLE_WORKSPACE_ADMIN = 'workspace_admin'

export const ACCESS_LEVELS_ORDER = [
  USER_ROLE_SUBSCRIPTION_ADMIN,
  USER_ROLE_ORGANISATION_ADMIN,
  USER_ROLE_REPORTING_ACCESS,
  USER_ROLE_RESTRICTED_USER,
  USER_ROLE_DEMO_VIEWER,
]

export const WORKSPACE_ACCESS_LEVEL_ORDER = [
  'workspace_admin',
  'workspace_standard',
]

export type UserFarmAccessLevel =
  | 'demo_viewer'
  | 'subscription_admin'
  | 'organisation_admin'
  | 'restricted_user'
  | 'reporting_access'

export type UserFarmAccessLevelsInWorkspace =
  | 'organisation_admin'
  | 'restricted_user'
  | 'reporting_access'

const userFarmAccessLevels = {
  workspace: [
    'organisation_admin',
    'restricted_user',
    'reporting_access',
  ] as UserFarmAccessLevelsInWorkspace[],
}

export const displayUserFarmAccess = (role: UserFarmAccessLevel) => {
  switch (role) {
    case 'demo_viewer':
      return 'Demo Viewer'
    case 'subscription_admin':
      return 'Subscription admin'
    case 'organisation_admin':
      return 'Organisation admin'
    case 'restricted_user':
      return 'Restricted access'
    case 'reporting_access':
      return 'Reporting access'
    default:
      return 'Unknown'
  }
}

export const displayWorkspaceRole = (role: WorkspaceMemberRole) => {
  switch (role) {
    case 'workspace_admin':
      return 'Admin'
    case 'workspace_standard':
      return 'Standard'
    case 'workspace_manager':
      return 'Manager'
    default:
      return 'Unknown'
  }
}

// { value: UserFarmAccessLevel; label: string; }

export type UserFarmAccessLevelOption = ReturnType<
  typeof userFarmAccessLevelOptions
>[number]
// I think this is going to get more complex, but trying to consolidate the logic here
export const userFarmAccessLevelOptions = (levelOption: 'workspace') =>
  userFarmAccessLevels[levelOption].map((role) => ({
    value: role,
    label: displayUserFarmAccess(role),
  }))

export type Domain = keyof typeof DOMAINS
export const DOMAINS = {
  SETTINGS: 'SETTINGS',
  SETTINGS_USER: 'SETTINGS_USER',
  SETTINGS_CONNECTIONS: 'SETTINGS_CONNECTIONS',
  SUBSCRIPTION_ADMIN: USER_ROLE_SUBSCRIPTION_ADMIN,
  ORGANISATION_ADMIN: USER_ROLE_ORGANISATION_ADMIN,
  RESTRICTED_USER: USER_ROLE_RESTRICTED_USER,
  REPORTING_ACCESS: USER_ROLE_REPORTING_ACCESS,
  DEMO_USER: USER_ROLE_DEMO_VIEWER,
  ADD_WORKSPACE: 'ADD_WORKSPACE',
  REMOVE_WORKSPACE: 'REMOVE_WORKSPACE',
  // WORKSPACE_ADMIN: USER_ROLE_WORKSPACE_ADMIN,
} as const

const AbilityAdjudicator = () => {
  const PERMISSIONS = {
    [DOMAINS.DEMO_USER]: {
      [ABILITIES.VIEW]: {
        [DOMAINS.SETTINGS]: featureSwitcher.isOn(
          FeatureFlag.DemoFarmsHaveSettings,
        ),
        [DOMAINS.SETTINGS_USER]: featureSwitcher.isOn(
          FeatureFlag.DemoFarmsHaveSettings,
        ),
        [DOMAINS.SETTINGS_CONNECTIONS]: featureSwitcher.isOn(
          FeatureFlag.DemoFarmsHaveSettings,
        ),
      },
      [ABILITIES.UPDATE]: {
        // want to display options to the user for demo purposes
        [DOMAINS.RESTRICTED_USER]: featureSwitcher.isOn(
          FeatureFlag.DemoFarmsHaveSettings,
        ),
        [DOMAINS.ORGANISATION_ADMIN]: featureSwitcher.isOn(
          FeatureFlag.DemoFarmsHaveSettings,
        ),
        [DOMAINS.SUBSCRIPTION_ADMIN]: false,
        [DOMAINS.REPORTING_ACCESS]: false,
      },
    },
    [DOMAINS.RESTRICTED_USER]: {
      [ABILITIES.VIEW]: {
        [DOMAINS.SETTINGS]: false,
        [DOMAINS.SETTINGS_USER]: false,
        [DOMAINS.SETTINGS_CONNECTIONS]: false,
      },
      [ABILITIES.UPDATE]: {
        [DOMAINS.RESTRICTED_USER]: false,
        [DOMAINS.ORGANISATION_ADMIN]: false,
        [DOMAINS.SUBSCRIPTION_ADMIN]: false,
        [DOMAINS.REPORTING_ACCESS]: false,
      },
    },
    [DOMAINS.ORGANISATION_ADMIN]: {
      [ABILITIES.VIEW]: {
        [DOMAINS.SETTINGS]: true,
        [DOMAINS.SETTINGS_USER]: true,
        [DOMAINS.SETTINGS_CONNECTIONS]: true,
      },
      [ABILITIES.UPDATE]: {
        [DOMAINS.RESTRICTED_USER]: true,
        [DOMAINS.ORGANISATION_ADMIN]: false,
        [DOMAINS.SUBSCRIPTION_ADMIN]: false,
        [DOMAINS.REPORTING_ACCESS]: true,
      },
    },
    [DOMAINS.SUBSCRIPTION_ADMIN]: {
      [ABILITIES.VIEW]: {
        [DOMAINS.SETTINGS]: true,
        [DOMAINS.SETTINGS_USER]: true,
        [DOMAINS.SETTINGS_CONNECTIONS]: true,
      },
      [ABILITIES.UPDATE]: {
        [DOMAINS.RESTRICTED_USER]: true,
        [DOMAINS.ORGANISATION_ADMIN]: true,
        [DOMAINS.SUBSCRIPTION_ADMIN]: false,
        [DOMAINS.REPORTING_ACCESS]: true,
        [DOMAINS.ADD_WORKSPACE]: true,
        [DOMAINS.REMOVE_WORKSPACE]: true,
      },
    },
    [DOMAINS.REPORTING_ACCESS]: {
      [ABILITIES.VIEW]: {
        [DOMAINS.SETTINGS]: true,
        [DOMAINS.SETTINGS_USER]: true,
        [DOMAINS.SETTINGS_CONNECTIONS]: true,
      },
      [ABILITIES.UPDATE]: {
        [DOMAINS.RESTRICTED_USER]: false,
        [DOMAINS.ORGANISATION_ADMIN]: false,
        [DOMAINS.SUBSCRIPTION_ADMIN]: false,
        [DOMAINS.REPORTING_ACCESS]: false,
      },
    },
  }

  return {
    can: (ability: string, domain: string, role: string | false) => {
      if (!role) return false
      return PERMISSIONS[role][ability][domain]
    },
  }
}

export default AbilityAdjudicator
