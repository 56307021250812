import { Record } from 'immutable'

import { UUID } from '~/state/model/ModelTypes'

export interface AddressFromServer {
  address_finder_id: string
  address_type?: 'POSTAL_ADDRESS' | 'PHYSICAL_ADDRESS'
  dpid?: string
  id: UUID
  latitude?: string
  line_1?: string
  line_2?: string
  line_3?: string
  longitude?: string
  postcode?: string
  state?: string
  region?: string
  ta?: string
  care_of?: string
}
interface AddressRecordFields extends AddressFromServer {}

const addressDefaults: AddressRecordFields = {
  address_finder_id: '',
  address_type: undefined,
  dpid: undefined,
  id: '',
  latitude: undefined,
  line_1: undefined,
  line_2: undefined,
  line_3: undefined,
  longitude: undefined,
  postcode: undefined,
  state: undefined,
  region: undefined,
  ta: undefined,
  care_of: undefined,
}
export class AddressRecord extends Record(addressDefaults) {
  static fromServerJS(data?: AddressFromServer): AddressRecord {
    return new AddressRecord({
      ...addressDefaults,
      ...data,
    })
  }

  static nullRecord(): AddressRecord {
    return new AddressRecord(addressDefaults)
  }
}
