import { createSvgIcon } from '@mui/material/utils'

export const ComfortableDensityIcon = createSvgIcon(
  <path
    transform='scale(1.33333333333)'
    d='M4.5,3 L13.5,3 C14.3284271,3 15,3.67157288 15,4.5 C15,5.32842712 14.3284271,6 13.5,6 L4.5,6 C3.67157288,6 3,5.32842712 3,4.5 C3,3.67157288 3.67157288,3 4.5,3 Z M4.5,7.5 L13.5,7.5 C14.3284271,7.5 15,8.17157288 15,9 C15,9.82842712 14.3284271,10.5 13.5,10.5 L4.5,10.5 C3.67157288,10.5 3,9.82842712 3,9 C3,8.17157288 3.67157288,7.5 4.5,7.5 Z M4.5,12 L13.5,12 C14.3284271,12 15,12.6715729 15,13.5 C15,14.3284271 14.3284271,15 13.5,15 L4.5,15 C3.67157288,15 3,14.3284271 3,13.5 C3,12.6715729 3.67157288,12 4.5,12 Z'
    id='Combined-Shape'
  />,
  'ComfortableDensity',
)
