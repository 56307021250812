import { Component, ReactNode } from 'react'
import { Location } from 'react-router-dom'

import bugsnagClient from '~/common/utils/Bugsnag'
import FourZeroOne from '~/errors/401'
import withRouter from '~/routes/withRouter'
import { NO_CURRENT_FARM_ERROR } from '~/state/model/farms/farms'
import { explicitAny } from '~/state/utils/explicitAny'

type Props = {
  children: ReactNode
  location?: Location
}

interface State {
  hasError: boolean
  error: CatchableError | null
}

type CatchableError = (typeof catchableErrors)[number]
const catchableErrors = [NO_CURRENT_FARM_ERROR] as const
const isCatchableError = (error: string): error is CatchableError =>
  catchableErrors.includes(error as explicitAny)

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  // static getDerivedStateFromError(error) {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: explicitAny, errorInfo: explicitAny) {
    if (error instanceof Error && isCatchableError(error.message)) {
      this.setState({
        error: error.message,
      })
    } else {
      bugsnagClient.notify(error, errorInfo)
    }
  }

  componentDidUpdate(prevProps: Props) {
    // Clear error when changing pages
    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      this.setState({ hasError: false, error: null })
    }
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children
    }

    switch (this.state.error) {
      case NO_CURRENT_FARM_ERROR:
        return <FourZeroOne />
      case null:
      default:
        return <DefaultErrorMessage />
    }
  }
}

const DefaultErrorMessage = () => (
  <div className='padding w-full bg-white text-center'>
    <h2>Something went wrong</h2>
    <div className='text-with-icon'>
      Please refresh <i className='material-icons'>refresh</i> the page and try
      again. If you receive this message again contact us on 0800 888 080
    </div>
  </div>
)

export default withRouter(ErrorBoundary)
export const ErrorBoundaryWithoutRouter = ErrorBoundary
