import { List, Map } from 'immutable'

export function mapFromList<T>(
  list: List<T>,
  keyFn: (item: T) => string,
): Map<string, T> {
  return list
    .reduce(
      (map: Map<string, T>, item: T) => map.set(keyFn(item), item),
      Map<string, T>().asMutable(),
    )
    .asImmutable()
}
