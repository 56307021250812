import { List, Record } from 'immutable'

import { ISODate, UUID } from '~/state/model/ModelTypes'

export interface DatedGstFrequencyServerData {
  gst_frequency: number
  gst_period_start_on: ISODate
  id: UUID
}

interface DatedGstFrequencyFields {
  gst_frequency: number
  gst_period_start_on: ISODate
  id: UUID
}

export const DatedGstFrequencyDefaults: DatedGstFrequencyFields = {
  gst_frequency: 1,
  gst_period_start_on: '',
  id: '',
}

export type DatedGstFrequencyList = List<DatedGstFrequency>

export class DatedGstFrequency extends Record(DatedGstFrequencyDefaults) {
  static fromServerJS(
    data: DatedGstFrequencyServerData | undefined,
  ): DatedGstFrequency {
    if (!data) {
      return new DatedGstFrequency()
    }
    const fields: DatedGstFrequencyFields = {
      ...data,
    }
    return new DatedGstFrequency(fields)
  }

  static fromServerArray(
    src: DatedGstFrequencyServerData[],
  ): DatedGstFrequencyList {
    return List(src.map((a) => DatedGstFrequency.fromServerJS(a)))
  }
}
