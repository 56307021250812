export enum FeatureFlag {
  PerformanceLogging,
  PGGWrightsonsInvoiceImport,
  ReduxLogMiddleware,
  BugSnagMiddleware,
  PaySauceFeature,
  CrystalFeature,
  DemoFarmsHaveSettings,
  AllCategoriesInLivestockGrid,
  FarmSourceConnector,
  XtractorConnector,
  BankTranWithoutLivestock,
  VarianceReportHideModal,
  FileGSTReturnWithIRD,
  RuralCoInvoiceImport,
  CannySignInRedirect,
  DiscountManagementTagsBy50PercentUntil2023,
  AddressFinderFeature,
  BnzApiFeature,
  GridTrace,
  Line2Quantity,
  DisableFocusOutlineFeature,
  DisableCallingEnsureWorkingPlansExist,
  CopyLine2Enhancement,
  WorkspacesFeature,
  ClearLivestock,
  PlanItemAdjustments,
  FonterraConnectorFeature,
  AustralianSignupFeature,
  CodesFromTemplateFeature,
  WorkspaceCertification,
}

type Flags = string[]

const WindowEnv = window['__env'] || {}
const ENV = WindowEnv.DEPLOY_TARGET

export class FeatureSwitcher {
  private flagsOn: FeatureFlag[]
  private initialFlags: Flags

  constructor(flags: FeatureFlag[]) {
    this.initialFlags = []
    this.flagsOn = []
    this.withFlags(flags)
  }

  resetFlags() {
    this.flagsOn = []
  }

  setInitialFlags(initialFeatureFlags: Flags) {
    this.initialFlags = initialFeatureFlags
    const turnedOnFeatures = initialFeatureFlags.map(
      (flag_name) => FeatureFlag[flag_name],
    )
    this.withFlags(turnedOnFeatures)
  }

  setFarmFlags(farmFlags: Flags) {
    this.resetFlags()

    const initial = this.initialFlags.map((flag_name) => FeatureFlag[flag_name])
    this.withFlags(initial)
    const farm = farmFlags.map((flag_name) => FeatureFlag[flag_name])
    this.withFlags(farm)
  }

  withFlags(flags: FeatureFlag[]) {
    flags
      .filter((flag) => flag) // reject old empty flags
      .forEach((flag) => {
        this.turnOn(flag)
        if (ENV !== 'production') {
          console.log(
            `* ${FeatureFlag[flag]} is now ${this.isOn(flag) ? 'on' : 'off'} `,
          )
        }
      })
  }

  isOn(flagToCheck: FeatureFlag): boolean {
    return this.flagsOn.includes(flagToCheck)
  }

  isOff(flag: FeatureFlag): boolean {
    return !this.isOn(flag)
  }

  turnOn(flag: FeatureFlag): void {
    if (this.isOn(flag)) return
    this.flagsOn.push(flag)
  }

  turnOff(flag: FeatureFlag): void {
    this.flagsOn = this.flagsOn.filter((flagOn) => flagOn !== flag)
  }

  setStatus(flag: FeatureFlag, status: boolean) {
    status ? this.turnOn(flag) : this.turnOff(flag)
  }

  turnOnForTest(flag: FeatureFlag) {
    this.turnOn(flag)
  }

  turnOffForTest(flag: FeatureFlag) {
    this.turnOff(flag)
  }
}

export const featureSwitcher = new FeatureSwitcher([])
